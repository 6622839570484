import { Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges } from '@angular/core';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import * as _ from 'underscore';

import { DataService } from 'src/app/services/data.service';
import { PlannersWorksheetService } from 'src/app/services/planners-worksheet.service';

@Component({
  selector: 'app-worksheet-dept',
  templateUrl: './worksheet-dept.component.html',
  styleUrls: ['./worksheet-dept.component.scss']
})
export class WorksheetDeptComponent implements OnInit, OnChanges, OnDestroy {
  @Input() plannerWorksheetCrewData: any;
  @Input() plannerWorksheetDeptData: any;
  @Input() startDate: string;
  @Input() todaysDate: string;

  start: string;
  today: string;

  displayedCrewColumns: string[] = [
    'workdate',
    'crew',

    'able_to_startUp',
    'planned_builds',
    'actual_builds',
    'comments',

    'actions'
  ];

  displayedDeptColumns: string[] = [
    'dept_name',

    'predicted_unplanned_abs',
    'planned_abs',
    'total_expected',

    'floaters',
    'tpt_scheduled',
    'off_std',
    'pqx',
    'total_scheduled',

    'actual_unplanned',
    'actual_planned',
    'black_flags',
    'total_actuals',

    'diff-sceduled-planned',
    'diff-planned-actuals',

    'off_crew',
    'notes',

    'actions'
  ];
  actualVehicleBuiltLastLoadedOn: string;
  plannedAbsLastLoadedOn: string;
  actualAbsLastLoadedOn: string;

  constructor(
    public dataService: DataService,
    private plannersWorksheetService: PlannersWorksheetService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'plannerWorksheetDeptData': {
            this.updateData(changes[propName].currentValue);
            break;
          }
          case 'startDate': {
            this.start = this.startDate;
            this.today = this.todaysDate;
            break;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.plannersWorksheetService
      .getPwAutofieldsLastUpdOnTimestamps(this.dataService.plantCode)
      .subscribe((res: any) => {
        console.log(res);
        this.actualVehicleBuiltLastLoadedOn = res[0].actual_vehicle_built_last_loaded_on;
        this.plannedAbsLastLoadedOn = res[0].planned_abs_last_loaded_on;
        this.actualAbsLastLoadedOn = res[0].actual_abs_last_loaded_on;
      });
  }

  updateData(data) {
    this.plannerWorksheetDeptData = _.chain(data)
      .groupBy('crew')
      .map((value, key) => {
        return {
          crew: key,
          crewData: _.chain(value).value()
        };
      })
      .value();
  }

  getTotal_predicted_unplanned_abs(data) {
    return data
      .map((t) => t.predicted_unplanned_abs)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_planned_abs(data) {
    return data
      .map((t) => t.planned_abs)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_total_expected(data) {
    return (
      this.getTotal_predicted_unplanned_abs(data) +
      this.getTotal_planned_abs(data)
    );
  }

  getTotal_floaters(data) {
    return data.map((t) => t.floaters).reduce((acc, value) => acc + value, 0);
  }

  getTotal_tpt_scheduled(data) {
    return data
      .map((t) => t.tpt_scheduled)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_off_std(data) {
    return data.map((t) => t.off_std).reduce((acc, value) => acc + value, 0);
  }

  getTotal_pqx(data) {
    return data.map((t) => t.pqx).reduce((acc, value) => acc + value, 0);
  }

  getTotal_total_scheduled(data) {
    return (
      this.getTotal_floaters(data) +
      this.getTotal_tpt_scheduled(data) -
      this.getTotal_off_std(data) -
      this.getTotal_pqx(data)
    );
  }

  getTotal_actual_unplanned(data) {
    return data
      .map((t) => t.actual_unplanned)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_actual_planned(data) {
    return data
      .map((t) => t.actual_planned)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_black_flags(data) {
    return data
      .map((t) => t.black_flags)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_total_actuals(data) {
    return (
      this.getTotal_actual_unplanned(data) + this.getTotal_actual_planned(data) + this.getTotal_black_flags(data)
    );
  }

  getTotal_off_crew(data) {
    return data.map((t) => t.off_crew).reduce((acc, value) => acc + value, 0);
  }

  getTotal_planned_builds() {
    return this.plannerWorksheetCrewData
      .map((t) => t.planned_builds)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_actual_builds() {
    return this.plannerWorksheetCrewData
      .map((t) => t.actual_builds)
      .reduce((acc, value) => acc + value, 0);
  }

  saveCrewData(data) {
    data.updateType = 'CREW';
    data.plantcode = this.dataService.plantCode;
    data.username = this.dataService.userId;
    data.autoPopulatedFieldEntryApplicable = this.dataService.autoPopulatedFieldEntryApplicable;
    this.plannersWorksheetService
      .saveWorksheetEntryForCrew(data)
      .subscribe((res: any) => {
        console.log(res);
        this.openSnackBar(`Your entry has been successfully saved`, 'Ok');
      });
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      duration: 1000,
      panelClass: [color]
    });
  }

  saveData(data) {
    data.plantcode = this.dataService.plantCode;
    data.username = this.dataService.userId;
    data.autoPopulatedFieldEntryApplicable = this.dataService.autoPopulatedFieldEntryApplicable;
    this.plannersWorksheetService
      .saveWorksheetEntryForDept(data)
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  ngOnDestroy() { }
}
