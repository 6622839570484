<h1 class="mat-h1">{{ dataService.plantName | titlecase }} Users</h1>
<mat-card>
  <div
    class="card-container"
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-around"
    fxLayoutGap="10px"
    fxLayoutGap.xs="5px"
  >
  <div fxFlex="noshrink">
    <mat-form-field>
      <input
        required
        matInput
        placeholder="TID"
        [formControl]="tidFormControl"
        [errorStateMatcher]="matcher"
      />
      <mat-error
        *ngIf="
          !tidFormControl.hasError('required')
        "
      >
        Please enter a valid TID
      </mat-error>

      <mat-error *ngIf="tidFormControl.hasError('required')">
        TID is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>
    <div fxFlex="noshrink">
      <mat-form-field>
        <input
          required
          matInput
          placeholder="Email"
          [formControl]="emailFormControl"
          [errorStateMatcher]="matcher"
        />
        <mat-error
          *ngIf="
            emailFormControl.hasError('email') &&
            !emailFormControl.hasError('required')
          "
        >
          Please enter a valid email address
        </mat-error>
        <mat-error
          *ngIf="
            emailFormControl.hasError('emailDomain') &&
            !emailFormControl.hasError('email') &&
            !emailFormControl.hasError('required')
          "
        >
          Please enter a valid <strong>STELLANTIS</strong> email address
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="noshrink">
      <mat-form-field>
        <input
          required
          matInput
          placeholder="Name"
          [formControl]="nameFormControl"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="nameFormControl.hasError('minlength')">
          Name must be atleast 3 characters long
        </mat-error>
        <mat-error
          *ngIf="
            nameFormControl.hasError('required') &&
            !nameFormControl.hasError('minlength')
          "
        >
          Please enter a valid name
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="noshrink">
      <mat-form-field>
        <mat-label>User Role</mat-label>
        <mat-select [(ngModel)]="selectedUserRole">
          <mat-option value="User">User</mat-option>
          <mat-option value="Plant Admin">Plant Admin</mat-option>
          <mat-option
            *ngIf="dataService.userRole === 'Super User'"
            value="Super User"
            >Super User</mat-option
          >
        </mat-select>
        <mat-hint
          >Select Plant Admin if this user is also going to manage access for
          other users</mat-hint
        >
      </mat-form-field>
    </div>
    <div fxFlex="noshrink">
      <button
        [disabled]="
          emailFormControl.status === 'INVALID' ||
          nameFormControl.status === 'INVALID'
        "
        mat-raised-button
        color="primary"
        (click)="createUser({})"
      >
        Create User
      </button>
    </div>
  </div>

  <mat-form-field>
    <input
      matInput
      (keyup)="applyFilter($event.target.value)"
      placeholder="Filter"
    />
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="usersByPlantSource" matSort>
      <!-- User id -->
      <ng-container matColumnDef="user_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Id</th>
        <td mat-cell *matCellDef="let user">{{ user.user_id }}</td>
      </ng-container>

      <!-- Email-->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let user">{{ user.email }}</td>
      </ng-container>

      <!-- Name -->
      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let user">{{ user.user_name }}</td>
      </ng-container>

      <!-- User Role -->
      <ng-container matColumnDef="user_role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Role</th>
        <td mat-cell *matCellDef="let user">{{ user.user_role }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let user">
          <button
            [disabled]="user.user_id === dataService.userId"
            mat-icon-button
            matTooltip="Delete User"
            matTooltipPosition="left"
            (click)="deleteUser(user)"
          >
            <mat-icon aria-hidden="false" aria-label="Delete User"
              >delete</mat-icon
            >
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
  </div>
</mat-card>

<app-super-user-table
  *ngIf="dataService.userRole === 'Super User'"
  [managementUsersData]="managementUsersData"
></app-super-user-table>
