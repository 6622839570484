<mat-toolbar color="primary"
             class="mat-elevation-z6">
      <mat-toolbar-row>
            <button mat-button
                    id="hamburger"
                    (click)="navToggle()"
                    matTooltip="Navigation"
                    [matTooltipPosition]="'below'">
                  <mat-icon aria-hidden="false"
                            aria-label="Menu">menu</mat-icon>
            </button>
            <button mat-button
                    [routerLink]="['/dashboard']"
                    [queryParams]="dbParam"
                    fxShow.xs="false">
                  <img class="logo"
                       alt="STELLANTIS logo"
                       src="assets/images/logo_white.svg" />
            </button>
            <div class="app-details">
                  <div class="app-name"
                       fxShow="true">Absentee Planning</div>
                  <div *ngIf="!router.url.includes('/hr-daily-report')"
                       class="mat-small">{{ dataService.plantName | uppercase }}</div>
            </div>
            <button mat-button
                    id="dashboard"
                    [routerLink]="['/dashboard']"
                    [routerLinkActive]="['is-active']"
                    [queryParams]="dbParam"
                    matTooltip="Dashboard"
                    [matTooltipPosition]="'below'"
                    fxShow.xs="false">
                  <mat-icon aria-hidden="false"
                            aria-label="Dashboard">dashboard</mat-icon>
            </button>
            <div fxShow.sm="false"
                 fxShow.xs="false"
                 fxShow="true">
                  <button mat-button
                          [routerLink]="['/planners-worksheet']"
                          [routerLinkActive]="['is-active']">
                        Planners Worksheet
                  </button>
                  <button mat-button
                          [routerLink]="['/prediction-summary']"
                          [routerLinkActive]="['is-active']">
                        Prediction Summary
                  </button>
                  <button mat-button
                          *ngFor="let c of config"
                          [routerLink]="c.routerLink"
                          [queryParams]="c.queryParams"
                          [class.is-active]="isLinkActive(c.routerLink)"
                          QueryParamsHandling="merge">
                        {{ c.levelName }}
                  </button>
            </div>

            <span class="fill-remaining-space"></span>
            <span class="mat-small welcome-text"
                  fxShow.xs="false"
                  fxShow="true">Welcome, {{ dataService.givenName }}</span>

            <button *ngIf="
        dataService.userRole === 'Super User' ||
        dataService.userRole === 'Plant Admin'
      "
                    mat-icon-button
                    [routerLink]="['/plant-config']"
                    [routerLinkActive]="['is-active']"
                    matTooltip="Configuration"
                    [matTooltipPosition]="'below'">
                  <mat-icon aria-hidden="false"
                            aria-label="Admin">settings</mat-icon>
            </button>

            <button *ngIf="
        dataService.userRole === 'Super User' ||
        dataService.userRole === 'Plant Admin'
      "
                    mat-icon-button
                    [routerLink]="['/user-access']"
                    [routerLinkActive]="['is-active']"
                    [matTooltip]="dataService.userRole"
                    [matTooltipPosition]="'below'">
                  <mat-icon aria-hidden="false"
                            aria-label="Admin">person</mat-icon>
            </button>

            <button mat-icon-button
                    *ngIf="!isWeatherNotifications"
                    (click)="openWeatherNotificationDialog()"
                    matTooltip="Weather Notifications"
                    [matTooltipPosition]="'below'">
                  <mat-icon [matBadge]="unreadWeatherNotificationCount"
                            [matBadgeHidden]="unreadWeatherNotificationCount === 0"
                            matBadgeSize="medium"
                            matBadgeColor="warn">cloud</mat-icon>
            </button>

            <button mat-icon-button
                    *ngIf="!isNotifications"
                    (click)="openNotificationDialog()"
                    matTooltip="Notifications"
                    [matTooltipPosition]="'below'">
                  <mat-icon [matBadge]="unreadNotificationCount"
                            [matBadgeHidden]="unreadNotificationCount === 0"
                            matBadgeSize="medium"
                            matBadgeColor="warn">notifications</mat-icon>
            </button>

            <button *ngIf="dataService.allPlantsConfig"
                    mat-icon-button
                    [matMenuTriggerFor]="switchPlantMenu"
                    matTooltip="Switch Plant"
                    [matTooltipPosition]="'below'">
                  <mat-icon aria-hidden="false"
                            aria-label="Switch Plant">repeat</mat-icon>
            </button>
            <mat-menu #switchPlantMenu="matMenu">
                  <button (click)="selectPlant(plantConfig)"
                          [class.list-item-active]="
          plantConfig.plant_code === dataService.plantCode
        "
                          mat-menu-item
                          [matTooltip]="plantConfig.plant_name"
                          [matTooltipPosition]="'left'"
                          *ngFor="let plantConfig of dataService.allPlantsConfig">
                        {{ plantConfig.plant_abbr }} ({{ plantConfig.plant_code }})
                  </button>
            </mat-menu>
      </mat-toolbar-row>
</mat-toolbar>