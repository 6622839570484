import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import 'chartjs-plugin-datalabels';
import { Color, Label } from 'ng2-charts';

import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { WeatherService } from 'src/app/services/weather.service';
import { FeedbackComponent } from '../../layout/feedback/feedback.component';
import { DialogComponent } from '../../layout/dialog/dialog.component';
import { indexOf } from 'underscore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  today: Date;
  tomorrow: Date;
  date1: FormControl;
  date2: FormControl;
  minDate: Date;
  maxDate: Date;
  dateFilter2: any;
  dateArr = [];
  dateFilterArr = [];
  dataArr = [];
  holidayArr = [];
  dataSets = [];
  weatherType = [];
  temperatureMin = [];
  temperatureMax = [];

  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            min: 0
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ]
    },
    layout: {
      padding: {
        top: 20,
        bottom: 10,
        right: 10
      }
    },
    plugins: {
      datalabels: {
        color: 'grey',
        align: 'top',
        formatter: (value, ctx) => {
          return value;
        }
      }
    }
  };
  colors: Color[] = [
    {
      borderColor: 'rgba(255,170,29,0.5)',
      backgroundColor: 'rgba(255,255,0,0.2)'
    }
  ];
  labels = [];
  legend = false;
  chartType: ChartType = 'line';
  plugins = [];
  chartHeight = 85;
  chartWidth = 460;

  constructor(
    private router: Router,
    private dataService: DataService,
    private apiService: ApiService,
    private weatherService: WeatherService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataArr = [];
    this.holidayArr = [];

    this.apiService.currentFilterDefaultDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data[0].min_date) {
          this.minDate = this.formatStringToDate(data[0].min_date);
          this.today = new Date();
          this.tomorrow = new Date(
            new Date().setDate(new Date().getDate() + 1)
          );
          this.date1 = new FormControl(this.today);
          this.date2 = new FormControl(this.tomorrow);
          this.dateArr = [this.date1, this.date2];
          this.dateFilterArr = [this.minDate, this.maxDate];

          this.apiService.currentPredictionByCrewD1
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((d) => {
              this.dataArr[0] = d;
            });
          this.apiService.getPredictionByCrewD1(
            this.dataService.plantCode,
            this.formatDateToString(this.dateArr[0].value),
            this.formatDateToString(this.dateArr[0].value)
          );
          this.getWeatherData(
            this.dataService.plantCode,
            this.formatDateToString(this.dateArr[0].value),
            0
          );
          this.apiService.currentHolidayPredictionD1
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((d) => {
              this.holidayArr[0] = d[0]['feature5'].substring(0, d[0]['feature5'].indexOf(':'));
            });
          this.apiService.getHolidayPredictionD1(
            this.dataService.plantCode,
            this.formatDateToString(this.dateArr[0].value),
            this.formatDateToString(this.dateArr[0].value)
          );
          this.dataService.startDate = this.formatDateToString(
            this.dateArr[0].value
          );
          this.dataService.endDate = this.formatDateToString(
            this.dateArr[0].value
          );

          this.apiService.currentPredictionByCrewD2
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((d) => {
              this.dataArr[1] = d;
            });
          this.apiService.getPredictionByCrewD2(
            this.dataService.plantCode,
            this.formatDateToString(this.dateArr[1].value),
            this.formatDateToString(this.dateArr[1].value)
          );
          this.getWeatherData(
            this.dataService.plantCode,
            this.formatDateToString(this.dateArr[1].value),
            1
          );
          this.apiService.currentHolidayPredictionD2
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((d) => {
              this.holidayArr[1] = d[0]['feature5'].substring(0, d[0]['feature5'].indexOf(':'));
            });
          this.apiService.getHolidayPredictionD2(
            this.dataService.plantCode,
            this.formatDateToString(this.dateArr[1].value),
            this.formatDateToString(this.dateArr[1].value)
          );
        }
        if (data[0].max_date) {
          this.maxDate = this.formatStringToDate(data[0].max_date);
        }
        if (data[0].max_date === null) {
          this.maxDate = null;
          this.router.navigate(['planners-worksheet']);
        }
      });
    this.dataService._plantCode.subscribe((v) => {
      this.dataArr = [];
      this.holidayArr = [];
      this.dataService.plantCode = v;
    });
  }

  onCardData(data) {
    data.created_by = this.dataService.givenName;
    data.plantCode = this.dataService.plantCode;
    this.router.navigate(['/level/production-group'], {
      queryParams: {
        plantCode: this.dataService.plantCode,
        crew: data.cardName,
        startDate: data.startDate,
        endDate: data.endDate
      }
    });
    this.dataService.crew = data.cardName;
    this.dataService.startDate = data.startDate;
    this.dataService.endDate = data.endDate;
  }

  onFeedbackData(data) {
    data.userId = this.dataService.userId;
    data.created_by = this.dataService.givenName;
    data.plantCode = this.dataService.plantCode;
    data.crew = data.cardName;
    this.dialog.open(FeedbackComponent, {
      width: '500px',
      data
    });
  }

  onDialogData(data) {
    console.log(data)
    data.selectedOptions = 'all';
    data.created_by = this.dataService.givenName;
    data.plantCode = this.dataService.plantCode;
    data.crew = data.cardName;
    this.dialog.open(DialogComponent, {
      width: '1000px',
      minHeight: '300px',
      data
    });
  }

  getDate(date, i) {
    this.dataService.startDate = this.formatDateToString(date);
    this.dataService.endDate = this.formatDateToString(date);
    const dateArr = [];
    dateArr[i] = this.formatDateToString(date);
    if (i === 0) {
      this.apiService.getPredictionByCrewD1(
        this.dataService.plantCode,
        dateArr[0],
        dateArr[0]
      );

      this.getWeatherData(
        this.dataService.plantCode,
        this.formatDateToString(this.dateArr[0].value),
        0
      );
      this.apiService.getHolidayPredictionD1(
        this.dataService.plantCode,
        dateArr[0],
        dateArr[0]
      );
    } else {
      this.apiService.getPredictionByCrewD2(
        this.dataService.plantCode,
        dateArr[1],
        dateArr[1]
      );
      this.getWeatherData(
        this.dataService.plantCode,
        this.formatDateToString(this.dateArr[1].value),
        1
      );
      this.apiService.getHolidayPredictionD2(
        this.dataService.plantCode,
        dateArr[1],
        dateArr[1]
      );
    }
  }

  getWeatherData(plantCode, date, index) {
    this.weatherService
      .getWeatherByDate(plantCode, date)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data.length === 1) {
          this.weatherType[index] = 'forecast';
          this.temperatureMin[index] = parseInt(data[0].temperaturemin);
          this.temperatureMax[index] = parseInt(data[0].temperaturemax);
        } else if (data.length > 1) {
          this.weatherType[index] = 'history';
          const labels = data.map((data) => data.hourly);
          const dataLabels = data.map((data) => data.temperature);
          this.labels[index] = labels;
          this.dataSets[index] = [
            {
              label: 'Temperature',
              borderColor: 'rgba(256, 256, 0, 0.3)',
              borderWidth: 1,
              data: dataLabels
            }
          ];
        } else {
          this.weatherType[index] = 'none';
        }
      });
  }

  formatStringToDate(dateStr) {
    const parts = dateStr.split('-');
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    return date;
  }

  formatDateToString(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
