import { Component, OnInit, Input } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.sass']
})
export class ChartComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  @Input() datasets: ChartDataSets[];
  @Input() labels: Label[];
  @Input() options: ChartOptions;
  @Input() colors: Color[];
  @Input() legend: Boolean;
  @Input() chartType: ChartType;
  @Input() plugins: [];

  constructor() {}

  ngOnInit() {}
}
