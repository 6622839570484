<div
  class="inner-container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="space-between"
>
  <h1 class="mat-h1">{{ dataService.plantName | titlecase }} Configuration</h1>

  <button
    mat-icon-button
    id="filter-button"
    matTooltip="Configuration Change Request"
    [matTooltipPosition]="'left'"
    (click)="onRequestConfigChangeClick()"
  >
    <mat-icon aria-hidden="false" aria-label="Filter">support_agent</mat-icon>
  </button>
</div>

<mat-card class="mat-elevation-z8">
  <mat-card-content>
    <mat-tab-group class="tab-custom-overflow">
      <mat-tab label="Teams">
        <button
        mat-icon-button
   matTooltip="Export to Excel"
   [matTooltipPosition]="'above'"
   (click)="export()"
 >
   <mat-icon aria-hidden="false" aria-label="Export">save_alt</mat-icon>
 </button> <span style="color:blue">* Note : Any configration changes done on/after tuesday will be only effect in next week predictions <br> For any Dept/production line changes please raise a configration change request.</span>
        <app-config-teams
          [productionTeams]="dataService.productionTeams"
        ></app-config-teams>
       
      </mat-tab>
      <mat-tab label="Holiday/Shutdown Dates">
        <mat-card fxFlex class="mat-elevation-z8">
          <mat-card-content>
            <app-config-holiday-schedule
              [holidayScheduledDates]="dataService.holidayScheduledDates"
            >
            </app-config-holiday-schedule>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Optional Production">
        <mat-card fxFlex class="mat-elevation-z8">
          <mat-card-content>
            <app-config-sunday-schedule
              [sundayScheduledDates]="dataService.sundayScheduledDates"
            >
            </app-config-sunday-schedule>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Absence Codes">
        <mat-card fxFlex class="mat-elevation-z8">
          <mat-card-content>
            <app-config-absence-codes
              [absenceCodes]="dataService.absenceCodes"
            ></app-config-absence-codes>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Shift Timings">
        <mat-card fxFlex class="mat-elevation-z8">
          <mat-card-content>
            <app-config-shift-timings
              [shiftTimings]="dataService.shiftTimings"
            ></app-config-shift-timings>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>

<div
  class="card-container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="space-around"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
></div>
