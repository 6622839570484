<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="space-around"
  fxLayoutGap="5px"
  fxLayoutGap.xs="5px"
>
  <h1 *ngIf="maxDate === null">
    Please navigate to the
    <a [routerLink]="['/planners-worksheet']">Planner's Worksheet</a>
  </h1>
  <mat-card fxFlex *ngFor="let data of dataArr; let i = index">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
      <app-datepicker
        [date]="dateArr[i]"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (getDate)="getDate($event, i)"
      ></app-datepicker>
      <div class="weather" *ngIf="weatherType[i] === 'forecast'">
        <div class="weatherLabel">Temperature:</div>
        <div class="min">
          <div class="temperature">
            <div class="number">{{ temperatureMin[i] }}</div>
            <div class="scale">&deg;F</div>
          </div>
          <div class="temperatureDetails">Min</div>
        </div>
        <div class="max">
          <div class="temperature">
            <div class="number">{{ temperatureMax[i] }}</div>
            <div class="scale">&deg;F</div>
          </div>
          <div class="temperatureDetails">Max</div>
        </div>
      </div>
      <app-chart
        *ngIf="weatherType[i] === 'history'"
        [datasets]="dataSets[i]"
        [labels]="labels[i]"
        [options]="options"
        [colors]="colors"
        [legend]="legend"
        [chartType]="chartType"
        [plugins]="plugins"
        [height]="chartHeight"
        [width]="chartWidth"
      ></app-chart>
    </div>
    <div
      class="inner-container"
      fxLayout="row"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayoutAlign="space-around"
      fxLayoutGap="2px"
      fxLayoutGap.sm="2px"
      fxLayoutGap.xs="2px"
    >
      <span *ngIf="data && data.length === 0">Holiday/Shutdown</span>
      <app-card
        fxFlex
        [data]="d"
        *ngFor="let d of data"
        (cardData)="onCardData($event)"
        (feedbackData)="onFeedbackData($event)"
        (dialogData)="onDialogData($event)"
      ></app-card>
    </div>
    <div
      fxLayout="column"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayoutAlign="space-around"
      *ngIf="data.length !== 0"
    >
      <p>Holiday: {{ holidayArr[i] }}</p>
    </div>
  </mat-card>
</div>
