import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { share, map } from 'rxjs/operators';
import { Card } from '../components/layout/card/card.interface';
import { DataSource } from '../components/layout/dialog/dialog.interface';
import { environment } from '../../environments/environment';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

const API_URL = environment.apiUrl;

interface FilterConfig {
  crew: string;
  dept: string;
  dept_name: string;
  production_line: string;
}

interface DataLoadUpdates {
  last_Data_Loaded_On: string;
  predicted_Data_UpTo: string;
  actuals_Last_Data_Updated: string;
}
interface FilterDefaultDates {
  min_date: string;
  max_date: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  filterConfig: FilterConfig[] = [
    {
      crew: '',
      dept: '',
      dept_name: '',
      production_line: ''
    }
  ];

  filterDefaultDates: FilterDefaultDates[] = [
    {
      min_date: '',
      max_date: ''
    }
  ];

  dataLoadUpdates: DataLoadUpdates[] = [
    {
      last_Data_Loaded_On: '',
      predicted_Data_UpTo: '',
      actuals_Last_Data_Updated: ''
    }
  ];

  predictionByCrew: Card[] = [
    {
      cardName: '',
      workDate: '',
      startDate: '',
      endDate: '',
      actual: 0,
      predicted: 0,
      predictedLow: 0,
      predictedHigh: 0,
      features: ''
    }
  ];

  predictionByDept: Card[] = [
    {
      cardName: '',
      workDate: '',
      startDate: '',
      endDate: '',
      actual: 0,
      predicted: 0,
      predictedLow: 0,
      predictedHigh: 0,
      features: ''
    }
  ];

  predictionByProductionLine: Card[] = [
    {
      cardName: '',
      workDate: '',
      startDate: '',
      endDate: '',
      actual: 0,
      predicted: 0,
      predictedLow: 0,
      predictedHigh: 0,
      features: ''
    }
  ];

  userDetails: any = [
    {
      user_id: '',
      user_claims: [{}, {}, {}, {}, {}, {}, {}, {}, { val: '' }]
    }
  ];

  private _userDetails = new Subject();
  currentUserDetails = this._userDetails.asObservable();

  private _filterConfig = new BehaviorSubject(this.filterConfig);
  currentFilterConfig = this._filterConfig.asObservable();

  private _filterDefaultDates = new BehaviorSubject(this.filterDefaultDates);
  currentFilterDefaultDates = this._filterDefaultDates.asObservable();

  private _dataLoadUpdates = new Subject();
  currentdataLoadUpdates = this._dataLoadUpdates.asObservable();

  private _predictionByCrew = new Subject();
  currentPredictionByCrew = this._predictionByCrew.asObservable();

  private _predictionByCrewD1 = new Subject();
  currentPredictionByCrewD1 = this._predictionByCrewD1.asObservable();

  private _predictionByCrewD2 = new Subject();
  currentPredictionByCrewD2 = this._predictionByCrewD2.asObservable();

  private _holidayPredictionD1 = new Subject();
  currentHolidayPredictionD1 = this._holidayPredictionD1.asObservable();

  private _holidayPredictionD2 = new Subject();
  currentHolidayPredictionD2 = this._holidayPredictionD2.asObservable();

  private _predictionByDept = new Subject();
  currentPredictionByDept = this._predictionByDept.asObservable();

  private _predictionByProductionLine = new Subject();
  currentPredictionByProductionLine = this._predictionByProductionLine.asObservable();

  private _catFirstHourActualsAggregation = new Subject();
  currentCatFirstHourActualsAggregation = this._catFirstHourActualsAggregation.asObservable();

  private _catEndOfShiftActualsAggregation = new Subject();
  currentCatEndOfShiftActualsAggregation = this._catEndOfShiftActualsAggregation.asObservable();

  private _catPeopleSoftActualsAggregation = new Subject();
  currentCatPeopleSoftActualsAggregation = this._catPeopleSoftActualsAggregation.asObservable();

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) { }

  getFilterConfigForPlant(plantcode): void {
    forkJoin(
      this.httpClient
        .get(
          `${API_URL}/dashboard/getFilterConfigForPlant/plant_code=${plantcode}`
        )
        .pipe(map((response: Response) => response)),
      this.httpClient
        .get(
          `${API_URL}/dashboard/getFilterDefaultDates/plantcode=${plantcode}`
        )
        .pipe(
          map((response: Response) => response),
          share()
        )
    ).subscribe(
      (data: any) => {
        this._filterConfig.next(data[0]);
        this._filterDefaultDates.next(data[1]);
      },
      (error) => console.log(error)
    );
  }

  getDataLoadUpdatesForPlant(plantcode) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getDataLoadUpdatesForPlant/plant_code=${plantcode}`
      )
      .pipe(share())
      .subscribe(
        (res: DataLoadUpdates[]) => {
          this._dataLoadUpdates.next(res);
        },
        (error) =>
          console.log('Could not get data for getDataLoadUpdatesForPlant.')
      );
  }

  getPredictionByCrew(plantcode, startdate, enddate) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getPredictionByCrew/plantcode=${plantcode}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: Card[]) => {
          this._predictionByCrew.next(res);
        },
        (error) => console.log('Could not get data for getPredictionByCrew.')
      );
  }

  getPredictionByCrewD1(plantcode, startdate, enddate) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getPredictionByCrew/plantcode=${plantcode}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: Card[]) => {
          this._predictionByCrewD1.next(res);
        },
        (error) => console.log('Could not get data for getPredictionByCrew.')
      );
  }

  getPredictionByCrewD2(plantCode, startdate, enddate) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getPredictionByCrew/plantcode=${plantCode}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: Card[]) => {
          this._predictionByCrewD2.next(res);
        },
        (error) => console.log('Could not get data for getPredictionByCrew.')
      );
  }
  getHolidayPredictionD1(plantcode, startdate, enddate) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getHolidayPrediction/plantcode=${plantcode}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: Card[]) => {
          this._holidayPredictionD1.next(res);
        },
        (error) => console.log('Could not get data for getHolidayPrediction.')
      );
  }

  getHolidayPredictionD2(plantCode, startdate, enddate) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getHolidayPrediction/plantcode=${plantCode}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: Card[]) => {
          this._holidayPredictionD2.next(res);
        },
        (error) => console.log('Could not get data for getHolidayPrediction.')
      );
  }
  getPredictionByDept(plantcode, crew, startdate, enddate) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getPredictionByDept/plantcode=${plantcode}&crew=${crew}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: Card[]) => {
          this._predictionByDept.next(res);
        },
        (error) => {
          console.log('Could not get prediction by department.');
        }
      );
  }

  getPredictionByProductionLine(plantcode, crew, dept, startdate, enddate) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getPredictionByProductionLine/plantcode=${plantcode}&crew=${crew}&dept=${dept}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: Card[]) => {
          this._predictionByProductionLine.next(res);
        },
        (error) => {
          console.log('Could not get prediction by production line');
        }
      );
  }

  catFirstHourActualsAggregation(
    selectedValue,
    plantcode,
    crew,
    dept_name,
    production_line,
    startdate,
    enddate
  ) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getCatFirstHourActualsAbsenceAggregation/selectedvalue=${selectedValue}&plantcode=${plantcode}&crew=${crew}&dept_name=${dept_name}&production_line=${production_line}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: DataSource[]) => {
          this._catFirstHourActualsAggregation.next(res);
        },
        (error) => {
          console.log('Could not get cat aggregation');
        }
      );
  }
  catEndOfShiftActualsAggregation(
    selectedValue,
    plantcode,
    crew,
    dept_name,
    production_line,
    startdate,
    enddate
  ) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getCatEndOfShiftActualsAbsenceAggregation/selectedvalue=${selectedValue}&plantcode=${plantcode}&crew=${crew}&dept_name=${dept_name}&production_line=${production_line}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: DataSource[]) => {
          this._catEndOfShiftActualsAggregation.next(res);
        },
        (error) => {
          console.log('Could not get cat aggregation');
        }
      );
  }

  catPeopleSoftActualsAggregation(
    selectedValue,
    plantcode,
    crew,
    dept_name,
    production_line,
    startdate,
    enddate
  ) {
    this.httpClient
      .get(
        `${API_URL}/dashboard/getCatPeopleSoftActualsAbsenceAggregation/selectedvalue=${selectedValue}&plantcode=${plantcode}&crew=${crew}&dept_name=${dept_name}&production_line=${production_line}&startdate=${startdate}&enddate=${enddate}`
      )
      .pipe(share())
      .subscribe(
        (res: DataSource[]) => {
          this._catPeopleSoftActualsAggregation.next(res);
        },
        (error) => {
          console.log('Could not get cat aggregation');
        }
      );
  }

  submitFeedback(feedBackData) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(feedBackData);
    return this.httpClient.post(`${API_URL}/dashboard/submitFeedback`, body, {
      headers
    });
  }

  authenticateUser() {
    this.httpClient
      .get(
        window.location.protocol + '//' + window.location.hostname + '/.auth/me'
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._userDetails.next(res);
        },
        (error) => {
          console.log('Could not authenticate User.');
          this.openSnackBar(
            `Authentication failed. Please reload application.`,
            'Ok',
            'warn'
          );
        }
      );
  }

  openSnackBar(message: string, action: string, color?: string) {
    this.snackBar.open(message, action, {
      panelClass: [color]
    });
  }
}
