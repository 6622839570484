<div
  class="inner-container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="space-between"
>
  <h1 class="mat-h1">{{ pageTitle }}</h1>
  <div
    class="rhs-container"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between"
  >
    <app-date-range-picker
      class="filterField"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [dateLimit]="dateLimit"
      [selected]="selected"
      (getDate)="getDate($event)"
    >
    </app-date-range-picker>

    <button
      mat-button
      (click)="exportAsXLSX()"
      matTooltip="Export to Excel"
      [matTooltipPosition]="'below'"
    >
      <mat-icon aria-hidden="false" aria-label="Menu">save_alt</mat-icon>
    </button>
  </div>
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab
      [label]="crew"
      *ngFor="let crew of dataService.crewArr; index as i"
    >
      <app-department-summary
        [data]="predictionSummaryArr[i]"
        [isWeek]="isWeek"
        [userId]="dataService.userId"
        [plantCode]="dataService.plantCode"
      ></app-department-summary>
    </mat-tab>
  </mat-tab-group>
</mat-card>
