import { Component, OnInit, Input } from '@angular/core';
import { ManpowerPlanningService } from 'src/app/services/manpower-planning.service';

@Component({
  selector: 'app-date-summary',
  templateUrl: './date-summary.component.html',
  styleUrls: ['./date-summary.component.scss']
})
export class DateSummaryComponent implements OnInit {
  @Input() displayedColumns: string[];
  @Input() data;
  @Input() action_code;
  @Input() userId;
  @Input() plantCode;

  constructor(private manpowerPlanningService: ManpowerPlanningService) {}

  ngOnInit() {}

  getTotalActual() {
    return this.data
      .map((t) => t.actual)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalHomeRoll() {
    return this.data
      .map((t) => Number(t.home_roll))
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalOffStd() {
    return this.data
      .map((t) => Number(t.off_std))
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalPredicted() {
    return this.data
      .map((t) => t.predicted)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotalTptScheduled() {
    return this.data
      .map((t) => Number(t.tpt_scheduled))
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal(data, key) {
    return data.map((t) => t[key]).reduce((acc, value) => acc + value, 0);
  }

  saveData() {
    const data: any = {};
    data.plantcode = this.data[0].plant_code;
    data.username = this.userId;
    data.mp_updates = this.data;
    data.mp_updates.map((v) => {
      v.username = this.userId;
      v.action_code = this.action_code;
      v.status = 'N';
    });
    this.manpowerPlanningService
      .loadMpUpdatesToTemp(data)
      .subscribe((res: any) => {
        // console.log('success 1');
        this.manpowerPlanningService
          .saveMpUpdates(data)
          .subscribe((res: any) => {
            // console.log('success 2');
          });
      });
  }
}
