import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';

import { DatePipe } from '@angular/common';
import { TableUtil } from 'src/app/utils/tableUtil';

import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { HrDailyReportService } from 'src/app/services/hr-daily-report.service';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';



@Component({
  selector: 'app-hr-daily-report',
  templateUrl: './hr-daily-report.component.html',
  styleUrls: ['./hr-daily-report.component.scss']
})

export class HrDailyReportComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  pageTitle: string;

  startDate: string;
  endDate: string;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  selected: any;
  includeHolidays: boolean = false;

  hrDailyReport: any;
  hrDailyReportAggTotals: any;
  hrDailyMoparReport: any;
  hrDailyMoparReportAggTotals: any;
  hrDailyReportColumns = ['facility', 'country', 'empGroup', 'employees', 'shortAndLongTermIllnessOrInjuryWithoutCovid', 'covid19Related', 'sicknessInjuryCovid19Subtotal', 'familyMedicalLeaveActFlma', 'absencesForOtherReasons', 'fmlaOtherAbsenceReasonSubtotal', 'totalAbsentEmployees', 'sandlPercent', 'covid19RelatedPercent', 'sicknessInjuryCovid19SubtotalPercent', 'familyMedicalLeaveActFlmaPercent', 'absencesForOtherReasonsPercent', 'fmlaOtherAbsenceReasonSubtotalPercent', 'totalAbsentEmployeesPercent', 'plannedAbsences', 'unplannedAbsences'];
  hrDailyReportAggegatedTotalsColumns = ['totalType', 'country', 'empGroup', 'employees', 'shortAndLongTermIllnessOrInjuryWithoutCovid', 'covid19Related', 'sicknessInjuryCovid19Subtotal', 'familyMedicalLeaveActFlma', 'absencesForOtherReasons', 'fmlaOtherAbsenceReasonSubtotal', 'totalAbsentEmployees', 'sandlPercent', 'covid19RelatedPercent', 'sicknessInjuryCovid19SubtotalPercent', 'familyMedicalLeaveActFlmaPercent', 'absencesForOtherReasonsPercent', 'fmlaOtherAbsenceReasonSubtotalPercent', 'totalAbsentEmployeesPercent', 'plannedAbsences', 'unplannedAbsences'];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    public dataService: DataService,
    private datePipe: DatePipe,
    private hrDailyReportService: HrDailyReportService) { }

  ngOnInit(): void {

    this.pageTitle = 'HR Daily Report';

    this.minDate = moment(this.dataService.minDate, 'YYYY-MM-DD');
    this.maxDate = moment(this.dataService.maxDate, 'YYYY-MM-DD');

    this.selected = {
      startDate: moment(this.dataService.startDate, 'YYYY-MM-DD'),
      endDate: moment(this.dataService.endDate, 'YYYY-MM-DD')
    };

  }

  getDate(range) {
    if (range.startDate && range.endDate) {
      this.startDate = moment(range.startDate).format('YYYY-MM-DD');
      this.endDate = moment(range.endDate).format('YYYY-MM-DD');
    }
    else {
      this.startDate = this.datePipe.transform(
        this.maxDate.toDate(),
        'yyyy-MM-dd'
      );;
      this.endDate = this.datePipe.transform(
        this.maxDate.toDate(),
        'yyyy-MM-dd'
      );;
    }

    this.getHrDailyReport(this.startDate, this.endDate, this.includeHolidays);
    this.getHrMoparDailyReport(this.startDate, this.endDate,this.includeHolidays);
  }

  toggle(event: MatSlideToggleChange) {
    this.getHrDailyReport(this.startDate, this.endDate, event.checked)
    this.getHrMoparDailyReport(this.startDate, this.endDate, event.checked)
  }

  getHrDailyReport(startDate, endDate, includeHolidays) {
    this.hrDailyReportService.getHrDailyReport(startDate, endDate, includeHolidays)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.hrDailyReport = data;
      });
    this.hrDailyReportService.getHrDailyAggTotal(startDate, endDate, includeHolidays)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.hrDailyReportAggTotals = data;
      });
  }
  getHrMoparDailyReport(startDate, endDate,includeHolidays) {
    this.hrDailyReportService.getHrMoparDailyReport(startDate, endDate,includeHolidays)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.hrDailyMoparReport = data;
      });
    this.hrDailyReportService.getHrMoparDailyAggTotal(startDate, endDate,includeHolidays)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.hrDailyMoparReportAggTotals = data;
      });
  }

  exportAsExcel(reportType) {
    if (reportType === "MANUFACTURING") {
      TableUtil.exportHrDailyReport(this.hrDailyReport, this.hrDailyReportAggTotals, "Manufacturing - HrDailyReport");
    }
    else if (reportType === "MOPAR") {
      TableUtil.exportHrDailyReport(this.hrDailyMoparReport, this.hrDailyMoparReportAggTotals, "Mopar - HrDailyReport");
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
