import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { PredictionSummaryService } from 'src/app/services/prediction-summary.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-prediction-summary',
  templateUrl: './prediction-summary.component.html',
  styleUrls: ['./prediction-summary.component.scss']
})
export class PredictionSummaryComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  pageTitle: string;
  crew: string;
  startDate: string;
  endDate: string;
  initialStartDate: string;
  initialEndDate: string;
  isWeek: boolean;
  isWeekStart: boolean;
  isWeekEnd: boolean;
  predictionSummaryArr = [];
  minDate: moment.Moment;
  maxDate: moment.Moment;
  dateLimit: number;
  selected: any;

  constructor(
    private apiService: ApiService,
    public dataService: DataService,
    private predictionSummaryService: PredictionSummaryService,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.pageTitle = 'Prediction Summary';

    this.dataService._plantCode.subscribe(v => {
      this.initialStartDate = undefined;
      this.initialEndDate = undefined;
    });

    this.apiService.currentFilterDefaultDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.predictionSummaryArr = [];
        if (data[0].min_date) {
          this.minDate = moment(data[0].min_date, 'YYYY-MM-DD');
        }
        if (data[0].max_date) {
          this.maxDate = moment(data[0].max_date, 'YYYY-MM-DD');

          let subtractDays;
          if (this.dataService.plantCode === '2452') {
            subtractDays = 5;
          } else {
            subtractDays = 6;
          }

          this.selected = {
            startDate: moment(data[0].max_date, 'YYYY-MM-DD').subtract(
              subtractDays,
              'days'
            ),
            endDate: moment(data[0].max_date, 'YYYY-MM-DD')
          };
          this.startDate = moment(this.selected.startDate).format('YYYY-MM-DD');
          this.endDate = moment(this.selected.endDate).format('YYYY-MM-DD');
          this.dateLimit = 6;

          for (let i = 0; i < this.dataService.crewArr.length; i++) {
            if (this.dataService.crewArr[i] !== '') {
              this.getPredictionSummaryByCrew(
                this.dataService.crewArr[i],
                i,
                this.startDate,
                this.endDate
              );
            }
          }
        }
      });
  }

  getPredictionSummaryByCrew(crew, i, startDate, endDate) {
    if (this.initialStartDate === undefined) {
      this.initialStartDate = this.startDate;
    }
    if (this.initialEndDate === undefined) {
      this.initialEndDate = this.endDate;
    }

    this.isWeek = this.checkStartAndEndOfWeek(startDate, endDate);

    this.predictionSummaryService
      .getPredictionSummaryByCrew(
        this.dataService.plantCode,
        crew,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.predictionSummaryArr[i] = data;
      });
  }

  checkStartAndEndOfWeek(start, end) {
    const startDay = moment(start).day();
    const endDay = moment(end).day();
    if (startDay === 0 || startDay === 1) {
      this.isWeekStart = true;
    } else {
      this.isWeekStart = false;
    }
    if (endDay === 5 || endDay === 6) {
      this.isWeekEnd = true;
    } else {
      this.isWeekEnd = false;
    }

    if (this.isWeekStart && this.isWeekEnd) {
      return true;
    } else {
      return false;
    }
  }

  getDate(range) {
    if (range.startDate !== null) {
      this.startDate = moment(range.startDate).format('YYYY-MM-DD');
      this.endDate = moment(range.endDate).format('YYYY-MM-DD');

      for (let i = 0; i < this.dataService.crewArr.length; i++) {
        if (this.dataService.crewArr[i] !== '') {
          this.getPredictionSummaryByCrew(
            this.dataService.crewArr[i],
            i,
            this.startDate,
            this.endDate
          );
        }
      }
    }
  }

  exportAsXLSX(): void {
    const plantsArr: any[] = this.dataService.allPlantsConfig;
    const plantNameAbbr = plantsArr.filter(
      obj => obj.plant_code === this.dataService.plantCode
    )[0].plant_abbr;

    const crewArr = this.dataService.crewArr;
    const sheets = {};
    for (let i = 0; i < this.predictionSummaryArr.length; i++) {
      let w: XLSX.WorkSheet;
      w = XLSX.utils.json_to_sheet(this.predictionSummaryArr[i]);
      sheets[crewArr[i]] = w;
    }
    const workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: crewArr
    };
    this.excelService.exportAsExcelFile(
      workbook,
      plantNameAbbr + ' - PredictionSummary'
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
