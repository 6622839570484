<section
  fxLayout="column wrap"
  fxLayoutGap="20px"
  fxLayoutAlign="center center"
>
  <div fxFlex>
    <h1>404</h1>
  </div>
  <div fxFlex>
    Looks like that page doesn't exist.
  </div>
  <button (click)="goToDashboard()" mat-raised-button>
    GO TO DASHBOARD
  </button>
</section>
