import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import packageInfo from '../../../../../package.json';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  currentYear: string;
  env: string;
  version: string;
  lastDataLoadedOn: string;
  predictedDataUpTo: string;
  actualsLastDataUpdated: string;

  @Output() changeTheme: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    public dataService: DataService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.currentYear = String(new Date().getFullYear());
    this.env = environment.envName;
    this.version = packageInfo.version;;
    this.apiService.currentdataLoadUpdates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data[0] !== undefined) {
          this.lastDataLoadedOn = data[0].last_Data_Loaded_On;
          this.predictedDataUpTo = data[0].predicted_Data_UpTo;
          this.actualsLastDataUpdated = data[0].actuals_Last_Data_Updated;
        }
      });

    this.dataService._plantCode.subscribe(v => {
      this.dataService.plantCode = v;
      this.apiService.getDataLoadUpdatesForPlant(this.dataService.plantCode);
    });
  }

  toggleDarkTheme(checked: boolean) {
    this.changeTheme.emit(checked);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
