import * as XLSX from 'xlsx';

export class TableUtil {
  static exportTableToExcel(tableId: string, name?: string) {
    const targetTableElm = document.getElementById(tableId);
    const wb = XLSX.utils.table_to_book(targetTableElm);
    XLSX.writeFile(wb, `${name}.xlsx`);
  }

  static exportArrayToExcel(arr: any[], name?: string) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, `${name}.xlsx`);
  }

  static exportHrDailyReport(tableId: any, tableId2: any, name?: string) {
    var hrDailyReport = tableId.concat(tableId2);
    hrDailyReport.map(x => {
      if (x.AGGTOTALS) {
        x.FACILITY = x.AGGTOTALS;
        delete x.AGGTOTALS;
      }
    })
    const targetTableElm: XLSX.WorkSheet = XLSX.utils.json_to_sheet(hrDailyReport);
    // const targetTableElm2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tableId2);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, targetTableElm, name);

    var hrDailyReportCols = [
      { wch: 41 },
      { wch: 8 },
      { wch: 11 },
      { wch: 10 },
      { wch: 55 },
      { wch: 17 },
      { wch: 34 },
      { wch: 31 },
      { wch: 29 },
      { wch: 38 },
      { wch: 24 },
      { wch: 18 },
      { wch: 20 },
      { wch: 12 },
      { wch: 23 },
      { wch: 40 },
      { wch: 37 },
      { wch: 35 },
      { wch: 44 },
      { wch: 30 },
      { wch: 24 },
      { wch: 27 }
    ];

    // var hrDailyAggTotalCols = [
    //   { wch: 10 },
    //   { wch: 8 },
    //   { wch: 11 },
    //   { wch: 10 },
    //   { wch: 55 },
    //   { wch: 17 },
    //   { wch: 34 },
    //   { wch: 31 },
    //   { wch: 29 },
    //   { wch: 38 },
    //   { wch: 24 },
    //   { wch: 18 },
    //   { wch: 20 },
    //   { wch: 12 },
    //   { wch: 23 },
    //   { wch: 40 },
    //   { wch: 37 },
    //   { wch: 35 },
    //   { wch: 44 },
    //   { wch: 30 },
    //   { wch: 24 },
    //   { wch: 27 }
    // ];

    targetTableElm['!cols'] = hrDailyReportCols;
    //targetTableElm2['!cols'] = hrDailyAggTotalCols;

    XLSX.writeFile(wb, `${name}.xlsx`);
  }
}
