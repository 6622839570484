import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

interface PlantData {
  plant_code: string;
  plant_abbr: string;
  plant_name: string;
  country: string;
  division: string;
  grp: string;
  active: string;
  time_zone: string;
  shift_pattern: string;
  state: string;
  postal_code: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserAccessService {
  private _plantUserRole = new Subject();
  currentPlantUserRole = this._plantUserRole.asObservable();

  private _managementUserRole = new Subject();
  currentManagementUserRole = this._managementUserRole.asObservable();

  private _plantsDataAssignedToUser = new Subject();
  currentPlantsDataAssignedToUser = this._plantsDataAssignedToUser.asObservable();

  private _usersByPlant = new Subject();
  currentUsersByPlant = this._usersByPlant.asObservable();

  constructor(private httpClient: HttpClient) { }

  getAllPlantsForManagementUsers(): void {
    this.httpClient
      .get(`${API_URL}/userAccess/getAllPlantsForManagementUsers`)
      .pipe(share())
      .subscribe(
        (res: PlantData[]) => {
          this._plantsDataAssignedToUser.next(res);
        },
        error =>
          console.log('Could not get data for getAllPlantsForManagementUsers.')
      );
  }

  getPlantsAssignedToUser(user_id): void {
    this.httpClient
      .get(`${API_URL}/userAccess/getPlantsAssignedToUser/user_id=${user_id}`)
      .pipe(share())
      .subscribe(
        (res: PlantData[]) => {
          this._plantsDataAssignedToUser.next(res);
        },
        error => console.log('Could not get data for getPlantsAssignedToUser.')
      );
  }

  getUsersByPlant(plantCode) {
    this.httpClient
      .get(`${API_URL}/userAccess/getUsersByPlant/plant_code=${plantCode}`)
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._usersByPlant.next(res);
        },
        error => console.log('Could not find plant user role.')
      );
  }

  getManagementUserRole(userId) {
    this.httpClient
      .get(`${API_URL}/userAccess/getManagementUserRole/user_id=${userId}`)
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._managementUserRole.next(res);
        },
        error => console.log('Could not find management user role.')
      );
  }

  getPlantUserRole(plantCode, userId) {
    this.httpClient
      .get(
        `${API_URL}/userAccess/getPlantUserRole/plant_code=${plantCode}&user_id=${userId}`
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._plantUserRole.next(res);
        },
        error => console.log('Could not find user role.')
      );
  }

  createUser(data) {
    return this.httpClient.post(`${API_URL}/userAccess/createUser`, data);
  }

  modifyUser(data) {
    return this.httpClient.post(`${API_URL}/userAccess/modifyUser`, data);
  }

  deleteUser(data) {
    return this.httpClient.post(`${API_URL}/userAccess/deleteUser`, data);
  }

  getManagementUsers() {
    return this.httpClient.get(`${API_URL}/userAccess/getManagementUsers`);
  }
}
