<h1 class="mat-h1">
  {{ pageTitle | titlecase }} for {{ dataService.plantName | titlecase }}
</h1>
<div
  fxLayout="column"
  fxLayoutAlign="space-around"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
>
  <app-notification
    *ngFor="let weatherNotification of dataService.weatherNotifications"
    [notification]="weatherNotification"
    (updateNotifications)="
      notificationsService.updateWeatherNotifications($event)
    "
  ></app-notification>
</div>
