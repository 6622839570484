<h1 class="mat-h1">
  {{ pageTitle | titlecase }} for {{ dataService.plantName | titlecase }}
</h1>
<div
  fxLayout="column"
  fxLayoutAlign="space-around"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
>
  <mat-card *ngIf="dataService.userRole === 'Super User'">
    <div
      class="card-container"
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutAlign="space-around"
      fxLayoutGap="10px"
      fxLayoutGap.xs="5px"
    >
      <div fxFlex>
        Notification Type
        <mat-button-toggle-group
          #notificationType="matButtonToggleGroup"
          [value]="selectedNotificationType"
          (change)="onNotificationTypeChange(notificationType.value)"
          aria-label="Notification Type"
        >
          <mat-button-toggle value="message">
            <mat-icon>message</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="cloud">
            <mat-icon>cloud</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="warning">
            <mat-icon>warning</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div fxFlex="40">
        <mat-form-field>
          <textarea
            required
            matInput
            maxlength="1000"
            placeholder="Notification text"
            [formControl]="notificationTextFormControl"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-error *ngIf="notificationTextFormControl.hasError('required')">
            Notification text is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <app-datepicker
          [placeholder]="placeholder"
          [date]="expiryDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (getDate)="getDate($event)"
        ></app-datepicker>
      </div>

      <div fxFlex>
        <button
          [disabled]="this.notificationTextFormControl.status === 'INVALID'"
          mat-raised-button
          color="primary"
          (click)="createNotification({})"
        >
          Create Notification
        </button>
      </div>
    </div>
  </mat-card>

  <app-notification
    *ngFor="let notification of dataService.notifications"
    [notification]="notification"
    (updateNotifications)="notificationsService.updateNotifications($event)"
  ></app-notification>
</div>
