<mat-card
  *ngIf="plannerWorksheetCrewData && plannerWorksheetCrewData.length > 0"
>
  <table
    mat-table
    fxFlex
    [dataSource]="plannerWorksheetCrewData"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="workdate">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td
        mat-cell
        class="workdate"
        *matCellDef="let element"
        [attr.rowspan]="4"
      >
        {{ element.workdate }} <br />
        {{ element.workDay | uppercase }}
      </td>
      <th mat-footer-cell *matFooterCellDef>Total</th>
    </ng-container>

    <ng-container matColumnDef="crew">
      <th mat-header-cell *matHeaderCellDef>Crew</th>
      <td mat-cell *matCellDef="let element">
        {{ element.crew }}
        <span *ngIf="element.plant_code_alt_name.length > 0">
          - {{ element.plant_code_alt_name }}</span
        >
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="able_to_startUp">
      <th mat-header-cell *matHeaderCellDef>
        Able to <br />
        Start Up
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select [(value)]="element.able_to_startup">
          <mat-option value="Yes - Full Start">Yes - Full Start</mat-option>
          <mat-option value="Yes - Sporadic Production"
            >Yes - Sporadic Production</mat-option
          >
          <mat-option value="Yes - Late Start">Yes - Late Start</mat-option>
          <mat-option value="No">No</mat-option>
          <mat-option value="Scheduled Down">Scheduled Down</mat-option>
        </mat-select>
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="planned_builds">
      <th mat-header-cell *matHeaderCellDef>
        First Hour <br />
        Planned Build
      </th>
      <td mat-cell *matCellDef="let element">
        <input
          oninput="this.value=this.value.slice(0,this.maxLength)"
          type="number"
          maxlength="3"
          class="dataInputTxt"
          matInput
          [(ngModel)]="element.planned_builds"
        />
      </td>
      <th mat-footer-cell *matFooterCellDef>
        <span *ngIf="getTotal_planned_builds()">{{
          getTotal_planned_builds()
        }}</span>
      </th>
    </ng-container>

    <ng-container matColumnDef="actual_builds">
      <th mat-header-cell *matHeaderCellDef>
        First Hour <br />
        Actual Build
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="dataService.autoPopulatedFieldEntryApplicable !== 'N'">{{
          element.actual_builds
        }}</span>
        <input
          *ngIf="dataService.autoPopulatedFieldEntryApplicable === 'N'"
          oninput="this.value=this.value.slice(0,this.maxLength)"
          type="number"
          maxlength="3"
          class="dataInputTxt"
          matInput
          [(ngModel)]="element.actual_builds"
        />
      </td>
      <th mat-footer-cell *matFooterCellDef>
        <span *ngIf="getTotal_actual_builds()">{{
          getTotal_actual_builds()
        }}</span>
      </th>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef>Comments</th>
      <td mat-cell *matCellDef="let element">
        <input
          type="textarea"
          rows="2"
          maxlength="2000"
          class="commentInputTxtArea"
          matInput
          [(ngModel)]="element.comments"
        />
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button
          class="saveBtn"
          color="primary"
          mat-icon-button
          (click)="saveCrewData(element)"
        >
          <mat-icon aria-hidden="false" aria-label="Save">save</mat-icon>
        </button>
      </td>
      <th mat-footer-cell *matFooterCellDef></th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedCrewColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedCrewColumns"></tr>
    <tr
      mat-footer-row
      *matFooterRowDef="displayedCrewColumns; sticky: true"
    ></tr>
  </table>
</mat-card>

<div
  class="mat-caption"
  *ngIf="
    plannerWorksheetCrewData &&
    plannerWorksheetCrewData.length > 0 &&
    start === today
  "
>
  Note:
  <span *ngIf="actualVehicleBuiltLastLoadedOn"
    >First Hour Actual Build as of {{ actualVehicleBuiltLastLoadedOn }} (From
    PFS),
  </span>
  <span *ngIf="plannedAbsLastLoadedOn"
    >Planned ABS as of {{ plannedAbsLastLoadedOn }} (From CATS),
  </span>
  <span *ngIf="actualAbsLastLoadedOn"
    >First Hour Unplanned & Planned ABS as of {{ actualAbsLastLoadedOn }} (From
    CATS)</span
  >
</div>

<mat-card>
  <mat-tab-group>
    <mat-tab
      [label]="plant.crew"
      *ngFor="let plant of localPlannerWorksheetLineData; index as i"
    >
      <div *ngIf="plant.crewData">
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let department of plant.crewData; index as i"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ department.dept }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="row" fxLayoutAlign="stretch">
              <table
                mat-table
                fxFlex
                [dataSource]="plant.crewData[i].deptData"
                class="mat-elevation-z8"
              >
                <ng-container matColumnDef="production_line">
                  <th mat-header-cell *matHeaderCellDef>Production Line</th>
                  <td
                    class="prod-line-label"
                    mat-cell
                    *matCellDef="let element"
                  >
                    {{ element.production_line }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef>Total</th>
                </ng-container>

                <ng-container matColumnDef="predicted_unplanned_abs">
                  <th mat-header-cell *matHeaderCellDef>
                    Predicted <br />
                    Unplanned <br />
                    ABS
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.predicted_unplanned_abs }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{
                      getTotal_predicted_unplanned_abs(
                        plant.crewData[i].deptData
                      )
                    }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="planned_abs">
                  <th mat-header-cell *matHeaderCellDef>Planned <br />ABS</th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable !== 'N'
                      "
                      >{{ element.planned_abs }}</span
                    >
                    <input
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable === 'N'
                      "
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.planned_abs"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_planned_abs(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="total_expected">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Total <br />Expected</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.predicted_unplanned_abs + element.planned_abs }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_total_expected(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="floaters">
                  <th mat-header-cell *matHeaderCellDef>Floater #</th>
                  <td mat-cell *matCellDef="let element">
                    <input
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.floaters"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="tpt_scheduled">
                  <th mat-header-cell *matHeaderCellDef>
                    # of Temps <br />Scheduled
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <input
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.tpt_scheduled"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_tpt_scheduled(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="off_std">
                  <th mat-header-cell *matHeaderCellDef>Off Std #</th>
                  <td mat-cell *matCellDef="let element">
                    <input
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.off_std"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="pqx">
                  <th mat-header-cell *matHeaderCellDef>PQX #</th>
                  <td mat-cell *matCellDef="let element">
                    <input
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.pqx"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="total_scheduled">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Total <br />Scheduled</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.floaters +
                        element.tpt_scheduled -
                        element.off_std -
                        element.pqx
                    }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_total_scheduled(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="actual_unplanned">
                  <th mat-header-cell *matHeaderCellDef>
                    First Hour <br />
                    Unplanned <br />
                    ABS
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable !== 'N'
                      "
                      >{{ element.actual_unplanned }}</span
                    >
                    <input
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable === 'N'
                      "
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.actual_unplanned"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_actual_unplanned(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="actual_planned">
                  <th mat-header-cell *matHeaderCellDef>
                    First Hour <br />Planned <br />
                    ABS
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable !== 'N'
                      "
                      >{{ element.actual_planned }}</span
                    >
                    <input
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable === 'N'
                      "
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.actual_planned"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_actual_planned(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="black_flags">
                  <th mat-header-cell *matHeaderCellDef>
                    First Hour <br />Black Flags
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable !== 'N'
                      "
                      >{{ element.black_flags }}</span
                    >
                    <input
                      *ngIf="
                        dataService.autoPopulatedFieldEntryApplicable === 'N'
                      "
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.black_flags"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_black_flags(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="total_actuals">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong>Total <br />Actuals</strong>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.actual_unplanned +
                        element.actual_planned +
                        element.black_flags
                    }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_total_actuals(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="diff-sceduled-planned">
                  <th mat-header-cell *matHeaderCellDef>
                    (scheduled - planned)
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.floaters +
                        element.tpt_scheduled -
                        element.off_std -
                        element.pqx -
                        (element.predicted_unplanned_abs + element.planned_abs)
                    }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="diff-planned-actuals">
                  <th mat-header-cell *matHeaderCellDef>(planned - actuals)</th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      element.predicted_unplanned_abs +
                        element.planned_abs -
                        (element.actual_unplanned +
                          element.actual_planned +
                          element.black_flags)
                    }}
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="off_crew">
                  <th mat-header-cell *matHeaderCellDef>Off Crew</th>
                  <td mat-cell *matCellDef="let element">
                    <input
                      oninput="this.value=this.value.slice(0,this.maxLength)"
                      type="number"
                      maxlength="3"
                      class="dataInputTxt"
                      matInput
                      [(ngModel)]="element.off_crew"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef>
                    {{ getTotal_off_crew(plant.crewData[i].deptData) }}
                  </th>
                </ng-container>

                <ng-container matColumnDef="notes">
                  <th mat-header-cell *matHeaderCellDef>Notes</th>
                  <td mat-cell *matCellDef="let element">
                    <input
                      type="textarea"
                      rows="2"
                      maxlength="2000"
                      class="dataInputTxtArea"
                      matInput
                      [(ngModel)]="element.notes"
                    />
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <button
                      class="saveBtn"
                      color="primary"
                      mat-icon-button
                      (click)="saveData(element)"
                    >
                      <mat-icon aria-hidden="false" aria-label="Save"
                        >save</mat-icon
                      >
                    </button>
                  </td>
                  <th mat-footer-cell *matFooterCellDef></th>
                </ng-container>

                <ng-container matColumnDef="blank">
                  <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>
                    <strong
                      >FOR
                      {{
                        plant.crewData[i].deptData[0].workdate
                          | date
                          | uppercase
                      }}
                    </strong>
                  </th>
                </ng-container>

                <ng-container matColumnDef="header-row-planned">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">
                    <strong>PLANNED</strong>
                  </th>
                </ng-container>

                <ng-container matColumnDef="header-row-scheduled">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="5">
                    <strong>SCHEDULED</strong>
                  </th>
                </ng-container>

                <ng-container matColumnDef="header-row-actuals">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="4">
                    <strong>ACTUALS</strong>
                  </th>
                </ng-container>

                <ng-container matColumnDef="header-row-reporting">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                    <strong>REPORTING</strong>
                  </th>
                </ng-container>

                <ng-container matColumnDef="header-row-actions">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                    <strong>Actions</strong>
                  </th>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="[
                    'date',
                    'header-row-planned',
                    'header-row-scheduled',
                    'header-row-actuals',
                    'header-row-reporting',
                    'blank',
                    'blank',
                    'blank'
                  ]"
                ></tr>

                <tr mat-header-row *matHeaderRowDef="displayedLineColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedLineColumns"
                ></tr>
                <tr
                  mat-footer-row
                  *matFooterRowDef="displayedLineColumns; sticky: true"
                ></tr>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
