import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from '../../layout/confirm-dialog/confirm-dialog.component';
import { Notification } from './notification.interface';
import { DataService } from 'src/app/services/data.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notification: Notification;
  @Output()
  updateNotifications = new EventEmitter<string>();

  constructor(
    private notificationsService: NotificationsService,
    public dataService: DataService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  delete(notification) {
    const message = `Are you sure you want to delete this notification?`;
    const dialogData = new ConfirmDialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      console.log(dialogResult);
      if (dialogResult) {
        this.notificationsService.delete(notification).subscribe((data) => {
          this.openSnackBar(`Notification deleted successfully`, 'Ok');
          this.update();
        });
      }
    });
  }

  update() {
    this.updateNotifications.emit();
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }

  replacePipes(s: string) {
    return s && s.replace(/\|/g, '<br />');
  }
}
