import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ManpowerPlanningService {
  constructor(private httpClient: HttpClient) {}

  loadMpUpdatesToTemp(data) {
    return this.httpClient.post(
      `${API_URL}/manpowerPlanning/loadMpUpdatesToTemp`,
      data
    );
  }

  saveMpUpdates(data) {
    return this.httpClient.post(
      `${API_URL}/manpowerPlanning/saveMpUpdates`,
      data
    );
  }
}
