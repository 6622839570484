<mat-accordion [multi]="true">
  <mat-expansion-panel
    *ngFor="let productionTeam of dataService.productionTeams"
    [expanded]="true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ productionTeam.dept_name }} ({{ productionTeam.dept_number }})
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="stretch">
      <div *ngFor="let productionLine of productionTeam.productionLines" fxFlex>
        <app-config-manage-teams
          [productionLine]="productionLine"
        ></app-config-manage-teams>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
