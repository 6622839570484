<table *ngIf="tableData && tableData.length > 0">
  <thead>
    <tr>
      <th *ngFor="let head of tableData[0] | keys">
        {{ head }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of tableData">
      <td *ngFor="let list of item | keys">{{ item[list] }}</td>
    </tr>
  </tbody>
</table>
