import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

import { Card } from '../../layout/card/card.interface';
import { FeedbackComponent } from '../../layout/feedback/feedback.component';
import { DialogComponent } from '../../layout/dialog/dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { LevelService } from 'src/app/services/level.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss']
})
export class LevelComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  pageTitle: string;
  level: string;
  data: Card[];
  chartData: [];
  cardCount: number;
  crew: string;
  productionGroup: string;
  startDate: string;
  endDate: string;
  getPredictionByCrew: any;
  getPredictionByDept: any;
  getPredictionByProductionLine: any;

  minDate: moment.Moment;
  maxDate: moment.Moment;
  selected: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private levelService: LevelService,
    public dataService: DataService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.apiService.currentFilterDefaultDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data[0].min_date) {
          this.minDate = moment(data[0].min_date, 'YYYY-MM-DD');
        }
        if (data[0].max_date) {
          this.maxDate = moment(data[0].max_date, 'YYYY-MM-DD');
        }

        this.selected = {
          startDate: moment(this.dataService.startDate, 'YYYY-MM-DD'),
          endDate: moment(this.dataService.endDate, 'YYYY-MM-DD')
        };

        if (
          this.dataService.startDate === undefined &&
          this.dataService.endDate === undefined
        ) {
          this.dataService.startDate = this.datePipe.transform(
            Date.now(),
            'yyyy-MM-dd'
          );
          this.dataService.endDate = this.datePipe.transform(
            Date.now(),
            'yyyy-MM-dd'
          );
        }
      });

    if (this.dataService.endDate === undefined) {
      this.dataService.endDate = this.dataService.startDate;
    }
    this.data = [];
    this.activatedRoute.queryParamMap
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.data = [];
        this.crew = params.get('crew');
        this.productionGroup = params.get('productionGroup');
        if (this.dataService.startDate === undefined) {
          this.startDate = params.get('startDate');
        } else {
          this.startDate = this.dataService.startDate;
        }
        if (this.dataService.endDate === undefined) {
          this.endDate = params.get('endDate');
        } else {
          this.endDate = this.dataService.endDate;
        }

        this.selected = {
          startDate: moment(this.startDate, 'YYYY-MM-DD'),
          endDate: moment(this.endDate, 'YYYY-MM-DD')
        };
        if (this.dataService.plantCode !== undefined) {
          this.loadData(
            this.crew,
            this.productionGroup,
            this.startDate,
            this.endDate
          );
        }
      });
  }

  loadData(crew, productionGroup, startDate, endDate) {
    if (this.router.url.includes('/level/production-line')) {
      if (
        crew !== '' &&
        productionGroup !== '' &&
        startDate !== null &&
        endDate !== null
      ) {
        this.pageTitle = 'Production Line';
        this.apiService.currentPredictionByProductionLine
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((data: Card[]) => {
            this.data = data;
            this.cardCount = data.length;
          });

        this.getPredictionByProductionLine = this.apiService.getPredictionByProductionLine(
          this.dataService.plantCode,
          crew,
          productionGroup,
          startDate,
          endDate
        );
        if (startDate !== endDate) {
          this.levelService
            .getPredictionDataByProductionLine(
              this.dataService.plantCode,
              crew,
              productionGroup,
              startDate,
              endDate
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: []) => {
              this.chartData = data;
            });
        }
      }
    } else if (this.router.url.includes('/level/production-group')) {
      if (crew !== '' && startDate !== null && endDate !== null) {
        this.pageTitle = 'Department';
        this.apiService.currentPredictionByDept
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((data: Card[]) => {
            this.data = data;
            this.cardCount = data.length;
          });

        this.getPredictionByDept = this.apiService.getPredictionByDept(
          this.dataService.plantCode,
          crew,
          startDate,
          endDate
        );
        if (startDate !== endDate) {
          this.levelService
            .getPredictionDataByDept(
              this.dataService.plantCode,
              crew,
              startDate,
              endDate
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: []) => {
              this.chartData = data;
            });
        }
      }
    } else if (this.router.url.includes('/level/crew')) {
      if (startDate !== null && endDate !== null) {
        this.pageTitle = 'Crew';
        this.apiService.currentPredictionByCrew
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((data: Card[]) => {
            this.data = data;
            this.cardCount = data.length;
          });
        this.getPredictionByCrew = this.apiService.getPredictionByCrew(
          this.dataService.plantCode,
          startDate,
          endDate
        );
        if (startDate !== endDate) {
          this.levelService
            .getPredictionDataByCrew(
              this.dataService.plantCode,
              startDate,
              endDate
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: []) => {
              this.chartData = data;
            });
        }
      }
    }
  }

  getDate(range) {
    if (range.startDate && range.endDate) {
      this.startDate = moment(range.startDate).format('YYYY-MM-DD');
      this.endDate = moment(range.endDate).format('YYYY-MM-DD');
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { startDate: this.startDate, endDate: this.endDate },
        queryParamsHandling: 'merge'
      });
      this.dataService.startDate = this.startDate;
      this.dataService.endDate = this.endDate;
    }
  }

  crewChange(val) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { crew: val },
      queryParamsHandling: 'merge'
    });
    this.crew = val;
    this.dataService.crew = val;
  }

  productionGroupChange(val) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { productionGroup: val },
      queryParamsHandling: 'merge'
    });
    this.productionGroup = val;
    this.dataService.productionGroup = val;
  }

  onCardData(data) {
    if (this.router.url.includes('/level/crew')) {
      this.router.navigate(['/level/production-group'], {
        queryParams: {
          crew: data.cardName,
          startDate: data.startDate,
          endDate: data.endDate
        },
        queryParamsHandling: 'merge'
      });
      this.crew = data.cardName;
      this.dataService.crew = data.cardName;
    }
    if (this.router.url.includes('/level/production-group')) {
      this.router.navigate(['/level/production-line'], {
        queryParams: {
          crew: data.crew,
          productionGroup: data.cardName,
          startDate: data.startDate,
          endDate: data.endDate
        },
        queryParamsHandling: 'merge'
      });
      this.productionGroup = data.cardName;
      this.dataService.productionGroup = data.cardName;
    }
    this.dataService.startDate = data.startDate;
    this.dataService.endDate = data.endDate;
  }

  onFeedbackData(data) {
    data.userId = this.dataService.userId;
    data.created_by = this.dataService.givenName;
    data.plantCode = this.dataService.plantCode;
    if (this.router.url.includes('/level/crew')) {
      data.crew = data.cardName;
    }
    if (this.router.url.includes('/level/production-group')) {
      data.productionGroup = data.cardName;
    }
    if (this.router.url.includes('/level/production-line')) {
      data.productionGroup = this.productionGroup;
      data.productionLine = data.cardName;
    }
    this.dialog.open(FeedbackComponent, { width: '500px', data });
  }

  onDialogData(data) {
    data.userId = this.dataService.userId;
    data.created_by = this.dataService.givenName;
    data.plantCode = this.dataService.plantCode;
    if (this.router.url.includes('/level/crew')) {
      data.crew = data.cardName;
    }
    if (this.router.url.includes('/level/production-group')) {
      data.productionGroup = data.cardName;
    }
    if (this.router.url.includes('/level/production-line')) {
      data.productionGroup = this.productionGroup;
      data.productionLine = data.cardName;
    }
    this.dialog.open(DialogComponent, { width: '1000px', minHeight: '300px', data });
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
