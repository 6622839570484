import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';

import { formatDate } from '@angular/common';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataService } from 'src/app/services/data.service';
import { Notification } from '../../layout/notification/notification.interface';
import { NotificationsService } from 'src/app/services/notifications.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  pageTitle: string;
  selectedNotificationType: string;

  notificationTextFormControl = new FormControl('', [Validators.required]);

  matcher = new MyErrorStateMatcher();
  placeholder: string;
  minDate: Date;
  maxDate: Date;
  expiryDate: FormControl;

  constructor(
    private router: Router,
    public dataService: DataService,
    private notificationsService: NotificationsService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.placeholder = 'Valid Till';
    this.pageTitle = 'Notifications';
    this.selectedNotificationType = 'message';
    this.dataService._activeNotificationCount.subscribe((v) => {
      this.loadNotifications();
    });
    this.loadNotifications();
    this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
    this.maxDate = new Date(new Date().setDate(new Date().getDate() + 365));

    this.expiryDate = new FormControl(
      new Date(new Date().setDate(new Date().getDate() + 30))
    );
  }

  loadNotifications() {
    this.dataService.notifications = [];
    if (
      this.router.url.includes('/notifications') &&
      this.dataService.activeNotificationCount > 0
    ) {
      this.notificationsService
        .getAllNotificationsByPlant(
          this.dataService.plantCode,
          this.dataService.activeNotificationCount +
            this.dataService.maxDisabledNotificationToShow
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: Notification[]) => {
          this.dataService.notifications = data;
        });
    }
  }

  onNotificationTypeChange(val) {
    console.log(val);
    this.selectedNotificationType = val;
  }

  createNotification(notification) {
    if (this.notificationTextFormControl.value !== this.dataService.userId) {
      notification.plant_code = this.dataService.plantCode;
      notification.notification_text = this.notificationTextFormControl.value;
      notification.notification_type = this.selectedNotificationType;
      notification.expires = formatDate(
        this.expiryDate.value,
        'yyyyMMddHm',
        'en-US'
      );
      notification.user_id = this.dataService.userId;
      this.notificationsService.create(notification).subscribe((data: any) => {
        this.openSnackBar(`Notification created successfully`, 'Ok');
        this.notificationsService.updateNotifications();
      });
    }
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }

  getDate(date) {
    this.expiryDate = new FormControl(date);
  }
}
