<h1 mat-dialog-title>Actuals at granular level</h1>
<div mat-dialog-actions
     align="end"
     class="close-dialog-div">
  <button (click)="close()"
          class="close-dialog"
          mat-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-form-field class="select-option">
  <mat-select (selectionChange)="onOptionChange()"
              [(ngModel)]="selectedOptions">
    <mat-option *ngFor="let option of selectOptions"
                [value]="option.value">
      {{option.viewValue}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div mat-dialog-content
     *ngIf="!loading">
  <ng-container *ngFor="let data of dataSource">

    <div class='table-container'>
      <p>{{data.header}}</p>

      <table mat-table
             [dataSource]="data"
             matSort
             class="mat-elevation-z8">

        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header> {{headerName}} </th>
          <td mat-cell
              *matCellDef="let data"> {{data.name}} </td>
          <td mat-footer-cell
              *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="abs_code">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header> Abs code </th>
          <td mat-cell
              *matCellDef="let data"> {{data.abs_code}} </td>
          <td mat-footer-cell
              *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="count">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header> {{data.tableHeader}}</th>
          <td mat-cell
              *matCellDef="let data"> {{data.count}} </td>
          <td mat-footer-cell
              *matFooterCellDef> Total : {{data.count_total}}</td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row
            class="sticky-footer"
            *matFooterRowDef="['name', 'abs_code', 'count']"></tr>
      </table>
    </div>
  </ng-container>
  <p *ngIf="dataSource.length === 0">No data for selected option.</p>

</div>