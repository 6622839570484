<mat-accordion>
  <mat-expansion-panel *ngFor="let department of departmentData">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ department.dept }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="row" fxLayoutAlign="stretch">
      <app-date-summary
        fxFlex="25"
        [displayedColumns]="prodLines"
        [action_code]="WEEK_STD"
        [data]="department.deptData[0].workdateData"
        [userId]="userId"
        [plantCode]="plantCode"
      ></app-date-summary>
      <app-date-summary
        *ngFor="let dept of department.deptData"
        fxFlex
        [displayedColumns]="tableData"
        [action_code]="TPT_SCHD"
        [data]="dept.workdateData"
        [userId]="userId"
      ></app-date-summary>
    </div>
  </mat-expansion-panel>
</mat-accordion>
