<footer>
  &copy; {{ currentYear }} Stellantis NV,
  <span>{{ env }} </span>
  <span
    ><a
      href="https://github.intra.fcagroup.com/FCA/ui-absenteeism/releases/"
      target="_blank"
      >v{{ version }}</a
    ></span
  >
  <span *ngIf="lastDataLoadedOn"> Data loaded on: {{ lastDataLoadedOn }},</span>
  <span *ngIf="predictedDataUpTo">
    Predictions up to: {{ predictedDataUpTo }},</span
  >
  <span *ngIf="actualsLastDataUpdated">
    Actuals up to: {{ actualsLastDataUpdated }}</span
  >
  <mat-slide-toggle (change)="toggleDarkTheme($event.checked)"
    >Night mode</mat-slide-toggle
  >
</footer>
