import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from 'microsoft-adal-angular6';

import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { ExecutiveLayoutComponent } from './components/layout/executive-layout/executive-layout.component';

import { CONTENT_ROUTES } from './routes/content-layout.routes';
import { EXECUTIVE_ROUTES } from './routes/executive-layout.routes';

import { NotFoundComponent } from './components/views/not-found/not-found.component';
import { UnauthorizedComponent } from './components/views/unauthorized/unauthorized.component';

export const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: CONTENT_ROUTES,
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'executive',
    component: ExecutiveLayoutComponent,
    children: EXECUTIVE_ROUTES,
    canActivate: [AuthenticationGuard]
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
