import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter<boolean>();

  plantCode: string;
  endDate: string;
  config = [];

  constructor(private router: Router, public dataService: DataService) { }

  ngOnInit() {
    this.config = [
      {
        levelName: 'Crew',
        routerLink: ['/level/crew']
      },
      {
        levelName: 'Department',
        routerLink: ['/level/production-group']
      },
      {
        levelName: 'Production Line',
        routerLink: ['/level/production-line']
      }
    ];
  }

  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    return baseUrl == url;
  }

  navToggle() {
    this.sidenavToggle.emit(true);
  }
}
