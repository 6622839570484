<section
  fxLayout="column wrap"
  fxLayoutGap="20px"
  fxLayoutAlign="center center"
>
  <div fxFlex>
    <h1>Unauthorized</h1>
  </div>
  <div fxFlex>
    <p>It seems you are not authorized to access this application.</p>
    <p>
      If you have access to this application and seeing this page as an error
      then please try to reauthorize.
    </p>
  </div>
  <button (click)="reauthorize()" mat-raised-button>
    Reauthorize
  </button>
</section>
