import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Notification } from '../components/layout/notification/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public userId: string;
  public userRole: string;
  public givenName: string;
  public plantName: string;
  public plantCode: string;
  public plantAbbr: string;
  public plannerWorksheetEntryLevel: string;
  public autoPopulatedFieldEntryApplicable: string;
  public startDate: string;
  public endDate: string;
  public minDate: any;
  public maxDate: any;
  public crew: string;
  public comment: string;
  public productionGroup: string;
  public crewArr: string[];
  public productionGroupArr: string[];
  public allPlantsConfig: object[];
  public usersByPlant: object[];
  public holidayScheduledDates: object[];
  public sundayScheduledDates: object[];
  public absenceCodes: object[];
  public shiftTimings: object[];
  public productionTeams: object[];
  public plantConfigDownload: object[];
  public notificationsTop3: Notification[];
  public weatherNotificationsTop1: Notification[];
  public notifications: Notification[];
  public weatherNotifications: Notification[];
  public activeNotificationCount: number;
  public activeWeatherNotificationCount: number;
  public maxDisabledNotificationToShow = 25;
  public unreadNotificationCount: number;
  public unreadWeatherNotificationCount: number;

  public _plantCode = new Subject<string>();
  public _userRole = new Subject<string>();
  public _activeNotificationCount = new Subject<number>();
  public _activeWeatherNotificationCount = new Subject<number>();

  constructor() { }
}
