<div class="inner-container"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-between">
  <h1 class="mat-h1">{{ pageTitle }}</h1>
  <div fxLayout="row"
       fxLayout.xs="column"
       fxLayoutAlign="space-around"
       fxLayoutGap="20px"
       fxLayoutGap.xs="5px">
    <mat-form-field class="filterField"
                    *ngIf="crew">
      <mat-label>Crew</mat-label>
      <mat-select [(ngModel)]="crew"
                  (selectionChange)="crewChange($event.value)">
        <mat-option [value]="crew"
                    *ngFor="let crew of dataService.crewArr">{{
          crew
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filterField"
                    *ngIf="productionGroup">
      <mat-label>Department</mat-label>
      <mat-select [(ngModel)]="productionGroup"
                  (selectionChange)="productionGroupChange($event.value)">
        <mat-option [value]="productionGroup"
                    *ngFor="let productionGroup of dataService.productionGroupArr">{{ productionGroup }}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-date-range-picker class="filterField"
                           [minDate]="minDate"
                           [maxDate]="maxDate"
                           [selected]="selected"
                           (getDate)="getDate($event)">
    </app-date-range-picker>
  </div>
</div>

<div class="card-container"
     fxLayout="row wrap"
     fxLayout.xs="column"
     fxLayoutAlign="space-around"
     fxLayoutGap="10px"
     fxLayoutGap.xs="5px">
  <app-card fxFlex="noshrink"
            class="card"
            [chartData]="chartData"
            [data]="d"
            [cardCount]="cardCount"
            *ngFor="let d of data"
            (cardData)="onCardData($event)"
            (feedbackData)="onFeedbackData($event)"
            (dialogData)="onDialogData($event)"></app-card>
</div>
<div fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="center center"
     fxLayoutGap="20px"
     fxLayoutGap.xs="5px">
  <mat-card fxFlex
            fxLayoutAlign="center center"
            *ngIf="cardCount === 0">
    <span>Holiday/Shutdown</span>
  </mat-card>
</div>