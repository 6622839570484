<div
  class="inner-container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="space-between"
>
  <h1 class="mat-h1">{{ pageTitle }}</h1>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-around"
    fxLayoutGap="20px"
    fxLayoutGap.xs="5px"
  >
    <mat-form-field class="filterField" *ngIf="chartBy === 'department'">
      <mat-label>Crew</mat-label>
      <mat-select
        [(ngModel)]="crew"
        (selectionChange)="crewChange($event.value)"
      >
        <mat-option [value]="crew" *ngFor="let crew of dataService.crewArr">{{
          crew
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filterField">
      <mat-label>By</mat-label>
      <mat-select
        [(ngModel)]="chartBy"
        (selectionChange)="chartByChange($event.value)"
      >
        <mat-option value="crew">Crew</mat-option>
        <mat-option value="department">Department</mat-option>
      </mat-select>
    </mat-form-field>
    <app-date-range-picker
      class="filterField"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [selected]="selected"
      (getDate)="getDate($event)"
    >
    </app-date-range-picker>
  </div>
</div>

<div
  class="card-container"
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="space-around"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
>
  <app-chart-card
    *ngFor="let d of chartData"
    class="card"
    fxFlex="noshrink"
    [title]="d.chartTitle"
    [datasets]="d.data"
    [labels]="d.chartLabels"
    [options]="chartOptions"
    [colors]="chartColors"
    [legend]="chartLegend"
    [chartType]="chartType"
    [plugins]="chartPlugins"
    [cardCount]="cardCount"
    fxFlex="49"
    fxFlex.md="100"
  ></app-chart-card>
</div>
