import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

import { UserAccessService } from 'src/app/services/user-access.service';
import { PlantConfigService } from 'src/app/services/plant-config.service';
import { DataService } from 'src/app/services/data.service';
import { HrDailyReportService } from 'src/app/services/hr-daily-report.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Notification } from '../notification/notification.interface';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  theme = 'fca-app-theme';
  crewArr: string[];
  productionGroupArr: string[];
  private subscription1: Subscription;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private userAccessService: UserAccessService,
    private plantConfigService: PlantConfigService,
    public dataService: DataService,
    private hrDailyReportService: HrDailyReportService,
    private _snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private adalSvc: MsAdalAngular6Service,
    private datePipe: DatePipe,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (environment.production) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.dataService.plantName) {
            this.document.title =
              'Absentee Planning - ' + this.dataService.plantName;
          }
          if (
            this.dataService.userRole === 'Plant Admin' ||
            this.dataService.userRole === 'User'
          ) {
            (window as any).ga('create', 'UA-154267150-1', 'auto');
            (window as any).ga('set', 'page', event.urlAfterRedirects);
            (window as any).ga('send', 'pageview');
            (window as any).ga(
              'send',
              'event',
              'userEmail',
              String(adalSvc.userInfo.userName)
            );
            (window as any).ga(
              'send',
              'event',
              'plantCode',
              String(this.dataService.plantCode),
              String(this.dataService.plantName)
            );
          }
        }
      });
    }
  }

  ngOnInit() {
    console.log(this.adalSvc.userInfo);
    //this.dataService.givenName = this.adalSvc.userInfo.profile.given_name;
    //this.dataService.userId = this.adalSvc.userInfo.userName;
    console.log(JSON.stringify(this.adalSvc.userInfo));
    this.dataService.givenName = this.adalSvc.userInfo.profile.unique_name;
    let userName = this.adalSvc.userInfo.userName

    if (userName.indexOf("@") >= 0)
      userName = userName.substring(0, userName.indexOf("@"));
    if (userName.length == 0)
      userName = this.dataService.givenName
    if (userName.indexOf("@") >= 0)
      userName = userName.substring(0, userName.indexOf("@"));
    console.log('userName :' + userName)
    this.dataService.givenName = userName;
    this.dataService.userId = userName;
    this.userAccessService.getManagementUserRole(this.dataService.userId);
    this.userAccessService.currentManagementUserRole
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data.length === 0) {
          this.userAccessService.getPlantsAssignedToUser(
            this.dataService.userId
          );
        } else {
          this.dataService.userRole = data[0].user_role;
          this.dataService._userRole.next(this.dataService.userRole);
          if (data[0].user_role === 'Super User') {
            this.userAccessService.getAllPlantsForManagementUsers();
          }
        }
      });

    this.userAccessService.currentPlantUserRole
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data[0] !== undefined) {
          this.dataService.userRole = data[0].user_role;
          this.dataService._userRole.next(this.dataService.userRole);
          if (
            data[0].user_role === 'Super User' ||
            data[0].user_role === 'Plant Admin'
          ) {
            this.userAccessService.getUsersByPlant(this.dataService.plantCode);
            this.plantConfigService.getHolidayScheduledDates(
              this.dataService.plantCode,
              this.datePipe.transform(
                new Date(new Date().getFullYear(), 0, 1),
                'yyyy-MM-dd'
              ),
              this.datePipe.transform(
                new Date(new Date().getFullYear(), 11, 31),
                'yyyy-MM-dd'
              ),
              JSON.stringify(['Plant Shutdown', 'Corporate Holiday'])
            );
            this.plantConfigService.getSundayScheduledDates(
              this.dataService.plantCode,
              this.dataService.comment
            );
            this.plantConfigService.getAbsenceCodes(this.dataService.plantCode);
            this.plantConfigService.getShiftTimings(this.dataService.plantCode);
            this.plantConfigService.getProductionTeams(
              this.dataService.plantCode
            );
            this.plantConfigService.getProductionTeamsForDownload(
              this.dataService.plantCode
            );
          } else {
            if (this.router.url.includes('/user-access')) {
              this.router.navigate(['dashboard']);
            }
          }
        }
      });

    this.userAccessService.currentPlantsDataAssignedToUser
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data.length === 0) {
          this.router.navigate(['unauthorized']);
        } else {
          if (data.length > 1) {
            this.dataService.allPlantsConfig = data;
          }
          if (sessionStorage.getItem('plantCode') === null) {
            if (data[0].plant_code) {
              sessionStorage.setItem('plantCode', data[0].plant_code);
              this.dataService.plantCode = data[0].plant_code;
            }

            if (data[0].plant_abbr) {
              sessionStorage.setItem('plantAbbr', data[0].plant_abbr);
              this.dataService.plantAbbr = data[0].plant_abbr;
            }

            if (data[0].plant_name) {
              sessionStorage.setItem('plantName', data[0].plant_name);
              this.dataService.plantName = data[0].plant_name;
            }
            if (data[0].planner_worksheet_entry_level) {
              sessionStorage.setItem(
                'plannerWorksheetEntryLevel',
                data[0].planner_worksheet_entry_level
              );
              this.dataService.plannerWorksheetEntryLevel =
                data[0].planner_worksheet_entry_level;
            }

            if (data[0].auto_populated_field_entry_applicable) {
              sessionStorage.setItem(
                'autoPopulatedFieldEntryApplicable',
                data[0].auto_populated_field_entry_applicable
              );
              this.dataService.autoPopulatedFieldEntryApplicable =
                data[0].auto_populated_field_entry_applicable;
            }
          } else {
            this.dataService.plantCode = sessionStorage.getItem('plantCode');
            this.dataService.plantAbbr = sessionStorage.getItem('plantAbbr');
            this.dataService.plantName = sessionStorage.getItem('plantName');
            this.dataService.plannerWorksheetEntryLevel = sessionStorage.getItem(
              'plannerWorksheetEntryLevel'
            );
            this.dataService.autoPopulatedFieldEntryApplicable = sessionStorage.getItem(
              'autoPopulatedFieldEntryApplicable'
            );
          }

          if (this.dataService.plantCode !== undefined) {
            this.dataService._plantCode.next(this.dataService.plantCode);
            this.apiService.getFilterConfigForPlant(this.dataService.plantCode);
            if (
              this.dataService.userRole === 'Super User' ||
              this.dataService.userRole === 'Executive'
            ) {
              this.userAccessService.getUsersByPlant(
                this.dataService.plantCode
              );
              this.plantConfigService.getHolidayScheduledDates(
                this.dataService.plantCode,
                this.datePipe.transform(
                  new Date(new Date().getFullYear(), 0, 1),
                  'yyyy-MM-dd'
                ),
                this.datePipe.transform(
                  new Date(new Date().getFullYear(), 11, 31),
                  'yyyy-MM-dd'
                ),
                JSON.stringify(['Plant Shutdown', 'Corporate Holiday'])
              );
              this.plantConfigService.getSundayScheduledDates(
                this.dataService.plantCode,
                this.dataService.comment
              );
              this.plantConfigService.getAbsenceCodes(
                this.dataService.plantCode
              );
              this.plantConfigService.getShiftTimings(
                this.dataService.plantCode
              );
              this.plantConfigService.getProductionTeams(
                this.dataService.plantCode
              );
              this.plantConfigService.getProductionTeamsForDownload(
                this.dataService.plantCode
              );
            } else {
              this.userAccessService.getPlantUserRole(
                this.dataService.plantCode,
                this.dataService.userId
              );
            }
          }
          this.notificationsService
            .getActiveNotificationCountByPlant(this.dataService.plantCode)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: Notification[]) => {
              this.dataService.activeNotificationCount = data[0][''];

              this.notificationsService
                .getAllNotificationsByPlant(this.dataService.plantCode, 3)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((data: Notification[]) => {
                  this.dataService.notificationsTop3 = data;
                });
            });

          if (
            localStorage.getItem(
              'readNotificationTimeStamp' + this.dataService.plantCode
            ) === null
          ) {
            localStorage.setItem(
              'readNotificationTimeStamp' + this.dataService.plantCode,
              JSON.stringify(0)
            );
          }
          this.dataService.unreadNotificationCount = this.calculateUnreadNotificationCount(
            JSON.parse(
              localStorage.getItem(
                'readNotificationTimeStamp' + this.dataService.plantCode
              )
            )
          );
          console.log(
            'this.dataService.unreadNotificationCount :: ' +
            this.dataService.unreadNotificationCount
          );

          /**/

          this.notificationsService
            .getActiveWeatherNotificationCountByPlant(
              this.dataService.plantCode
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: Notification[]) => {
              this.dataService.activeWeatherNotificationCount = data[0][''];

              this.notificationsService
                .getAllWeatherNotificationsByPlant(
                  this.dataService.plantCode,
                  1
                )
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((data: Notification[]) => {
                  this.dataService.weatherNotificationsTop1 = data;
                });
            });

          if (
            localStorage.getItem(
              'readWeatherNotificationTimeStamp' + this.dataService.plantCode
            ) === null
          ) {
            localStorage.setItem(
              'readWeatherNotificationTimeStamp' + this.dataService.plantCode,
              JSON.stringify(0)
            );
          }
          this.dataService.unreadWeatherNotificationCount = this.calculateUnreadWeatherNotificationCount(
            JSON.parse(
              localStorage.getItem(
                'readWeatherNotificationTimeStamp' + this.dataService.plantCode
              )
            )
          );
          console.log(
            'this.dataService.unreadWeatherNotificationCount :: ' +
            this.dataService.unreadWeatherNotificationCount
          );
        }
      });

    this.hrDailyReportService.getHrDailyDefaultDates()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data[0].min_date) {
          this.dataService.minDate = data[0].min_date;
        }
        if (data[0].max_date) {
          this.dataService.maxDate = data[0].max_date;
        }
      });
  }

  calculateUnreadNotificationCount(readNotificationTimeStamp) {
    let unreadNotificationCount;
    this.notificationsService
      .getUnreadNotificationCountByPlant(
        this.dataService.plantCode,
        readNotificationTimeStamp
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: Notification[]) => {
        this.dataService.unreadNotificationCount = data[0][''];
      });
    unreadNotificationCount = this.dataService.unreadNotificationCount;
    if (unreadNotificationCount < 0) {
      unreadNotificationCount = 0;
    }
    return unreadNotificationCount;
  }

  calculateUnreadWeatherNotificationCount(readWeatherNotificationTimeStamp) {
    let unreadWeatherNotificationCount;
    this.notificationsService
      .getUnreadWeatherNotificationCountByPlant(
        this.dataService.plantCode,
        readWeatherNotificationTimeStamp
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: Notification[]) => {
        this.dataService.unreadWeatherNotificationCount = data[0][''];
      });
    unreadWeatherNotificationCount = this.dataService
      .unreadWeatherNotificationCount;
    if (unreadWeatherNotificationCount < 0) {
      unreadWeatherNotificationCount = 0;
    }
    return unreadWeatherNotificationCount;
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }

  closeSnackBar() {
    this._snackBar.dismiss();
  }

  onThemeChange(theme: boolean) {
    this.theme = theme ? 'fca-dark-theme' : 'fca-app-theme';

    // indigo-theme
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscription1.unsubscribe();
  }
}
