import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';

import { AppMaterialModule } from './material-module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoaderInterceptor } from './loader.interceptor';

import { InsertAuthTokenInterceptor } from './insert-auth-token-interceptor';

// layout
import { LoaderComponent } from './components/layout/loader/loader.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { ExecutiveLayoutComponent } from './components/layout/executive-layout/executive-layout.component';
import { ToolbarComponent } from './components/layout/toolbar/toolbar.component';
import { SidenavComponent } from './components/layout/sidenav/sidenav.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { CardComponent } from './components/layout/card/card.component';
import { FeedbackComponent } from './components/layout/feedback/feedback.component';
import { DialogComponent } from './components/layout/dialog/dialog.component';
import { DepartmentSummaryComponent } from './components/layout/department-summary/department-summary.component';
import { DateSummaryComponent } from './components/layout/date-summary/date-summary.component';
import { DatepickerComponent } from './components/layout/datepicker/datepicker.component';
import { DateRangePickerComponent } from './components/layout/date-range-picker/date-range-picker.component';
import { NotificationsDialogComponent } from './components/layout/notifications-dialog/notifications-dialog.component';
import { WeatherNotificationsDialogComponent } from './components/layout/weather-notifications-dialog/weather-notifications-dialog.component';

import { NotificationComponent } from './components/layout/notification/notification.component';


// views
import { DashboardComponent } from './components/views/dashboard/dashboard.component';
import { NotFoundComponent } from './components/views/not-found/not-found.component';
import { PredictionSummaryComponent } from './components/views/prediction-summary/prediction-summary.component';
import { LevelComponent } from './components/views/level/level.component';
import { UnauthorizedComponent } from './components/views/unauthorized/unauthorized.component';
import { UserAccessComponent } from './components/views/user-access/user-access.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ConfirmDialogComponent } from './components/layout/confirm-dialog/confirm-dialog.component';

import {
  MsAdalAngular6Module,
  AuthenticationGuard
} from 'microsoft-adal-angular6';

import { ChartsModule } from 'ng2-charts';

import { FormatNumberPipe } from './pipes/format-number.pipe';
import { KeysPipe } from './pipes/keys.pipe';

import { environment } from '../environments/environment';
import { MetricsComponent } from './components/views/metrics/metrics.component';
import { ChartCardComponent } from './components/layout/chart-card/chart-card.component';
import { ChartComponent } from './components/layout/chart/chart.component';
import { NotificationsComponent } from './components/views/notifications/notifications.component';
import { WeatherNotificationsComponent } from './components/views/weather-notifications/weather-notifications.component';
import { ExecutiveDashboardComponent } from './components/views/executive-dashboard/executive-dashboard.component';
import { ExecutiveToolbarComponent } from './components/layout/executive-toolbar/executive-toolbar.component';
import { PlannersWorksheetComponent } from './components/views/planners-worksheet/planners-worksheet.component';
import { PlantStartupTrackerComponent } from './components/views/plant-startup-tracker/plant-startup-tracker.component';
import { WorksheetCrewComponent } from './components/layout/worksheet-crew/worksheet-crew.component';
import { WorksheetDeptComponent } from './components/layout/worksheet-dept/worksheet-dept.component';
import { WorksheetLineComponent } from './components/layout/worksheet-line/worksheet-line.component';
import { SuperUserTableComponent } from './components/layout/super-user-table/super-user-table.component';
import { PlantConfigComponent } from './components/views/plant-config/plant-config.component';
import { ConfigHolidayScheduleComponent } from './components/layout/config-holiday-schedule/config-holiday-schedule.component';
import { ConfigSundayScheduleComponent } from './components/layout/config-sunday-schedule/config-sunday-schedule.component';
import { ConfigAbsenceCodesComponent } from './components/layout/config-absence-codes/config-absence-codes.component';
import { ConfigTeamsComponent } from './components/layout/config-teams/config-teams.component';
import { ConfigManageTeamsComponent } from './components/layout/config-manage-teams/config-manage-teams.component';
import { ConfigChangeRequestComponent } from './components/layout/config-change-request/config-change-request.component';
import { ConfigShiftTimingsComponent } from './components/layout/config-shift-timings/config-shift-timings.component';
import { AbsenceOverTimeComponent } from './components/views/absence-over-time/absence-over-time.component';
import { TableComponent } from './components/layout/table/table.component';
import { HrDailyReportComponent } from './components/views/hr-daily-report/hr-daily-report.component';
import { HrDailyReportTrendsComponent } from './components/views/hr-daily-report-trends/hr-daily-report-trends.component';

@NgModule({
  declarations: [
    AppComponent,

    ContentLayoutComponent,
    ExecutiveLayoutComponent,
    ToolbarComponent,
    SidenavComponent,
    FooterComponent,
    DepartmentSummaryComponent,
    DateSummaryComponent,

    DashboardComponent,
    NotFoundComponent,
    CardComponent,
    DatepickerComponent,
    DateRangePickerComponent,
    PredictionSummaryComponent,
    LevelComponent,
    UnauthorizedComponent,
    UserAccessComponent,
    FeedbackComponent,
    DialogComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    MetricsComponent,
    ChartCardComponent,
    ChartComponent,
    NotificationsComponent,
    WeatherNotificationsComponent,
    NotificationsDialogComponent,
    WeatherNotificationsDialogComponent,
    NotificationComponent,
    ExecutiveDashboardComponent,
    ExecutiveToolbarComponent,
    PlannersWorksheetComponent,
    WorksheetCrewComponent,
    WorksheetDeptComponent,
    WorksheetLineComponent,
    PlantStartupTrackerComponent,
    SuperUserTableComponent,
    PlantConfigComponent,
    ConfigHolidayScheduleComponent,
    ConfigSundayScheduleComponent,
    ConfigAbsenceCodesComponent,
    ConfigShiftTimingsComponent,
    ConfigTeamsComponent,
    ConfigManageTeamsComponent,
    ConfigChangeRequestComponent,
    AbsenceOverTimeComponent,
    TableComponent,
    FormatNumberPipe,
    KeysPipe,
    HrDailyReportComponent,
    HrDailyReportTrendsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppMaterialModule,
    FlexLayoutModule,
    NgxDaterangepickerMd.forRoot(),
    MsAdalAngular6Module.forRoot({
      /*tenant: 'c1aee0b1-83f4-4518-a226-e7508aec4c2d',
      clientId: '71a784d9-b4a0-4546-89f2-28e2a3a23621',
      redirectUri: environment.redirectUri,*/
      instance: environment.instance,
      tenant: 'adfs',
      clientId: environment.clientId,
       redirectUri: environment.redirectUri,
      endpoints: environment.endpoints,
      navigateToLoginRequestUrl: false,
      cacheLocation: 'localStorage'
    }),
    ChartsModule
  ],
  providers: [
    AuthenticationGuard,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 50000 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InsertAuthTokenInterceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents: [
    FeedbackComponent,
    ConfigChangeRequestComponent,
    DialogComponent,
    ConfirmDialogComponent,
    NotificationsDialogComponent,
    WeatherNotificationsDialogComponent
  ]
})
export class AppModule {}
