import {
  Component,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-config-absence-codes',
  templateUrl: './config-absence-codes.component.html',
  styleUrls: ['./config-absence-codes.component.scss']
})
export class ConfigAbsenceCodesComponent implements OnChanges, OnInit {
  @Input() absenceCodes;

  absenceCodesData = [];

  constructor(
    public dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'absenceCodes': {
            this.loadAbsenceCodes(changes[propName].currentValue);
          }
        }
      }
    }
  }

  ngOnInit() { }

  loadAbsenceCodes(data) {
    this.absenceCodesData = data;
    this.changeDetectorRef.detectChanges();
  }
}
