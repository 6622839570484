import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Inject,
  OnChanges,
  HostListener
} from '@angular/core';

import { DOCUMENT } from '@angular/common';

import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import { TableUtil } from '../../../utils/tableUtil';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss']
})
export class ChartCardComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('card', { static: true }) card: ElementRef;

  @Input() title: string;
  @Input() width: number;
  @Input() height: number;
  @Input() datasets: ChartDataSets[];
  @Input() labels: Label[];
  @Input() options: ChartOptions;
  @Input() colors: Color[];
  @Input() legend: boolean;
  @Input() chartType: ChartType;
  @Input() plugins: [];
  @Input() cardCount: number;
  @Input() isToggleVisible: boolean;
  @Input() jsonDataForExport: [];

  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

  screenWidth: any;

  @Input() chartWidth: number;
  @Input() chartHeight: number;
  tempWidth: number;
  tempHeight: number;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnChanges() {
    this.getScreenWidth();
  }

  ngOnInit() {
    this.document.addEventListener('fullscreenchange', (event) => {
      if (!this.document.fullscreenElement) {
        this.chartWidth = this.tempWidth;
        this.chartHeight = this.tempHeight;
      }
    });
  }

  ngAfterViewInit() {
    if (!this.chartWidth) {
      this.setCardSize();
    } else {
      this.tempWidth = this.chartWidth;
      this.tempHeight = this.chartHeight;
    }
  }

  setCardSize() {
    timer(1, 10)
      .pipe(take(1))
      .subscribe((x) => {
        if (this.cardCount === 1) {
          this.chartWidth = this.screenWidth - 110;
        } else if (this.cardCount === 2 && this.screenWidth > 700) {
          this.chartWidth = this.screenWidth / 2 - 110;
        } else {
          this.chartWidth = this.chartContainer.nativeElement.offsetWidth;
        }
        // this.chartHeight = 280;
        this.tempWidth = this.chartWidth;
        this.tempHeight = this.chartHeight;
      });
  }

  changeChartType(checked: boolean) {
    if (checked) {
      this.chartType = 'bar';
    } else {
      this.chartType = 'line';
    }
  }

  expand() {
    this.chartWidth = this.screenWidth - 110;
    this.chartHeight = 700;
    if (this.card.nativeElement) {
      const cardEl = this.card.nativeElement;
      const invokeFullScreen =
        cardEl.requestFullscreen ||
        cardEl.webkitRequestFullScreen ||
        cardEl.mozRequestFullscreen ||
        cardEl.msRequestFullscreen;
      if (invokeFullScreen) {
        invokeFullScreen.call(cardEl);
      }
    }
  }

  export() {
    if (this.jsonDataForExport && this.jsonDataForExport.length > 0) {
      TableUtil.exportArrayToExcel(this.jsonDataForExport, this.title);
    }
  }
}
