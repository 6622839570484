import { Routes } from '@angular/router';
import { DashboardComponent } from '../components/views/dashboard/dashboard.component';
import { NotificationsComponent } from '../components/views/notifications/notifications.component';
import { WeatherNotificationsComponent } from '../components/views/weather-notifications/weather-notifications.component';
import { PredictionSummaryComponent } from '../components/views/prediction-summary/prediction-summary.component';
import { LevelComponent } from '../components/views/level/level.component';
import { UserAccessComponent } from '../components/views/user-access/user-access.component';
import { MetricsComponent } from '../components/views/metrics/metrics.component';
import { PlannersWorksheetComponent } from '../components/views/planners-worksheet/planners-worksheet.component';
import { PlantStartupTrackerComponent } from '../components/views/plant-startup-tracker/plant-startup-tracker.component';
import { PlantConfigComponent } from '../components/views/plant-config/plant-config.component';
import { AbsenceOverTimeComponent } from '../components/views/absence-over-time/absence-over-time.component';
import { HrDailyReportComponent } from '../components/views/hr-daily-report/hr-daily-report.component'
import { HrDailyReportTrendsComponent } from '../components/views/hr-daily-report-trends/hr-daily-report-trends.component';

export const CONTENT_ROUTES: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'weather-notifications', component: WeatherNotificationsComponent },
  { path: 'prediction-summary', component: PredictionSummaryComponent },
  { path: 'level/:level', component: LevelComponent },
  { path: 'user-access', component: UserAccessComponent },
  { path: 'metrics', component: MetricsComponent },
  { path: 'planners-worksheet', component: PlannersWorksheetComponent },
  { path: 'plant-startup-summary', component: PlantStartupTrackerComponent },
  { path: 'plant-config', component: PlantConfigComponent },
  { path: 'absence-over-time', component: AbsenceOverTimeComponent },
  { path: 'hr-daily-report', component: HrDailyReportComponent },
  { path: 'hr-daily-report-trends', component: HrDailyReportTrendsComponent }

];
