import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'underscore';
import * as moment from 'moment';

import { ChartOptions, ChartType } from 'chart.js';

import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-absence-over-time',
  templateUrl: './absence-over-time.component.html',
  styleUrls: ['./absence-over-time.component.scss']
})
export class AbsenceOverTimeComponent implements OnInit, OnChanges, OnDestroy {
  ngUnsubscribe: Subject<any> = new Subject<any>();

  pageTitle: string;
  startDate: string;
  endDate: string;
  cardCount = 2;
  crew: string;
  department: string;
  chartBy = 'crew';
  chartData: any[];
  chartType: ChartType = 'line';
  chartOptions: ChartOptions = {
    responsive: true,
    layout: {
      padding: {
        bottom: 25
      }
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Count'
          },
          ticks: {
            min: 0
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  chartColors = [
    {
      backgroundColor: 'rgba(255, 99, 132, 0.1)',
      borderColor: 'rgba(255, 99, 132,1)'
    },
    {
      backgroundColor: 'rgba(62, 149, 205, 0.1)',
      borderColor: 'rgba(62, 149, 205, 1)'
    },
    {
      backgroundColor: 'rgba(255, 206, 86, 0.1)',
      borderColor: 'rgba(255, 206, 86, 1)'
    }
  ];
  chartLegend = true;
  chartPlugins = [];

  minDate: moment.Moment;
  maxDate: moment.Moment;
  selected: any;
  jsonDataForExport = [];

  constructor(
    public dataService: DataService,
    private apiService: ApiService,
    private metricsService: MetricsService,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnChanges() {}

  ngOnInit() {
    this.pageTitle = 'Absence Over Time';

    this.apiService.currentFilterDefaultDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data[0].min_date) {
          this.minDate = moment(data[0].min_date, 'YYYY-MM-DD');
        }
        if (data[0].max_date) {
          this.maxDate = moment(data[0].max_date, 'YYYY-MM-DD');

          let subtractDays;
          if (this.dataService.plantCode === '2452') {
            subtractDays = 5;
          } else {
            subtractDays = 6;
          }

          this.selected = {
            startDate: moment(data[0].max_date, 'YYYY-MM-DD').subtract(
              subtractDays,
              'days'
            ),
            endDate: moment(data[0].max_date, 'YYYY-MM-DD')
          };
          this.startDate = moment(this.selected.startDate).format('YYYY-MM-DD');
          this.endDate = moment(this.selected.endDate).format('YYYY-MM-DD');
        }

        this.crew = this.dataService.crewArr[0];
        this.department = this.dataService.productionGroupArr[0];
        this.loadCharts(this.chartBy);
      });
  }

  loadCharts(chartBy) {
    this.chartData = [];
    if (chartBy === 'crew') {
      this.getabsencecodewklytrendsovertimebycrew(this.startDate, this.endDate);
    } else if (chartBy === 'department') {
      this.getabsencecodewklytrendsovertimebydept(
        this.crew,
        this.startDate,
        this.endDate
      );
    } else {
      this.getabsencecodewklytrendsovertimebyprodline(
        this.crew,
        this.department,
        this.startDate,
        this.endDate
      );
    }
  }

  getDate(range) {
    if (range.startDate && range.endDate) {
      this.startDate = moment(range.startDate).format('YYYY-MM-DD');
      this.endDate = moment(range.endDate).format('YYYY-MM-DD');
      this.dataService.startDate = this.startDate;
      this.dataService.endDate = this.endDate;
      this.loadCharts(this.chartBy);
    }
  }

  chartByChange(val) {
    this.chartBy = val;
    this.loadCharts(this.chartBy);
  }

  crewChange(val) {
    this.crew = val;
    this.loadCharts(this.chartBy);
  }

  departmentChange(val) {
    this.department = val;
    this.loadCharts(this.chartBy);
  }

  getabsencecodewklytrendsovertimebycrew(startDate, endDate) {
    let labels = [];
    this.metricsService
      .getabsencecodewklytrendsovertimebycrew(
        this.dataService.plantCode,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        labels = _.unique(_.pluck(data, 'weekRange'));

        this.chartData = _.chain(data)
          .groupBy('crew')
          .map((value, key) => {
            return {
              chartTitle: key,
              chartLabels: _.unique(_.pluck(value, 'weekRange')),
              data: _.chain(value)
                .groupBy('abs_code')
                .map((val, k) => {
                  return {
                    label: k,
                    fill: false,
                    borderWidth: 1,
                    data: _.pluck(val, 'abs_code_count')
                  };
                })
                .value()
            };
          })
          .value();

        // add 0
        /* this.chartData = _.chain(data)
          .groupBy('crew')
          .map((value, key) => {
            return {
              chartTitle: key,
              chartLabels: _.unique(_.pluck(value, 'weekRange')),
              data: _.chain(value)
                .groupBy('abs_code')
                .map((val: any, k) => {
                  const arr = [];
                  for (let i = 0; i <= labels.length - 1; i++) {
                    arr.push('0');
                    for (let j = 0; j <= val.length - 1; j++) {
                      if (labels[i] === val[j].weekRange) {
                        arr[i] = val[j].abs_code_count;
                      }
                    }
                  }
                  return {
                    label: k,
                    fill: false,
                    borderWidth: 1,
                    data: arr
                  };
                })
                .value()
            };
          })
          .value(); */

        this.jsonDataForExport = _.chain(data)
          .groupBy('crew')
          .map((value, key) => {
            return {
              data: value
            };
          })
          .value();
      });
  }

  getabsencecodewklytrendsovertimebydept(crew, startDate, endDate) {
    this.metricsService
      .getabsencecodewklytrendsovertimebydept(
        this.dataService.plantCode,
        crew,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.chartData = _.chain(data)
          .groupBy('dept_name')
          .map((value, key) => {
            return {
              chartTitle: key,
              chartLabels: _.unique(_.pluck(value, 'weekRange')),
              data: _.chain(value)
                .groupBy('abs_code')
                .map((val, k) => {
                  return {
                    label: k,
                    fill: false,
                    borderWidth: 1,
                    data: _.pluck(val, 'abs_code_count')
                  };
                })
                .value()
            };
          })
          .value();

        this.jsonDataForExport = _.chain(data)
          .groupBy('dept_name')
          .map((value, key) => {
            return {
              data: value
            };
          })
          .value();
      });
  }

  getabsencecodewklytrendsovertimebyprodline(
    crew,
    department,
    startDate,
    endDate
  ) {
    this.metricsService
      .getabsencecodewklytrendsovertimebyprodline(
        this.dataService.plantCode,
        crew,
        department,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.chartData = _.chain(data)
          .groupBy('production_line')
          .map((value, key) => {
            return {
              chartTitle: key,
              chartLabels: _.unique(_.pluck(value, 'weekRange')),
              data: _.chain(value)
                .groupBy('abs_code')
                .map((val, k) => {
                  return {
                    label: k,
                    fill: false,
                    borderWidth: 1,
                    data: _.pluck(val, 'abs_code_count')
                  };
                })
                .value()
            };
          })
          .value();

        this.jsonDataForExport = _.chain(data)
          .groupBy('production_line')
          .map((value, key) => {
            return {
              data: value
            };
          })
          .value();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
