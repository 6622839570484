import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Input,
  Inject,
  Output,
  ViewChild,
  AfterViewInit,
  OnChanges,
  HostListener
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as _ from 'underscore';

import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import { Card } from './card.interface';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements Card, OnInit, AfterViewInit, OnChanges {
  @Input() data: Card;
  @Input() chartData: [];
  @Input() cardCount: number;

  @Output() cardData = new EventEmitter();
  @Output() feedbackData = new EventEmitter();
  @Output() dialogData = new EventEmitter();

  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

  screenWidth: any;
  workDate: string;
  startDate: string;
  endDate: string;
  cardName: string;
  actual: number;
  predicted: number;
  predictedLow: number;
  predictedHigh: number;
  firstHourActuals: number;
  endOfShiftActuals: number;
  features: string;
  isActive: boolean;
  workDateArr = [];
  loading: boolean;

  chartWidth: number;
  chartHeight: number;
  tempWidth: number;
  tempHeight: number;
  chartDataForCard: ChartDataSets[];
  chartLabelsForCard: Label[];

  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    legend: {
      labels: {
        boxWidth: 15,
      }
    }
  };
  // lineChartColors: Color[] = [
  //   {
  //     borderColor: 'black',
  //     backgroundColor: 'rgba(255,0,0,0.3)'
  //   }
  // ];

  lineChartLegend = true;
  lineChartType: ChartType = 'line';
  lineChartPlugins = [];

  constructor(
    private loaderService: LoaderService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  @HostListener('window:resize', ['$event'])
  getScreenWidth(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnChanges() {
    this.getScreenWidth();
    if (this.chartData) {
      this.chartDataForCard = this.chartData.filter(
        (chartItem: any) => chartItem.cardName === this.data.cardName
      );

      this.chartLabelsForCard = this.chartDataForCard.map(
        (chartItem: any) => chartItem.workDate
      );

      const arr1 = [];
      const actual = this.chartDataForCard.reduce((obj, item: any) => {
        arr1.push(item.actual);
        obj = { label: 'PeopleSoft Actual', backgroundColor: 'rgba(0,0,0,0)', borderColor: "#FFCE56", pointBackgroundColor: '#FFE6AA', pointBorderColor: '#FFCE56', data: arr1 };
        return obj;
      }, {});

      const arr2 = [];
      const predicted = this.chartDataForCard.reduce((obj, item: any) => {
        arr2.push(item.predicted);
        obj = { label: 'Predicted', backgroundColor: 'rgba(0,0,0,0)', borderColor: "#FF6384", pointBackgroundColor: '#FF9EB3', pointBorderColor: '#FF6384', data: arr2 };
        return obj;
      }, {});

      const arr3 = [];
      const firstHourActuals = this.chartDataForCard.reduce((obj, item: any) => {
        arr3.push(item.firstHourActuals);
        obj = { label: 'First Hour Actuals', backgroundColor: 'rgba(0,0,0,0)', borderColor: "#4BC0C0", pointBackgroundColor: '#93D9D9', pointBorderColor: '#4BC0C0', data: arr3 };
        return obj;
      }, {});

      const arr4 = [];
      const endOfShiftActuals = this.chartDataForCard.reduce((obj, item: any) => {
        arr4.push(item.endOfShiftActuals);
        obj = { label: 'End Of Shift Actuals', backgroundColor: 'rgba(0,0,0,0)', borderColor: "#F95700FF", pointBackgroundColor: '#FB7934', pointBorderColor: '#F95700FF', data: arr4 };
        return obj;
      }, {});

      this.chartDataForCard = [actual, predicted, firstHourActuals, endOfShiftActuals];
    }
  }

  ngOnInit() {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
    this.chartHeight = 240;
    this.workDate = '';
    this.startDate = '';
    this.endDate = '';
    this.cardName = '';
    this.actual = 0;
    this.predicted = 0;
    this.predictedLow = 0;
    this.predictedHigh = 0;
    this.firstHourActuals = 0;
    this.endOfShiftActuals = 0;
    this.features = '';
    if (this.data) {
      if (this.data.workDate.indexOf(',') > -1) {
        this.workDateArr = this.data.workDate.split(',');
        this.startDate = this.data.startDate = this.workDateArr[0];
        this.endDate = this.data.endDate = this.workDateArr[1];
      } else {
        this.startDate = this.data.startDate = this.data.workDate;
        this.endDate = this.data.endDate = this.data.workDate;
      }
    }

    this.document.addEventListener('fullscreenchange', (event) => {
      if (!this.document.fullscreenElement) {
        this.chartWidth = this.tempWidth;
        this.chartHeight = this.tempHeight;
      }
    });
  }

  ngAfterViewInit() {
    if (this.startDate !== this.endDate) {
      this.setCardSize();
    }
  }

  setCardSize() {
    timer(1, 10)
      .pipe(take(1))
      .subscribe((x) => {
        if (this.cardCount === 1) {
          this.chartWidth = this.screenWidth - 110;
        } else if (this.cardCount === 2 && this.screenWidth > 700) {
          this.chartWidth = this.screenWidth / 2 - 110;
        } else {
          this.chartWidth = this.screenWidth / 3 - 70;
        }
        this.chartHeight = 240;
        this.tempWidth = this.chartWidth;
        this.tempHeight = this.chartHeight;
      });
  }

  expand() {
    event.stopPropagation();
    this.chartWidth = this.screenWidth - 110;
    this.chartHeight = 700;
    if (this.chartContainer.nativeElement) {
      const chartContainerEl = this.chartContainer.nativeElement;
      const invokeFullScreen =
        chartContainerEl.requestFullscreen ||
        chartContainerEl.webkitRequestFullScreen ||
        chartContainerEl.mozRequestFullscreen ||
        chartContainerEl.msRequestFullscreen;
      if (invokeFullScreen) {
        invokeFullScreen.call(chartContainerEl);
      }
    }
  }

  onFeedbackClick(event, data) {
    event.stopPropagation();
    this.feedbackData.emit(data);
  }
  onDialogClick(event, data) {
    event.stopPropagation();
    this.dialogData.emit(data);
  }

  onCardClick(event, data) {
    this.cardData.emit(data);
  }

  mouseEnter(state: boolean) {
    this.isActive = state;
  }

  mouseLeave(state: boolean) {
    this.isActive = state;
  }
}
