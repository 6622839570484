<div
  class="card-container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="space-around"
  fxLayoutGap="10px"
  fxLayoutGap.xs="5px"
>
  <mat-form-field [formGroup]="date">
    <mat-label>Enter optional working date</mat-label>
    <input
      matInput
      formControlName="start"
      [matDatepicker]="picker"
      [min]="minDate"
    />

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div fxFlex="noshrink">
    <mat-form-field>
      <input matInput [(ngModel)]="working_crews" placeholder="Include Crews" />
    </mat-form-field>
  </div>
  <div fxFlex="noshrink">
    <button
      mat-raised-button
      color="primary"
      (click)="saveSundayScheduledDate()"
    >
      Add
    </button>
  </div>
</div>

<table mat-table [dataSource]="tableSource">
  <ng-container matColumnDef="exc_start_date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.exc_start_date !== row.exc_end_date">
        {{ row.exc_start_date | date: 'MM/dd/yyyy' }} -
        {{ row.exc_end_date | date: 'MM/dd/yyyy' }}
      </span>
      <span *ngIf="row.exc_start_date === row.exc_end_date">
        {{ row.exc_start_date | date: 'MM/dd/yyyy' }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="holiday_type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let row">
      {{ row.holiday_type }}
    </td>
  </ng-container>

  <ng-container matColumnDef="working_crews">
    <th mat-header-cell *matHeaderCellDef [style.width.px]="200">
      Include Crews
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-form-field [style.width.px]="200">
        <input
          matInput
          [(ngModel)]="row.working_crews"
          placeholder="Include crews"
        />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="comments">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let row">
      {{ row.comments }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let row">
      <button
        *ngIf="
          checkIfDateIsFutureDate(row.exc_start_date) &&
          row.holiday_type === 'Optional Production'
        "
        mat-icon-button
        matTooltip="Save Record"
        matTooltipPosition="left"
        (click)="updateSundayScheduledDate(row)"
      >
        <mat-icon aria-hidden="false" aria-label="Save Record">save</mat-icon>
      </button>

      <button
        *ngIf="
          checkIfDateIsFutureDate(row.exc_start_date) &&
          row.holiday_type === 'Optional Production'
        "
        mat-icon-button
        matTooltip="Delete Record"
        matTooltipPosition="left"
        (click)="deleteSundayScheduledDate(row)"
      >
        <mat-icon aria-hidden="false" aria-label="Delete Record"
          >delete</mat-icon
        >
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div class="mat-caption">
  *Include Crews: Mention crews who will work on that day in comma separated
  format. For example: A,B<br />
  *Save record after updating 'Including Crews' column.
</div>
