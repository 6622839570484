import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PlantConfigService {
  constructor(private httpClient: HttpClient) { }
  private _holidayScheduledDates = new Subject();
  currentHolidayScheduledDates = this._holidayScheduledDates.asObservable();

  private _sundayScheduledDates = new Subject();
  currentSundayScheduledDates = this._sundayScheduledDates.asObservable();

  private _absenceCodes = new Subject();
  currentAbsenceCodes = this._absenceCodes.asObservable();

  private _shiftTimings = new Subject();
  currentShiftTimings = this._shiftTimings.asObservable();

  private _productionTeams = new Subject();
  currentProductionTeams = this._productionTeams.asObservable();

  private _productionTeamsDownload = new Subject();
  currentProductionTeamsDownload = this._productionTeamsDownload.asObservable();

  getHolidayScheduledDates(plantCode, startDate, endDate, holidayType) {
    this.httpClient
      .get(
        `${API_URL}/plantConfiguration/getHolidayScheduledDates/plant_code=${plantCode}&start_date=${startDate}&end_date=${endDate}&holiday_type=${holidayType}`
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._holidayScheduledDates.next(res);
        },
        (error) => console.log('Could not find holiday scheduled dates.')
      );
  }

  getSundayScheduledDates(plantCode, comments) {
    this.httpClient
      .get(
        `${API_URL}/plantConfiguration/getSundayScheduledDates/plant_code=${plantCode}&comments=${comments}`
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._sundayScheduledDates.next(res);
        },
        (error) => console.log('Could not find sunday scheduled dates.')
      );
  }

  getAbsenceCodes(plantCode) {
    this.httpClient
      .get(
        `${API_URL}/plantConfiguration/getAbsenceCodes/plant_code=${plantCode}`
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._absenceCodes.next(res);
        },
        (error) => console.log('Could not find absence code.')
      );
  }

  getShiftTimings(plantCode) {
    this.httpClient
      .get(
        `${API_URL}/plantConfiguration/getShiftTimings/plant_code=${plantCode}`
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._shiftTimings.next(res);
        },
        (error) => console.log('Could not find shift Timings.')
      );
  }

  isHolidayAlreadyScheduled(plantCode, startDate, endDate) {
    return this.httpClient.get(
      `${API_URL}/plantConfiguration/isHolidayAlreadyScheduled/plant_code=${plantCode}&start_date=${startDate}&end_date=${endDate}`
    );
  }

  isSundayAlreadyScheduled(plantCode, comments, startDate, endDate) {
    return this.httpClient.get(
      `${API_URL}/plantConfiguration/isSundayAlreadyScheduled/plant_code=${plantCode}&comments=${comments}&start_date=${startDate}&end_date=${endDate}`
    );
  }

  saveScheduledDate(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/saveScheduledDate`,
      data
    );
  }

  saveSundayScheduledDate(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/saveSundayScheduledDate`,
      data
    );
  }

  updateScheduledDate(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/updateScheduledDate`,
      data
    );
  }

  updateSundayScheduledDate(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/updateSundayScheduledDate`,
      data
    );
  }

  deleteScheduledDate(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/deleteScheduledDate`,
      data
    );
  }

  deleteSundayScheduledDate(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/deleteSundayScheduledDate`,
      data
    );
  }

  getProductionTeams(plantCode) {
    this.httpClient
      .get(
        `${API_URL}/plantConfiguration/getProductionTeams/plant_code=${plantCode}`
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._productionTeams.next(res);
        },
        (error) => console.log('Could not find production teams.')
      );
  }

  getProductionTeamsForDownload(plantCode) {
    this.httpClient
      .get(
        `${API_URL}/plantConfiguration/getProductionTeamsForDownload/plant_code=${plantCode}`
      )
      .pipe(share())
      .subscribe(
        (res: any) => {
          this._productionTeamsDownload.next(res);
        },
        (error) => console.log('Could not find production teams.')
      );
  }
  

  isTeamAlreadyAddedForDept(plantCode,deptNo, deptName, team) {
    return this.httpClient.get(
      `${API_URL}/plantConfiguration/isTeamAlreadyAddedForDept/plant_code=${plantCode}&dept_number=${deptNo}&dept_name=${deptName}&team=${team}`
    );
  }

  getTeamCountsForProductionLine(plantCode,deptNo, deptName, productionLine) {
    return this.httpClient.get(
      `${API_URL}/plantConfiguration/getTeamCountsForProductionLine/plant_code=${plantCode}&dept_number=${deptNo}&dept_name=${deptName}&production_line=${productionLine}`
    );
  }

  addTeamForProduction(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/addTeamForProduction`,
      data
    );
  }

  deleteTeamForProduction(data) {
    return this.httpClient.post(
      `${API_URL}/plantConfiguration/deleteTeamForProduction`,
      data
    );
  }
}
