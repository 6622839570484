import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataService } from 'src/app/services/data.service';
import { PlantConfigService } from 'src/app/services/plant-config.service';

import { ConfigChangeRequestComponent } from '../../layout/config-change-request/config-change-request.component';
import { DatePipe } from '@angular/common';
import { TableUtil } from '../../../utils/tableUtil';

@Component({
  selector: 'app-plant-config',
  templateUrl: './plant-config.component.html',
  styleUrls: ['./plant-config.component.scss']
})
export class PlantConfigComponent implements AfterViewInit, OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    public dataService: DataService,
    private plantConfigService: PlantConfigService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
   

  ) { }

  ngOnInit() {
    this.plantConfigService.currentHolidayScheduledDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.holidayScheduledDates = data;
        this.dataService.plantAbbr = data[0].plant;
      });

    this.plantConfigService.currentSundayScheduledDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.sundayScheduledDates = data;
        this.dataService.plantAbbr = data[0].plant;
      });

    this.plantConfigService.currentAbsenceCodes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.absenceCodes = data;
      });

    this.plantConfigService.currentShiftTimings
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.shiftTimings = data;
      });

    this.plantConfigService.currentProductionTeams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.productionTeams = data;
      });

      this.plantConfigService.currentProductionTeamsDownload
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.plantConfigDownload = data;
      });
          
  }

  ngAfterViewInit() {
    if (
      !this.dataService.holidayScheduledDates &&
      this.dataService.plantCode !== undefined
    ) {
      this.plantConfigService.getHolidayScheduledDates(
        this.dataService.plantCode,
        this.datePipe.transform(
          new Date(new Date().getFullYear(), 0, 1),
          'yyyy-MM-dd'
        ),
        this.datePipe.transform(
          new Date(new Date().getFullYear(), 11, 31),
          'yyyy-MM-dd'
        ),
        JSON.stringify(['Plant Shutdown', 'Corporate Holiday'])

      );
    }

    if (
      !this.dataService.sundayScheduledDates &&
      this.dataService.plantCode !== undefined
    ) {
      this.plantConfigService.getSundayScheduledDates(
        this.dataService.plantCode,
        this.dataService.comment
      );
    }

    if (
      !this.dataService.absenceCodes &&
      this.dataService.plantCode !== undefined
    ) {
      this.plantConfigService.getAbsenceCodes(this.dataService.plantCode);
    }

    if (
      !this.dataService.shiftTimings &&
      this.dataService.plantCode !== undefined
    ) {
      this.plantConfigService.getShiftTimings(this.dataService.plantCode);
    }

    if (
      !this.dataService.productionTeams &&
      this.dataService.plantCode !== undefined
    ) {
      this.plantConfigService.getProductionTeams(this.dataService.plantCode);
    }

    this.plantConfigService.getProductionTeamsForDownload(this.dataService.plantCode);


    }

  onRequestConfigChangeClick() {
    const data: any = {};
    data.userId = this.dataService.userId;
    data.created_by = this.dataService.givenName;
    data.plantCode = this.dataService.plantCode;
    this.dialog.open(ConfigChangeRequestComponent, {
      width: '600px',
      data
    });
  }

     export() {
      let productionTeamsConfigArr: any ={};
       
      productionTeamsConfigArr = this.dataService.plantConfigDownload;
      if (productionTeamsConfigArr && productionTeamsConfigArr.length > 0) {
        TableUtil.exportArrayToExcel(productionTeamsConfigArr, this.dataService.plantAbbr+'_plant_team_config');
      }
    }

  ngOnDestroy() { }
}
