<mat-sidenav-container [class]="theme" class="layout-container">
  <mat-sidenav #sidenav mode="push">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button
          color="primary"
          class="closeNav"
          mat-flat-button
          (click)="sidenav.close()"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span>Navigation</span>
      </mat-toolbar-row>
    </mat-toolbar>
    <app-sidenav (sidenavToggle)="sidenav.toggle()"></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>
    <div fxLayout="column">
      <app-toolbar (sidenavToggle)="sidenav.toggle()"></app-toolbar>
      <div class="container">
        <router-outlet></router-outlet>
      </div>
      <app-footer (changeTheme)="onThemeChange($event)"></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
