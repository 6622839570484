export class CommonUtil {
  public static checkIfDateIsFutureDate(date) {
    const todaysDate = new Date();
    const currentDate = new Date(date);
    if (todaysDate < currentDate) {
      return true;
    }
    else {
       return false;
    }
  }
}
