import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input() isNextPreviousVisible: boolean;
  @Input() date: FormControl;
  @Input() placeholder = 'Date';
  @Input() minDate: FormControl;
  @Input() maxDate: FormControl;

  @Output() getDate = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  updateDate(event) {
    this.emitDate(event.value);
  }

  emitDate(date) {
    this.getDate.emit(date);
  }

  prevDay() {
    const prevDate = moment(this.date.value).add(-1, 'days');
    this.date = new FormControl(prevDate.toISOString());
    this.emitDate(prevDate);
  }

  nextDay() {
    const nextDate = moment(this.date.value).add(1, 'days');
    this.emitDate(nextDate);
    this.date = new FormControl(nextDate.toISOString());
  }
}
