import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Notification } from 'src/app/components/layout/notification/notification.interface';
import { DataService } from 'src/app/services/data.service';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient,
    public dataService: DataService
  ) {}

  getUnreadNotificationCountByPlant(plant_code, last_read_timestamp) {
    return this.httpClient.get(
      `${API_URL}/notifications/getUnreadNotificationCountByPlant/plant_code=${plant_code}&lastreadtimestamp=${last_read_timestamp}`
    );
  }

  getActiveNotificationCountByPlant(plant_code) {
    return this.httpClient.get(
      `${API_URL}/notifications/getActiveNotificationCountByPlant/plant_code=${plant_code}`
    );
  }

  getAllNotificationsByPlant(plant_code, count) {
    return this.httpClient.get(
      `${API_URL}/notifications/getAllNotificationsByPlant/plant_code=${plant_code}&count=${count}`
    );
  }

  getUnreadWeatherNotificationCountByPlant(plant_code, last_read_timestamp) {
    return this.httpClient.get(
      `${API_URL}/notifications/getUnreadWeatherNotificationCountByPlant/plant_code=${plant_code}&lastreadtimestamp=${last_read_timestamp}`
    );
  }

  getActiveWeatherNotificationCountByPlant(plant_code) {
    return this.httpClient.get(
      `${API_URL}/notifications/getActiveWeatherNotificationCountByPlant/plant_code=${plant_code}`
    );
  }

  getAllWeatherNotificationsByPlant(plant_code, count) {
    return this.httpClient.get(
      `${API_URL}/notifications/getAllWeatherNotificationsByPlant/plant_code=${plant_code}&count=${count}`
    );
  }

  create(data) {
    return this.httpClient.post(`${API_URL}/notifications/create`, data);
  }

  delete(data) {
    return this.httpClient.post(`${API_URL}/notifications/delete`, data);
  }

  updateNotifications() {
    this.getActiveNotificationCountByPlant(this.dataService.plantCode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: Notification[]) => {
        this.dataService.activeNotificationCount = data[0][''];
        this.dataService._activeNotificationCount.next(
          this.dataService.activeNotificationCount
        );
        if (this.dataService.activeNotificationCount > 0) {
          this.getAllNotificationsByPlant(
            this.dataService.plantCode,
            this.dataService.activeNotificationCount +
              this.dataService.maxDisabledNotificationToShow
          )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: Notification[]) => {
              this.dataService.notificationsTop3 = data.slice(0, 3);
              this.dataService.notifications = data;
            });
        }
      });
  }

  updateWeatherNotifications() {
    this.getActiveWeatherNotificationCountByPlant(this.dataService.plantCode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: Notification[]) => {
        this.dataService.activeWeatherNotificationCount = data[0][''];
        this.dataService._activeWeatherNotificationCount.next(
          this.dataService.activeWeatherNotificationCount
        );
        if (this.dataService.activeWeatherNotificationCount > 0) {
          this.getAllWeatherNotificationsByPlant(
            this.dataService.plantCode,
            this.dataService.activeWeatherNotificationCount +
              this.dataService.maxDisabledNotificationToShow
          )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: Notification[]) => {
              this.dataService.weatherNotificationsTop1 = data.slice(0, 1);
              this.dataService.weatherNotifications = data;
            });
        }
      });
  }
}
