export const environment = {
  production: true,
  envName: 'production',
  apiUrl: 'https://azapp-11804-p-002.azurewebsites.net',
  emailList: [
    {
      email: 'fakhare.alam@external.fcagroup.com',
      name: 'Alam Fakhare'
    },
    {
      email: 'anne.dwyer@fcagroup.com',
      name: 'Anne Dwyer'
    },
    {
      email: 'porpaavai.sampath@fcagroup.com',
      name: 'Porpaavai Sampath'
    },
    {
      email: 'Samatha.Madanani@external.fcagroup.com',
      name: 'Samatha Madanani'
    },
    {
      email: 'vikram.kute1@external.fcagroup.com',
      name: 'Vikram Kute'
    },
    {
      email: 'david.corliss@fcagroup.com',
      name: 'David Corliss'
    },
    {
      email: 'venkata.samanthu@external.fcagroup.com',
      name: 'Venkata Samanthu'
    },
    {
      email: 'fakhare.alam@external.stellantis.com',
      name: 'Alam Fakhare'
    },
    {
      email: 'anne.dwyer@stellantis.com',
      name: 'Anne Dwyer'
    },
    {
      email: 'porpaavai.sampath@stellantis.com',
      name: 'Porpaavai Sampath'
    },
    {
      email: 'Samatha.Madanani@external.stellantis.com',
      name: 'Samatha Madanani'
    },
    {
      email: 'vikram.kute1@external.stellantis.com',
      name: 'Vikram Kute'
    },
    {
      email: 'david.corliss@stellantis.com',
      name: 'David Corliss'
    },
    {
      email: 'venkata.samanthu@external.stellantis.com',
      name: 'Venkata Samanthu'
    }
  ],
  redirectUri: window.location.origin,
  instance:'https://fed04.fcagroup.com/',
  clientId :'df1af8fa-efc3-4bbd-8e24-c7a87cf96909',
  endpoints: {
    api: 'https://graph.microsoft.com'
  }
 /* endpoints: {
    'https://azapp-11804-p-002.azurewebsites.net/':
      '71a784d9-b4a0-4546-89f2-28e2a3a23621'
  }*/
};
