import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PlannersWorksheetService {
  constructor(private httpClient: HttpClient) { }

  getPlannerWorksheetByCrew(plantcode, startdate, enddate) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPlannerWorksheetByCrew/plantcode=${plantcode}&startdate=${startdate}&enddate=${enddate}`
    );
  }

  getPlannerWorksheetByDept(plantcode, startdate, enddate) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPlannerWorksheetByDept/plantcode=${plantcode}&startdate=${startdate}&enddate=${enddate}`
    );
  }

  getPlannerWorksheetByProdLine(plantcode, startdate, enddate) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPlannerWorksheetByProdLine/plantcode=${plantcode}&startdate=${startdate}&enddate=${enddate}`
    );
  }

  saveWorksheetEntryForCrew(data) {
    return this.httpClient.post(
      `${API_URL}/plannerWorksheet/saveWorksheetEntryForCrew`,
      data
    );
  }

  saveWorksheetEntryForDept(data) {
    return this.httpClient.post(
      `${API_URL}/plannerWorksheet/saveWorksheetEntryForDept`,
      data
    );
  }

  saveWorksheetEntryForProductionLine(data) {
    return this.httpClient.post(
      `${API_URL}/plannerWorksheet/saveWorksheetEntryForProductionLine`,
      data
    );
  }

  getPlannerWorksheetChartByCrew(plantcode, startDate, endDate) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPlannerWorksheetChartByCrew/plantcode=${plantcode}&startdate=${startDate}&enddate=${endDate}`
    );
  }

  getPlannerWorksheetChartByDepartment(plantcode, crew, startDate, endDate) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPlannerWorksheetChartByDept/plantcode=${plantcode}&crew=${crew}&dept=undefined&startdate=${startDate}&enddate=${endDate}`
    );
  }

  getPlantsTrackerInfo(workDate) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPlantsTrackerInfo/workdate=${workDate}`
    );
  }

  getPlantTrackerWeeklyTrend(plantcode, workDate) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPlantTrackerWeeklyTrend/plantcode=${plantcode}&workdate=${workDate}`
    );
  }

  getPwAutofieldsLastUpdOnTimestamps(plantcode) {
    return this.httpClient.get(
      `${API_URL}/plannerWorksheet/getPwAutofieldsLastUpdOnTimestamps/plantcode=${plantcode}`
    );
  }
}
