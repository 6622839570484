<h1 mat-dialog-title>Notifications</h1>
<div *ngIf="dataService.activeNotificationCount > 0; else noNotifications">
  <mat-dialog-content class="mat-notification-dialog">
    <div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="5px">
      <app-notification
        *ngFor="let notification of dataService.notificationsTop3"
        [notification]="notification"
        (updateNotifications)="notificationsService.updateNotifications($event)"
      ></app-notification>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    align="end"
    *ngIf="dataService.activeNotificationCount > 3"
  >
    <button
      mat-button
      [routerLink]="['/notifications']"
      cdkFocusInitial
      (click)="onNoClick()"
    >
      Show All
    </button>
  </mat-dialog-actions>

  <mat-dialog-actions
    align="end"
    *ngIf="
      dataService.activeNotificationCount <= 3 &&
      dataService.userRole === 'Super User'
    "
  >
    <button
      mat-button
      [routerLink]="['/notifications']"
      cdkFocusInitial
      (click)="onNoClick()"
    >
      Create Notification
    </button>
  </mat-dialog-actions>
</div>
<ng-template #noNotifications>
  Your notifications live here
  <button
    *ngIf="dataService.userRole === 'Super User'"
    mat-button
    [routerLink]="['/notifications']"
    cdkFocusInitial
    (click)="onNoClick()"
  >
    Create Notification
  </button>
</ng-template>
