<div class="card-container"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-around"
     fxLayoutGap="10px"
     fxLayoutGap.xs="5px">
  <mat-form-field>
    <mat-label>Enter plant shutdown date range</mat-label>
    <mat-date-range-input [formGroup]="range"
                          [rangePicker]="picker"
                          [min]="minDate">
      <input matStartDate
             formControlName="start"
             placeholder="Start date" />
      <input matEndDate
             formControlName="end"
             placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix
                           [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <div fxFlex="noshrink">
    <mat-form-field>
      <input matInput
             [(ngModel)]="comment"
             placeholder="Description" />
    </mat-form-field>
  </div>
  <div fxFlex="noshrink">
    <button mat-raised-button
            color="primary"
            (click)="saveScheduledDate()">
      Add Holiday
    </button>
  </div>
</div>

<table mat-table
       [dataSource]="tableSource">
  <ng-container matColumnDef="exc_start_date">
    <th mat-header-cell
        *matHeaderCellDef
        [style.width.px]=200
        [style.paddingRight.px]=100>
      <mat-form-field>
        <mat-label>Date Range/Date</mat-label>
        <mat-date-range-input [formGroup]="holidayRange"
                              [rangePicker]="picker"
                              [min]="holidayMinDate">
          <input matStartDate
                 formControlName="start"
                 placeholder="Start date"
                 #dateRangeStart />
          <input matEndDate
                 formControlName="end"
                 placeholder="End date"
                 #dateRangeEnd
                 (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix
                               [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </th>
    <td mat-cell
        *matCellDef="let row">
      <span *ngIf="row.exc_start_date !== row.exc_end_date">
        {{ row.exc_start_date | date: 'MM/dd/yyyy' }} -
        {{ row.exc_end_date | date: 'MM/dd/yyyy' }}
      </span>
      <span *ngIf="row.exc_start_date === row.exc_end_date">
        {{ row.exc_start_date | date: 'MM/dd/yyyy' }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="holiday_type">
    <th mat-header-cell
        *matHeaderCellDef
        [style.paddingRight.px]=60>Type
      <mat-select [formControl]="holidayTypes"
                  (selectionChange)="filter($event)"
                  multiple
                  [(value)]="selected">
        <mat-option *ngFor="let holiday of holidayTypeList"
                    [value]="holiday">{{holiday}}</mat-option>
      </mat-select>
    </th>
    <td mat-cell
        *matCellDef="let row">
      {{ row.holiday_type }}
    </td>
  </ng-container>

  <ng-container matColumnDef="is_work_day">
    <th mat-header-cell
        *matHeaderCellDef>Working</th>
    <td mat-cell
        *matCellDef="let row">
      <ng-container *ngIf="checkIfDateIsFutureDate(row.exc_start_date) && row.holiday_type === 'Corporate Holiday'">
        <mat-slide-toggle [ngModel]="row.is_work_day === 'Y' ? true : false"
                          (change)="$event.checked === false ? row.is_work_day = 'N' : row.is_work_day = 'Y'">
          <span *ngIf="row.is_work_day === 'Y'">Yes</span>
        </mat-slide-toggle>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="working_crews">
    <th mat-header-cell
        *matHeaderCellDef
        [style.width.px]=200>Exclude Crews</th>
    <td mat-cell
        *matCellDef="let row">
      <ng-container *ngIf="row.is_work_day === 'Y'">
        <mat-form-field [style.width.px]=200>
          <input matInput
                 [(ngModel)]="row.working_crews"
                 placeholder="exclude crews" />
        </mat-form-field>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="comments">
    <th mat-header-cell
        *matHeaderCellDef>Description</th>
    <td mat-cell
        *matCellDef="let row">
      {{ row.comments }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell
        *matHeaderCellDef>Actions</th>
    <td mat-cell
        *matCellDef="let row">

      <button *ngIf="checkIfDateIsFutureDate(row.exc_start_date) && row.holiday_type === 'Corporate Holiday'"
              mat-icon-button
              matTooltip="Save Record"
              matTooltipPosition="left"
              (click)="updateScheduledDate(row)">
        <mat-icon aria-hidden="false"
                  aria-label="Save Record">save</mat-icon>
      </button>

      <button *ngIf="checkIfDateIsFutureDate(row.exc_start_date) && row.holiday_type !== 'Corporate Holiday'"
              mat-icon-button
              matTooltip="Delete Record"
              matTooltipPosition="left"
              (click)="deleteScheduledDate(row)">
        <mat-icon aria-hidden="false"
                  aria-label="Delete Record">delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div class="mat-caption">
  *Exclude Crews: Select crews who will not work on that day. <br>
  *Save record after updating 'Working' and/or 'Exclude Crews' column/s.
</div>