<mat-list role="list">
  <mat-list-item role="listitem">
    <div class="prodLine">{{ productionLine.production_line }}:</div>
    <mat-chip-list aria-label="Teams" *ngIf="productionLine.teams[0] !== null">
      <mat-chip *ngFor="let team of productionLine.teams[0].split(',')">{{
        team
      }}
      <span
      class="delete"
      (click)="deleteTeam(productionLine,team)">
              x</span>
     
</mat-chip>
    </mat-chip-list>

    <ng-container    >
      <mat-form-field>
        <mat-label>Team</mat-label>
        <input matInput type="text" [(ngModel)]="team" />
        <button
          mat-button
          *ngIf="team"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="team = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <button mat-raised-button (click)="addTeam(productionLine)">
        Add Team
      </button>
    </ng-container>
  </mat-list-item>
</mat-list>
