import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { CommonUtil } from '../../../utils/commonUtil';

import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from '../../layout/confirm-dialog/confirm-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { PlantConfigService } from 'src/app/services/plant-config.service';

@Component({
  selector: 'app-config-sunday-schedule',
  templateUrl: './config-sunday-schedule.component.html',
  styleUrls: ['./config-sunday-schedule.component.scss']
})
export class ConfigSundayScheduleComponent implements OnChanges, OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  @Input() sundayScheduledDates;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  tableSource;
  displayedColumns: string[];
  minDate: Date;
  date = new FormGroup({
    start: new FormControl()
  });

  comment = 'Optional Work Day';
  working_crews = '';

  constructor(
    private plantConfigService: PlantConfigService,
    public dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'sundayScheduledDates': {
            this.loadSundayScheduledDates(changes[propName].currentValue);
          }
        }
      }
    }
  }

  ngOnInit() {
    this.minDate = new Date();
    this.dataService.comment = this.comment;
    this.plantConfigService.currentSundayScheduledDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.sundayScheduledDates = data;
      });
  }

  getSundayScheduledDates() {
    this.plantConfigService.getSundayScheduledDates(
      this.dataService.plantCode,
      this.dataService.comment
    );
  }

  loadSundayScheduledDates(data) {
    this.tableSource = new MatTableDataSource(data);
    this.displayedColumns = [
      'exc_start_date',
      'holiday_type',
      'working_crews',
      'comments',
      'actions'
    ];
    this.tableSource.paginator = this.paginator;
    this.changeDetectorRef.detectChanges();
  }

  saveSundayScheduledDate() {
    if (this.date.controls.start.status === 'INVALID') {
      this.openSnackBar(`Invalid start date`, 'Ok', 'warn');
      return;
    } else {
      const sundayData: any = {};
      sundayData.plant_code = this.dataService.plantCode;
      sundayData.plant = this.dataService.plantAbbr;
      sundayData.holiday_type = 'Optional Production';
      sundayData.is_work_day = 'Y';
      sundayData.working_crews = this.working_crews;
      sundayData.start_date = moment(this.date.value.start).format(
        'YYYY-MM-DD'
      );
      sundayData.end_date = moment(this.date.value.start).format('YYYY-MM-DD');
      sundayData.comments = this.comment;
      sundayData.user_id = this.dataService.userId;

      console.log('sundayData.end_date :: ' + sundayData.plant_code);
      if (sundayData.end_date === 'Invalid date') {
        this.openSnackBar(
          `Please select end date. If you would like to add single date then select same start date and end date`,
          'Ok',
          'warn'
        );
        return;
      }

      this.plantConfigService
        .isSundayAlreadyScheduled(
          sundayData.plant_code,
          sundayData.comments,
          sundayData.start_date,
          sundayData.end_date
        )
        .subscribe((data: any) => {
          console.log(data.length);
          if (data.length > 0) {
            console.log(data);
            this.openSnackBar(
              `Optional Production date already present`,
              'Ok',
              'warn'
            );
          } else {
            this.plantConfigService
              .saveSundayScheduledDate(sundayData)
              .subscribe((data) => {
                this.openSnackBar(
                  `Optional Production date added successfully`,
                  'Ok'
                );
                this.getSundayScheduledDates();
              });
          }
        });
    }
  }

  checkIfDateIsFutureDate(date) {
    return CommonUtil.checkIfDateIsFutureDate(date);
  }

  deleteSundayScheduledDate(row) {
    let message;
    row.user_id = this.dataService.userId;
    if (row.exc_start_date === row.exc_end_date) {
      message = `Are you sure you want to delete ${row.exc_start_date}?`;
    } else {
      message = `Are you sure you want to delete sunday/shutdown date ${row.exc_start_date} to ${row.exc_end_date} ?`;
    }

    const dialogData = new ConfirmDialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.plantConfigService
          .deleteSundayScheduledDate(row)
          .subscribe((data) => {
            this.openSnackBar(
              `Sunday/Shutdown date deleted successfully`,
              'Ok'
            );
            this.getSundayScheduledDates();
          });
      }
    });
  }

  updateSundayScheduledDate(row) {
    row.user_id = this.dataService.userId;
    this.plantConfigService.updateSundayScheduledDate(row).subscribe((data) => {
      this.openSnackBar(`Sunday/Shutdown data updated successfully`, 'Ok');
      this.getSundayScheduledDates();
    });
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }

  formatDateToString(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
}
