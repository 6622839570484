<table mat-table
       [dataSource]="shiftTimingsData">

  <ng-container matColumnDef="shift_name">
    <th mat-header-cell
        *matHeaderCellDef>Shift Name</th>
    <td mat-cell
        *matCellDef="let row">
      {{ row.shift_name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="crew">
    <th mat-header-cell
        *matHeaderCellDef>Crew</th>
    <td mat-cell
        *matCellDef="let row">
      {{ row.crew }}
    </td>
  </ng-container>

  <ng-container matColumnDef="shift_crew_days">
    <th mat-header-cell
        *matHeaderCellDef>Shift Crew Days</th>
    <td mat-cell
        *matCellDef="let row">
      {{ row.shift_crew_days }}
    </td>
  </ng-container>

  <ng-container matColumnDef="shift_start_time">
    <th mat-header-cell
        *matHeaderCellDef>Shift Start Time</th>
    <td mat-cell
        *matCellDef="let row">
      {{ row.shift_start_time }}
    </td>
  </ng-container>

  <ng-container matColumnDef="shift_end_time">
    <th mat-header-cell
        *matHeaderCellDef>Shift End Time</th>
    <td mat-cell
        *matCellDef="let row">
      {{ row.shift_end_time }}
    </td>
  </ng-container>

  <tr mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>
</table>