import { Component, OnInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private adalSvc: MsAdalAngular6Service) {}

  ngOnInit(): void {
    if (!this.adalSvc.isAuthenticated) {
      this.adalSvc.login();
      return;
    }
  }
}
