import { Component, OnInit, OnDestroy } from '@angular/core';

import { FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { PlannersWorksheetService } from 'src/app/services/planners-worksheet.service';

@Component({
  selector: 'app-planners-worksheet',
  templateUrl: './planners-worksheet.component.html',
  styleUrls: ['./planners-worksheet.component.scss']
})
export class PlannersWorksheetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  pageTitle: string;
  startDate: string;
  endDate: string;

  todaysDate: string;

  minDate: Date;
  maxDate: Date;

  minDateForRange: moment.Moment;
  maxDateForRange: moment.Moment;

  dateLimit: number;
  selected: any;
  plannerWorksheetCrewData: any;
  plannerWorksheetDeptData: any;
  plannerWorksheetLineData: any;

  today: Date;
  currentDate: FormControl;

  constructor(
    private apiService: ApiService,
    public dataService: DataService,
    private plannersWorksheetService: PlannersWorksheetService
  ) { }

  ngOnInit() {
    this.pageTitle = 'Planners Worksheet';

    this.apiService.currentFilterDefaultDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data[0].min_date) {
          this.minDate = moment(data[0].min_date, 'YYYY-MM-DD').toDate();
          this.minDateForRange = moment(data[0].min_date, 'YYYY-MM-DD');
        }
        if (data[0].max_date) {
          this.maxDate = moment(data[0].max_date, 'YYYY-MM-DD').toDate();
          this.maxDateForRange = moment(data[0].max_date, 'YYYY-MM-DD');
          if (this.dataService.plannerWorksheetEntryLevel === 'CREW') {
            this.today = new Date();
            this.currentDate = new FormControl(this.today);
            this.selected = {
              startDate: moment(this.currentDate.value).format('YYYY-MM-DD'),
              endDate: moment(this.currentDate.value).format('YYYY-MM-DD')
            };
            this.startDate = moment(this.selected.startDate).format(
              'YYYY-MM-DD'
            );
            this.endDate = moment(this.selected.endDate).format('YYYY-MM-DD');
          } else if (
            this.dataService.plannerWorksheetEntryLevel === 'DEPT' ||
            this.dataService.plannerWorksheetEntryLevel === 'PRODUCTION_LINE'
          ) {
            this.today = new Date();
            this.currentDate = new FormControl(this.today);
            this.startDate = moment(this.currentDate.value).format(
              'YYYY-MM-DD'
            );
            this.endDate = moment(this.currentDate.value).format('YYYY-MM-DD');
          }
        }

        if (data[0].min_date === null && data[0].max_date === null) {
          this.minDate = null;
          this.maxDate = null;
          this.today = new Date();
          this.currentDate = new FormControl(this.today);
          this.startDate = moment(this.currentDate.value).format('YYYY-MM-DD');
          this.endDate = moment(this.currentDate.value).format('YYYY-MM-DD');
        }

        this.todaysDate = moment(this.today).format('YYYY-MM-DD');

        this.plannersWorksheetData(
          this.dataService.plantCode,
          this.startDate,
          this.endDate
        );
      });
  }

  getDate(date) {
    console.log(date);
    this.startDate = moment(date).format('YYYY-MM-DD');
    this.endDate = moment(date).format('YYYY-MM-DD');
    this.plannersWorksheetData(
      this.dataService.plantCode,
      this.startDate,
      this.endDate
    );
  }

  getDateRange(range) {
    if (range.startDate !== null) {
      this.startDate = moment(range.startDate).format('YYYY-MM-DD');
      this.endDate = moment(range.endDate).format('YYYY-MM-DD');
      this.plannersWorksheetData(
        this.dataService.plantCode,
        this.startDate,
        this.endDate
      );
    }
  }

  plannersWorksheetData(plantCode, startDate, endDate) {
    this.plannersWorksheetService
      .getPlannerWorksheetByCrew(plantCode, startDate, endDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data): any => {
        this.plannerWorksheetCrewData = data;
      });

    if (this.dataService.plannerWorksheetEntryLevel === 'DEPT') {
      this.plannersWorksheetService
        .getPlannerWorksheetByDept(plantCode, startDate, endDate)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data): any => {
          this.plannerWorksheetDeptData = data;
        });
    }

    if (this.dataService.plannerWorksheetEntryLevel === 'PRODUCTION_LINE') {
      this.plannersWorksheetService
        .getPlannerWorksheetByProdLine(plantCode, startDate, endDate)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data): any => {
          this.plannerWorksheetLineData = data;
        });
    }
  }

  ngOnDestroy() {
    console.log('destroy');
  }
}
