import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PredictionSummaryService {
  constructor(private httpClient: HttpClient) {}

  getPredictionSummaryByCrew(plantcode, crew, startdate, enddate) {
    return this.httpClient.get(
      `${API_URL}/predictionSummary/getPredictionSummaryByCrew/plantcode=${plantcode}&crew=${crew}&startdate=${startdate}&enddate=${enddate}`
    );
  }
}
