<mat-table fxFlex [dataSource]="data" class="mat-elevation-z8">
  <ng-container matColumnDef="date">
    <mat-header-cell class="date" mat-header-cell *matHeaderCellDef>
      <span *ngIf="displayedColumns[0] !== 'production_line'">{{
        data[0].workdate
      }}</span>
    </mat-header-cell>
  </ng-container>

  <ng-container matColumnDef="production_line">
    <mat-header-cell *matHeaderCellDef> Production Line </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.production_line }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="actual">
    <mat-header-cell *matHeaderCellDef> Actual </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.actual }} </mat-cell>
    <mat-footer-cell *matFooterCellDef>
      {{ getTotalActual() }}
    </mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="predicted">
    <mat-header-cell *matHeaderCellDef> Predicted </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.predicted }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>
      {{ getTotalPredicted() }}
    </mat-footer-cell>
  </ng-container>

 <!-- <ng-container matColumnDef="cats_first_hour">
    <mat-header-cell *matHeaderCellDef>First Hour Actuals</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.cats_first_hour }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>
      {{ getTotal(data, 'cats_first_hour') }}
    </mat-footer-cell>
  </ng-container>

  <ng-container matColumnDef="cats_end_of_shift">
    <mat-header-cell *matHeaderCellDef>End of Shift Actuals</mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.cats_end_of_shift }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>
      {{ getTotal(data, 'cats_end_of_shift') }}
    </mat-footer-cell>
  </ng-container>-->

  <mat-header-row *matHeaderRowDef="['date']"> </mat-header-row>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true">
  </mat-footer-row>
</mat-table>
