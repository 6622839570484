<mat-card [ngClass]="{ disabled: notification.isExpired === 'Y' }">
  <p>
    <mat-icon>{{ notification.notification_type }}</mat-icon>
    <span [innerHTML]="replacePipes(notification.notification_text)"></span>
  </p>

  <div class="mat-small" align="end">
    <span *ngIf="notification.isExpired === 'Y'">(Lapsed)</span>
    {{ notification.created_at }}
    <button
      *ngIf="dataService.userRole === 'Super User'"
      mat-icon-button
      matTooltip="Delete Notification"
      matTooltipPosition="left"
      (click)="delete(notification)"
    >
      <mat-icon aria-hidden="false" aria-label="Delete User">delete</mat-icon>
    </button>
  </div>
</mat-card>
