import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ApiService } from 'src/app/services/api.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  comment: string;

  constructor(
    private apiService: ApiService,
    private emailService: EmailService,
    public dialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  submit() {
    this.data.comment = this.comment;
    this.apiService.submitFeedback(this.data).subscribe((res: any) => {
      console.log(res);
    });
    this.emailService
      .sendPredictionFeedBackEmail(this.data)
      .subscribe((res: any) => {
        console.log('Feedback email sent');
      });
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
