<h1 mat-dialog-title>Prediction Feedback</h1>

<div mat-dialog-content>
  <mat-list>
    <mat-list-item *ngIf="data.plantCode">
      Plant code: {{ data.plantCode }}
    </mat-list-item>
    <mat-list-item *ngIf="data.crew"> Crew: {{ data.crew }} </mat-list-item>
    <mat-list-item *ngIf="data.productionGroup">
      Department: {{ data.productionGroup }}
    </mat-list-item>
    <mat-list-item *ngIf="data.productionLine">
      Production Line: {{ data.productionLine }}
    </mat-list-item>
    <mat-list-item *ngIf="data.startDate">
      Start date: {{ data.startDate }}
    </mat-list-item>
    <mat-list-item *ngIf="data.endDate"
      >End date: {{ data.endDate }}
    </mat-list-item>
    <mat-list-item *ngIf="data.actual">
      Actual: {{ data.actual }}
    </mat-list-item>
    <mat-list-item *ngIf="data.predicted">
      Predicted: {{ data.predicted }}
    </mat-list-item>
  </mat-list>
  <mat-form-field class="tp-full-width">
    <textarea matInput placeholder="Comment" [(ngModel)]="comment"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button (click)="close()" mat-raised-button>
    Close
  </button>
  <button (click)="submit()" mat-raised-button color="primary">
    Submit
  </button>
</div>
