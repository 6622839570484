import { Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges } from '@angular/core';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { DataService } from 'src/app/services/data.service';
import { PlannersWorksheetService } from 'src/app/services/planners-worksheet.service';

@Component({
  selector: 'app-worksheet-crew',
  templateUrl: './worksheet-crew.component.html',
  styleUrls: ['./worksheet-crew.component.scss']
})
export class WorksheetCrewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() plannerWorksheetCrewData: any;
  @Input() startDate: string;
  @Input() todaysDate: string;

  start: string;
  today: string;


  displayedCrewColumns: string[] = [
    'workdate',
    'crew',

    'able_to_startUp',
    'planned_builds',
    'actual_builds',
    'comments',

    'actions'
  ];

  displayedColumns: string[] = [
    'workdate',
    'crew',

    'predicted_unplanned_abs',
    'planned_abs',
    'total_expected',

    'floaters',
    'tpt_scheduled',
    'off_std',
    'pqx',
    'total_scheduled',

    'actual_unplanned',
    'actual_planned',
    'black_flags',
    'total_actuals',

    'diff-sceduled-planned',
    'diff-planned-actuals',

    'off_crew',
    'notes',

    'actions'
  ];
  actualVehicleBuiltLastLoadedOn: string;
  plannedAbsLastLoadedOn: string;
  actualAbsLastLoadedOn: string;

  constructor(
    public dataService: DataService,
    private plannersWorksheetService: PlannersWorksheetService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.plannersWorksheetService
      .getPwAutofieldsLastUpdOnTimestamps(this.dataService.plantCode)
      .subscribe((res: any) => {
        console.log(res);
        this.actualVehicleBuiltLastLoadedOn = res[0].actual_vehicle_built_last_loaded_on;
        this.plannedAbsLastLoadedOn = res[0].planned_abs_last_loaded_on;
        this.actualAbsLastLoadedOn = res[0].actual_abs_last_loaded_on;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'startDate': {
            this.start = this.startDate;
            this.today = this.todaysDate;
            break;
          }
        }
      }
    }
  }

  getTotal_predicted_unplanned_abs() {
    return this.plannerWorksheetCrewData
      .map((t) => t.predicted_unplanned_abs)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_planned_abs() {
    return this.plannerWorksheetCrewData
      .map((t) => t.planned_abs)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_total_expected() {
    return (
      this.getTotal_predicted_unplanned_abs() + this.getTotal_planned_abs()
    );
  }

  getTotal_floaters() {
    return this.plannerWorksheetCrewData
      .map((t) => t.floaters)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_tpt_scheduled() {
    return this.plannerWorksheetCrewData
      .map((t) => t.tpt_scheduled)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_off_std() {
    return this.plannerWorksheetCrewData
      .map((t) => t.off_std)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_pqx() {
    return this.plannerWorksheetCrewData
      .map((t) => t.pqx)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_total_scheduled() {
    return (
      this.getTotal_floaters() +
      this.getTotal_tpt_scheduled() -
      this.getTotal_off_std() -
      this.getTotal_pqx()
    );
  }

  getTotal_actual_unplanned() {
    return this.plannerWorksheetCrewData
      .map((t) => t.actual_unplanned)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_actual_planned() {
    return this.plannerWorksheetCrewData
      .map((t) => t.actual_planned)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_black_flags() {
    return this.plannerWorksheetCrewData
      .map((t) => t.black_flags)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_total_actuals() {
    return this.getTotal_actual_unplanned() + this.getTotal_actual_planned() + this.getTotal_black_flags();
  }

  getTotal_off_crew() {
    return this.plannerWorksheetCrewData
      .map((t) => t.off_crew)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_planned_builds() {
    return this.plannerWorksheetCrewData
      .map((t) => t.planned_builds)
      .reduce((acc, value) => acc + value, 0);
  }

  getTotal_actual_builds() {
    return this.plannerWorksheetCrewData
      .map((t) => t.actual_builds)
      .reduce((acc, value) => acc + value, 0);
  }

  saveData(data) {
    data.plantcode = this.dataService.plantCode;
    data.username = this.dataService.userId;
    data.autoPopulatedFieldEntryApplicable = this.dataService.autoPopulatedFieldEntryApplicable;
    this.plannersWorksheetService
      .saveWorksheetEntryForCrew(data)
      .subscribe((res: any) => {
        console.log(res);
        this.openSnackBar(`Your entry has been successfully saved`, 'Ok');
      });
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      duration: 1000,
      panelClass: [color]
    });
  }

  ngOnDestroy() { }
}
