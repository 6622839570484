import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from '../../layout/confirm-dialog/confirm-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { UserAccessService } from 'src/app/services/user-access.service';

@Component({
  selector: 'app-super-user-table',
  templateUrl: './super-user-table.component.html',
  styleUrls: ['./super-user-table.component.scss']
})
export class SuperUserTableComponent implements OnChanges {
  @Input() managementUsersData;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  managementUsersSource;
  displayedColumns: string[];

  constructor(
    private userAccessService: UserAccessService,
    public dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'managementUsersData': {
            this.loadManagementUsers(changes[propName].currentValue);
            console.log(changes[propName].currentValue);
          }
        }
      }
    }
  }

  getManagementUsers() {
    this.userAccessService.getManagementUsers().subscribe((data: any) => {
      this.loadManagementUsers(data);
      this.changeDetectorRef.detectChanges();
    });
  }

  loadManagementUsers(data) {
    console.log(data);
    this.managementUsersSource = new MatTableDataSource(data);
    this.displayedColumns = ['user_id','email', 'user_name', 'user_role', 'actions'];
    this.managementUsersSource.paginator = this.paginator;
    this.managementUsersSource.sort = this.sort;
    this.changeDetectorRef.detectChanges();
  }

  deleteUser(user) {
    const message = `Are you sure you want to revoke access for '${user.user_id}?`;
    const dialogData = new ConfirmDialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.userAccessService.deleteUser(user).subscribe((data) => {
          this.openSnackBar(
            `User '${user.user_id}' deleted successfully`,
            'Ok'
          );
          this.getManagementUsers();
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.managementUsersSource.filter = filterValue.trim().toLowerCase();
    if (this.managementUsersSource.paginator) {
      this.managementUsersSource.paginator.firstPage();
    }
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }
}
