<div class="inner-container"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-between">
  <h1 class="mat-h1">{{ pageTitle }} for <span *ngIf="currentDate">{{ startDate | date }}</span></h1>

  <app-datepicker [date]="currentDate"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  (getDate)="getDate($event)"
                  [isNextPreviousVisible]="true"></app-datepicker>
</div>

<mat-card>
  <app-worksheet-crew *ngIf="dataService.plannerWorksheetEntryLevel === 'CREW'"
                      [plannerWorksheetCrewData]="plannerWorksheetCrewData"
                      [startDate]="startDate"
                      [todaysDate]="todaysDate"></app-worksheet-crew>

  <app-worksheet-dept *ngIf="dataService.plannerWorksheetEntryLevel === 'DEPT'"
                      [plannerWorksheetCrewData]="plannerWorksheetCrewData"
                      [plannerWorksheetDeptData]="plannerWorksheetDeptData"
                      [startDate]="startDate"
                      [todaysDate]="todaysDate"></app-worksheet-dept>

  <app-worksheet-line *ngIf="dataService.plannerWorksheetEntryLevel === 'PRODUCTION_LINE'"
                      [plannerWorksheetCrewData]="plannerWorksheetCrewData"
                      [plannerWorksheetLineData]="plannerWorksheetLineData"
                      [startDate]="startDate"
                      [todaysDate]="todaysDate"></app-worksheet-line>
</mat-card>