import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  OnChanges
} from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-department-summary',
  templateUrl: './department-summary.component.html',
  styleUrls: ['./department-summary.component.scss']
})
export class DepartmentSummaryComponent
  implements OnInit, AfterViewInit, OnChanges {
  @Input() data;
  @Input() isWeek;
  @Input() userId;
  @Input() plantCode;

  prodLines: string[];
  tableData: string[];
  WEEK_STD: string;
  TPT_SCHD: string;

  departmentData;

  constructor() {}

  ngOnChanges() {
    this.updateData(this.data);
  }

  ngOnInit() {
    this.WEEK_STD = 'WEEK_STD';
    this.TPT_SCHD = 'TPT_SCHD';
    this.updateData(this.data);
  }

  updateData(data) {
    this.prodLines = ['production_line'];
    this.tableData = [
      'actual',
      'predicted',
     // 'cats_first_hour',
      //'cats_end_of_shift'
    ];

    this.departmentData = _.chain(data)
      .groupBy('dept_name')
      .map((value, key) => {
        return {
          dept: key,
          deptData: _.chain(value)
            .groupBy('workdate')
            .map((v, k) => {
              return {
                workdate: k,
                workdateData: v
              };
            })
            .value()
        };
      })
      .value();
  }

  ngAfterViewInit() {}
}
