import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from '../../layout/confirm-dialog/confirm-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { UserAccessService } from 'src/app/services/user-access.service';
import { EmailService } from 'src/app/services/email.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

function emailDomainValidator(control: FormControl) {
  const email = control.value.toLowerCase();
  if (email && email.indexOf('@') != -1) {
    const [_, domain] = email.split('@');
    if (
      domain === 'fcagroup.com' ||
      domain === 'external.fcagroup.com' ||
      domain === 'stellantis.com' ||
      domain === 'external.stellantis.com'
    ) {
      return null;
    }
    return {
      emailDomain: {
        parsedDomain: domain
      }
    };
  }
}

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  usersByPlantSource;
  displayedColumns: string[];
  selectedUserRole: string;
  managementUsersData = {};

  tidFormControl = new FormControl('', [
    Validators.required
   ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    emailDomainValidator
  ]);

  nameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3)
  ]);
  matcher = new MyErrorStateMatcher();

  constructor(
    private userAccessService: UserAccessService,
    private emailService: EmailService,
    public dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedUserRole = 'User';
    this.userAccessService.currentUsersByPlant
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.usersByPlant = data;
        this.usersByPlantSource = new MatTableDataSource(data);
        this.displayedColumns = [
          'user_id',
          'email',
          'user_name',
          'user_role',
          'actions'
        ];
        this.usersByPlantSource.paginator = this.paginator;
        this.usersByPlantSource.sort = this.sort;
      });
  }

  ngAfterViewInit() {
    if (this.dataService.usersByPlant !== undefined) {
      this.usersByPlantSource = new MatTableDataSource(
        this.dataService.usersByPlant
      );
      this.displayedColumns = ['user_id','email','user_name', 'user_role', 'actions'];
      this.usersByPlantSource.paginator = this.paginator;
      this.usersByPlantSource.sort = this.sort;
      this.changeDetectorRef.detectChanges();
    } else {
      if (this.dataService.plantCode !== undefined) {
        this.userAccessService.getUsersByPlant(this.dataService.plantCode);
      }
    }
    this.loadManagementUsers();
  }

  createUser(user) {
    if (
      this.tidFormControl.status === 'VALID' &&
      this.emailFormControl.status === 'VALID' &&
      this.nameFormControl.status === 'VALID'
    ) {
      if (this.emailFormControl.value !== this.dataService.userId) {
        user.user_id = this.tidFormControl.value;
        user.email = this.emailFormControl.value;
        user.user_name = this.nameFormControl.value;
        user.user_role = this.selectedUserRole;
        user.plant_code = this.dataService.plantCode;
        user.created_by = this.dataService.userId;
        this.userAccessService.createUser(user).subscribe((data: any) => {
          data.toUserId = data.user_id;
          data.toName = data.user_name;
          data.userId = this.dataService.userId;
          data.created_by = this.dataService.givenName;
          this.openSnackBar(
            `User '${data.user_id}' created successfully`,
            'Ok'
          );
          this.emailService
            .notifyUserAboutAppAccess(data)
            .subscribe((res: any) => {
              console.log('Notified to user about app access');
            });
          this.userAccessService.getUsersByPlant(this.dataService.plantCode);
          this.loadManagementUsers();
        });
      } else {
        this.openSnackBar(
          `You already have access to this plant`,
          'Ok',
          'warn'
        );
      }
    }
  }

  loadManagementUsers() {
    this.userAccessService.getManagementUsers().subscribe((data: any) => {
      this.managementUsersData = data;
      console.log(this.managementUsersData);
      this.changeDetectorRef.detectChanges();
    });
  }

  deleteUser(user) {
    const message = `Are you sure you want to revoke access for '${user.user_id}?`;
    const dialogData = new ConfirmDialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.userAccessService.deleteUser(user).subscribe((data) => {
          this.openSnackBar(
            `User '${user.user_id}' deleted successfully`,
            'Ok'
          );
          this.userAccessService.getUsersByPlant(this.dataService.plantCode);
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.usersByPlantSource.filter = filterValue.trim().toLowerCase();

    if (this.usersByPlantSource.paginator) {
      this.usersByPlantSource.paginator.firstPage();
    }
  }

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }

  ngOnDestroy() {}
}
