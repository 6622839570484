import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { CommonUtil } from '../../../utils/commonUtil';

import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from '../../layout/confirm-dialog/confirm-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { PlantConfigService } from 'src/app/services/plant-config.service';

@Component({
  selector: 'app-config-holiday-schedule',
  templateUrl: './config-holiday-schedule.component.html',
  styleUrls: ['./config-holiday-schedule.component.scss']
})
export class ConfigHolidayScheduleComponent implements OnChanges, OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  @Input() holidayScheduledDates;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  tableSource;
  displayedColumns: string[];
  minDate: Date;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  holidayMinDate: Date;
  holidayRange = new FormGroup({
    start: new FormControl(new Date(new Date().getFullYear(), 0, 1)),
    end: new FormControl(new Date(new Date().getFullYear(), 11, 31))
  });

  selectedHolidayTypes;
  holidayTypes = new FormControl();
  holidayTypeList: string[] = ['Plant Shutdown', 'Corporate Holiday'];
  comment = '';

  constructor(
    private plantConfigService: PlantConfigService,
    public dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private datePipe: DatePipe

  ) { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'holidayScheduledDates': {
            this.loadHolidayScheduledDates(changes[propName].currentValue);
          }
        }
      }
    }
  }

  ngOnInit() {
    this.holidayTypes.setValue(['Plant Shutdown', 'Corporate Holiday']);
    this.selectedHolidayTypes = ['Plant Shutdown', 'Corporate Holiday'];
    this.minDate = new Date();
    this.plantConfigService.currentHolidayScheduledDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.holidayScheduledDates = data;
      });
  }

  getHolidayScheduledDates() {
    console.log(this.selectedHolidayTypes)
    this.plantConfigService.getHolidayScheduledDates(
      this.dataService.plantCode,
      this.datePipe.transform(
        this.holidayRange.value.start,
        'yyyy-MM-dd'
      ),
      this.datePipe.transform(
        this.holidayRange.value.end,
        'yyyy-MM-dd'
      ),
      JSON.stringify(this.selectedHolidayTypes)
    );
  }

  loadHolidayScheduledDates(data) {
    this.tableSource = new MatTableDataSource(data);
    // this.displayedColumns = ['exc_start_date', 'holiday_type', 'is_work_day', 'working_crews' , 'comments', 'actions'];
    this.displayedColumns = [
      'exc_start_date',
      'holiday_type',
      'is_work_day',
      'comments',
      'actions'
    ];
    this.tableSource.paginator = this.paginator;
    this.tableSource.paginator = this.paginator;
    this.changeDetectorRef.detectChanges();
  }

  saveScheduledDate() {
    if (this.range.controls.start.status === 'INVALID') {
      this.openSnackBar(`Invalid start date`, 'Ok', 'warn');
      return;
    } else if (this.range.controls.end.status === 'INVALID') {
      this.openSnackBar(`Invalid end date`, 'Ok', 'warn');
      return;
    } else {
      const holidayData: any = {};
      holidayData.plant_code = this.dataService.plantCode;
      holidayData.plant = this.dataService.plantAbbr;
      holidayData.holiday_type = 'Plant Shutdown';
      holidayData.is_work_day = 'N';
      holidayData.working_crews = null;
      holidayData.start_date = moment(this.range.value.start).format(
        'YYYY-MM-DD'
      );
      holidayData.end_date = moment(this.range.value.end).format('YYYY-MM-DD');
      holidayData.comments = this.comment;
      holidayData.user_id = this.dataService.userId;

      console.log('holidayData.end_date :: ' + holidayData.end_date);
      if (holidayData.end_date === 'Invalid date') {
        this.openSnackBar(
          `Please select end date. If you would like to add single date then select same start date and end date`,
          'Ok',
          'warn'
        );
        return;
      }

      this.plantConfigService
        .isHolidayAlreadyScheduled(
          holidayData.plant_code,
          holidayData.start_date,
          holidayData.end_date
        )
        .subscribe((data: any) => {
          console.log(data.length);
          if (data.length > 0) {
            console.log(data);
            this.openSnackBar(
              `Holiday/Shutdown date already present`,
              'Ok',
              'warn'
            );
          } else {
            this.plantConfigService
              .saveScheduledDate(holidayData)
              .subscribe((data) => {
                this.openSnackBar(
                  `Holiday/Shutdown date added successfully`,
                  'Ok'
                );
                this.getHolidayScheduledDates();
              });
          }
        });
    }
  }

  checkIfDateIsFutureDate(date) {
    return CommonUtil.checkIfDateIsFutureDate(date);
  }

  deleteScheduledDate(row) {
    let message;
    row.user_id = this.dataService.userId;
    if (row.exc_start_date === row.exc_end_date) {
      message = `Are you sure you want to delete ${row.exc_start_date}?`;
    } else {
      message = `Are you sure you want to delete holiday/shutdown range ${row.exc_start_date} to ${row.exc_end_date} ?`;
    }

    const dialogData = new ConfirmDialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.plantConfigService.deleteScheduledDate(row).subscribe((data) => {
          this.openSnackBar(`Holiday/Shutdown date deleted successfully`, 'Ok');
          this.getHolidayScheduledDates();
        });
      }
    });
  }

  updateScheduledDate(row) {
    row.user_id = this.dataService.userId;
    if (row.is_work_day === 'N') {
      row.working_crews = '';
    }
    this.plantConfigService.updateScheduledDate(row).subscribe((data) => {
      this.openSnackBar(`Holiday/Shutdown data updated successfully`, 'Ok');
      this.getHolidayScheduledDates();
    });
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if (dateRangeEnd.value) {
      this.getHolidayScheduledDates();
    }
  }

  filter(data) {
    console.log(data.value);
    this.selectedHolidayTypes = data.value
    this.getHolidayScheduledDates()
  }


  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }
}
