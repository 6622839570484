import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private httpClient: HttpClient) {}

  sendPredictionFeedBackEmail(data) {
    let productionGroupStr;
    let productionLineStr;
    let actualStr;
    let predictedStr;
    if ('undefined' === typeof data.productionGroup) {
      productionGroupStr = ``;
    } else {
      productionGroupStr = `<br>Department: ${data.productionGroup}`;
    }
    if ('undefined' === typeof data.productionLine) {
      productionLineStr = ``;
    } else {
      productionLineStr = `<br>Production Line: ${data.productionLine}`;
    }
    if (0 === data.actual) {
      actualStr = ``;
    } else {
      actualStr = `<br>Actual: ${data.actual}`;
    }
    if (0 === data.predicted) {
      predictedStr = ``;
    } else {
      predictedStr = `<br>Predicted: ${data.predicted}`;
    }
    data.toEmailList = environment.emailList;
    data.subject = `Prediction Feedback for Plant: ${data.plantCode} by ${data.created_by}`;
    data.body =
      `Team, <br><br>Prediction Feedback for Work Date: ${data.workDate}
    <br>Crew: ${data.crew}` +
      productionGroupStr +
      productionLineStr +
      actualStr +
      predictedStr +
      `<br><br>Comments: ${data.comment}
    <br><br>Note: This is an auto generated email sent by ${data.created_by} through Absentee Planning application.
    <br>Please do Reply All to provide your response.`;
    return this.httpClient.post(`${API_URL}/sendEmail`, data);
  }

  sendConfigurationRequestEmail(data) {
    data.toEmailList = environment.emailList;
    data.subject = `Plant configuration changes for ${data.plantCode} by ${data.created_by}`;
    data.body = `Team, <br><br>Please find below configuration changes requested by ${data.created_by}:
    <br><br> ${data.comment}
    <br><br>Note: This is an auto generated email sent by ${data.created_by} through Absentee Planning application.`;
    console.log(data);
    return this.httpClient.post(`${API_URL}/sendEmail`, data);
  }

  notifyUserAboutAppAccess(data) {
    data.toEmailList = [
      {
        email: data.toUserId,
        name: data.toName
      }
    ];
    data.subject = `${data.created_by} has invited you to access Absentee Planning Application (${data.plant_code})`;
    data.body = `Dear ${data.toName},
    <br>
    <p>You have been provided access to <a href="${window.location.protocol}//${window.location.hostname}">Absentee planning Application</a>.</p>
    <p>Link: ${window.location.protocol}//${window.location.hostname}</p>
    <p>Note: This is an auto generated email sent by ${data.created_by} through Absentee Planning application.</p>`;
    return this.httpClient.post(`${API_URL}/sendEmail`, data);
  }
}
