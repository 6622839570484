<div class="inner-container"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-between">
    <h1 class="mat-h1">{{ pageTitle }}</h1>
    <div class="rhs-container"
         fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="space-between">
        <app-date-range-picker class="filterField"
                               [minDate]="minDate"
                               [maxDate]="maxDate"
                               [selected]="selected"
                               (getDate)="getDate($event)">
        </app-date-range-picker>
    </div>
</div>
<div fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="end center">
    Include Holidays
    <mat-slide-toggle class="toggle"
                      [(ngModel)]="includeHolidays"
                      (change)="toggle($event)"></mat-slide-toggle>
</div>
<mat-tab-group mat-stretch-tabs="
                      false"
               mat-align-tabs="start">
    <mat-tab>
        <ng-template mat-tab-label>
            MANUFACTURING
            <button mat-icon-button
                    class="exportButton"
                    (click)="exportAsExcel('MANUFACTURING')">
                <mat-icon>get_app</mat-icon>
            </button>
        </ng-template>
        <div>
            <table id="HrDailyReport"
                   mat-table
                   [dataSource]="hrDailyReport"
                   class="mat-elevation-z8"
                   *ngIf="hrDailyReport && hrDailyReport.length">
                <ng-container matColumnDef="facility">
                    <th mat-header-cell
                        *matHeaderCellDef> Facility </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FACILITY}} </td>
                </ng-container>
                <ng-container matColumnDef="country">
                    <th mat-header-cell
                        *matHeaderCellDef> Country </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COUNTRY}} </td>
                </ng-container>
                <ng-container matColumnDef="empGroup">
                    <th mat-header-cell
                        *matHeaderCellDef> Group </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMP_GROUP}} </td>
                </ng-container>
                <ng-container matColumnDef="employees">
                    <th mat-header-cell
                        *matHeaderCellDef> Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="shortAndLongTermIllnessOrInjuryWithoutCovid">
                    <th mat-header-cell
                        *matHeaderCellDef> Short And Long Term Illness Or Injury Without Covid </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SHORT_AND_LONG_TERM_ILLNESS_OR_INJURY_WITHOUT_COVID}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="covid19Related">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED}} </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19Subtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlma">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA}} </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasons">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS}} </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployees">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="sandlPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> SANDL Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SANDL_PRCNT }}% </td>
                </ng-container>
                <ng-container matColumnDef="covid19RelatedPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19SubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlmaPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasonsPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployeesPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="plannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Planned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.PLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="unplannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Unplanned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.UNPLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>
                <tr mat-header-row
                    *matHeaderRowDef="hrDailyReportColumns; sticky: true"></tr>
                <tr mat-row
                    *matRowDef="let myRowData; columns: hrDailyReportColumns"></tr>
            </table>
        </div>
        <div>
            <table id="HrDailyReportAggTotals"
                   mat-table
                   [dataSource]="hrDailyReportAggTotals"
                   class="mat-elevation-z8"
                   *ngIf="hrDailyReportAggTotals && hrDailyReportAggTotals.length">
                <ng-container matColumnDef="totalType">
                    <th mat-header-cell
                        *matHeaderCellDef> Subtotal/Total </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.AGGTOTALS || report.FACILITY}} </td>
                </ng-container>
                <ng-container matColumnDef="country">
                    <th mat-header-cell
                        *matHeaderCellDef> Country </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COUNTRY}} </td>
                </ng-container>
                <ng-container matColumnDef="empGroup">
                    <th mat-header-cell
                        *matHeaderCellDef> Group </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMP_GROUP}} </td>
                </ng-container>
                <ng-container matColumnDef="employees">
                    <th mat-header-cell
                        *matHeaderCellDef> Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="shortAndLongTermIllnessOrInjuryWithoutCovid">
                    <th mat-header-cell
                        *matHeaderCellDef> Short And Long Term Illness Or Injury Without Covid </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SHORT_AND_LONG_TERM_ILLNESS_OR_INJURY_WITHOUT_COVID}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="covid19Related">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED}} </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19Subtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlma">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA}} </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasons">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS}} </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployees">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="sandlPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> SANDL Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SANDL_PRCNT }}% </td>
                </ng-container>
                <ng-container matColumnDef="covid19RelatedPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19SubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlmaPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasonsPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployeesPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="plannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Planned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.PLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="unplannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Unplanned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.UNPLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="hrDailyReportAggegatedTotalsColumns"></tr>
                <tr mat-row
                    *matRowDef="let myRowData; columns: hrDailyReportAggegatedTotalsColumns"></tr>
            </table>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            MOPAR
            <button mat-icon-button
                    class="exportButton"
                    (click)="exportAsExcel('MOPAR')">
                <mat-icon>get_app</mat-icon>
            </button>
        </ng-template>
        <div>
            <table id="HrDailyReport"
                   mat-table
                   [dataSource]="hrDailyMoparReport"
                   class="mat-elevation-z8"
                   *ngIf="hrDailyMoparReport && hrDailyMoparReport.length">
                <ng-container matColumnDef="facility">
                    <th mat-header-cell
                        *matHeaderCellDef> Facility </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FACILITY}} </td>
                </ng-container>
                <ng-container matColumnDef="country">
                    <th mat-header-cell
                        *matHeaderCellDef> Country </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COUNTRY}} </td>
                </ng-container>
                <ng-container matColumnDef="empGroup">
                    <th mat-header-cell
                        *matHeaderCellDef> Group </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMP_GROUP}} </td>
                </ng-container>
                <ng-container matColumnDef="employees">
                    <th mat-header-cell
                        *matHeaderCellDef> Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="shortAndLongTermIllnessOrInjuryWithoutCovid">
                    <th mat-header-cell
                        *matHeaderCellDef> Short And Long Term Illness Or Injury Without Covid </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SHORT_AND_LONG_TERM_ILLNESS_OR_INJURY_WITHOUT_COVID}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="covid19Related">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED}} </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19Subtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlma">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA}} </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasons">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS}} </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployees">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="sandlPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> SANDL Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SANDL_PRCNT }}% </td>
                </ng-container>
                <ng-container matColumnDef="covid19RelatedPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19SubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlmaPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasonsPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployeesPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="plannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Planned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.PLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="unplannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Unplanned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.UNPLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>
                <tr mat-header-row
                    *matHeaderRowDef="hrDailyReportColumns; sticky: true"></tr>
                <tr mat-row
                    *matRowDef="let myRowData; columns: hrDailyReportColumns"></tr>
            </table>
        </div>
        <div>
            <table id="HrDailyReportAggTotals"
                   mat-table
                   [dataSource]="hrDailyMoparReportAggTotals"
                   class="mat-elevation-z8"
                   *ngIf="hrDailyMoparReportAggTotals && hrDailyMoparReportAggTotals.length">
                <ng-container matColumnDef="totalType">
                    <th mat-header-cell
                        *matHeaderCellDef> Subtotal/Total </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.AGGTOTALS || report.FACILITY}} </td>
                </ng-container>
                <ng-container matColumnDef="country">
                    <th mat-header-cell
                        *matHeaderCellDef> Country </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COUNTRY}} </td>
                </ng-container>
                <ng-container matColumnDef="empGroup">
                    <th mat-header-cell
                        *matHeaderCellDef> Group </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMP_GROUP}} </td>
                </ng-container>
                <ng-container matColumnDef="employees">
                    <th mat-header-cell
                        *matHeaderCellDef> Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="shortAndLongTermIllnessOrInjuryWithoutCovid">
                    <th mat-header-cell
                        *matHeaderCellDef> Short And Long Term Illness Or Injury Without Covid </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SHORT_AND_LONG_TERM_ILLNESS_OR_INJURY_WITHOUT_COVID}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="covid19Related">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED}} </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19Subtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlma">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA}} </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasons">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS}} </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotal">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL}} </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployees">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES}} </td>
                </ng-container>
                <ng-container matColumnDef="sandlPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> SANDL Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SANDL_PRCNT }}% </td>
                </ng-container>
                <ng-container matColumnDef="covid19RelatedPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Covid-19 Related Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.COVID_19_RELATED_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="sicknessInjuryCovid19SubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Sickness Injury Covid-19 Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.SICKNESS_INJURY_COVID_19_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="familyMedicalLeaveActFlmaPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Family Medical Leave Act Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FAMILY_MEDICAL_LEAVE_ACT_FLMA_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="absencesForOtherReasonsPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Absences For Other Reasons Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.ABSENCES_FOR_OTHER_REASONS_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="fmlaOtherAbsenceReasonSubtotalPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> FMLA Other Absence Reason Subtotal Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.FMLA_OTHER_ABSENCE_REASON_SUBTOTAL_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="totalAbsentEmployeesPercent">
                    <th mat-header-cell
                        *matHeaderCellDef> Total Absent Employees Percent </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.TOTAL_ABSENT_EMPLOYEES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="plannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Planned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.PLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>
                <ng-container matColumnDef="unplannedAbsences">
                    <th mat-header-cell
                        *matHeaderCellDef> Unplanned Absences </th>
                    <td mat-cell
                        *matCellDef="let report"> {{report.UNPLANNED_ABSENCES_PRCNT}}% </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="hrDailyReportAggegatedTotalsColumns"></tr>
                <tr mat-row
                    *matRowDef="let myRowData; columns: hrDailyReportAggegatedTotalsColumns"></tr>
            </table>
        </div>

    </mat-tab>
</mat-tab-group>