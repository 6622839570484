<h1 mat-dialog-title>Configuration Change Request</h1>
<div mat-dialog-content>
  <mat-form-field class="tp-full-width">
    <textarea
      rows="10"
      matInput
      placeholder="Change Request Details"
      [(ngModel)]="comment"
    ></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button (click)="close()" mat-raised-button>Close</button>
  <button (click)="submit()" mat-raised-button color="primary">Submit</button>
</div>
