import {
  Component,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';

import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-config-shift-timings',
  templateUrl: './config-shift-timings.component.html',
  styleUrls: ['./config-shift-timings.component.scss']
})
export class ConfigShiftTimingsComponent implements OnChanges, OnInit {
  @Input() shiftTimings;

  shiftTimingsData = [];
  displayedColumns: string[] = ['shift_name', 'crew', 'shift_crew_days', 'shift_start_time', 'shift_end_time'];

  constructor(
    public dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'shiftTimings': {
            this.loadShiftTimings(changes[propName].currentValue);
          }
        }
      }
    }
  }

  ngOnInit() { }

  loadShiftTimings(data) {
    this.shiftTimingsData = data;
    this.changeDetectorRef.detectChanges();
  }
}
