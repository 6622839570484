<div [style.width.px]="width" [style.height.px]="height">
  <canvas
    baseChart
    [datasets]="datasets"
    [labels]="labels"
    [options]="options"
    [colors]="colors"
    [legend]="legend"
    [chartType]="chartType"
    [plugins]="plugins"
  >
  </canvas>
</div>
