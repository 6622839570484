import { Component, Input, OnInit } from '@angular/core';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { DataService } from 'src/app/services/data.service';
import { PlantConfigService } from 'src/app/services/plant-config.service';

import {
  ConfirmDialogModel,
  ConfirmDialogComponent
} from '../../layout/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-config-manage-teams',
  templateUrl: './config-manage-teams.component.html',
  styleUrls: ['./config-manage-teams.component.scss']
})
export class ConfigManageTeamsComponent implements OnInit {
  @Input() productionLine;

  team = '';

  constructor(
    private plantConfigService: PlantConfigService,
    public dataService: DataService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  addTeam(teamData) {
    if (this.team === '') {
      this.openSnackBar(`Enter Team`, 'Ok', 'warn');
      return;
    }

    teamData.team = this.team;
    teamData.userName = this.dataService.userId;

    this.plantConfigService
      .isTeamAlreadyAddedForDept(
        teamData.plant_code,
        teamData.dept_number,
        teamData.dept_name,
        teamData.team
      )
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.overrideTeamForProduction(teamData, data);
        } else {
          this.addTeamForProduction(teamData);
        }
      });
  }

  deleteTeam(teamData,team) {
    teamData.team = team;
    teamData.userName = this.dataService.userId;
    const message = `Are you sure you want to delete team :`+ teamData.team +` from dept/production_line ` + teamData.dept_number +`/` +teamData.production_line ;
    const dialogData = new ConfirmDialogModel('Delete Team', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.plantConfigService
          .deleteTeamForProduction(teamData)
          .subscribe((data) => {
            this.openSnackBar(`Team deleted successfully`, 'Ok');
            this.getProductionTeams();
            this.getProductionTeamsDownload()
          });
      }
    });

  }

  addTeamForProduction(teamData) {
    const message = `Are you sure you want to add team?`;
    const dialogData = new ConfirmDialogModel('Add Team', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.plantConfigService
          .addTeamForProduction(teamData)
          .subscribe((data) => {
            this.openSnackBar(`Team added successfully`, 'Ok');
            this.getProductionTeams();
            this.getProductionTeamsDownload();
          });
      }
    });
  }

  overrideTeamForProduction(teamData, existingTeamData) {
    this.plantConfigService
      .getTeamCountsForProductionLine(
        existingTeamData[0].plant_code,
        existingTeamData[0].dept_number,
        existingTeamData[0].dept_name,
        existingTeamData[0].production_line
      )
      .subscribe((data: any) => {
       /** if (data[0].teamCount === 1) {
          this.openSnackBar(
            `Cannot move this team. Please submit configuration change request to dev team for this change.`,
            'Ok',
            'warn'
          );
          return;
        } else {
          if (existingTeamData.length >= 1) {
            this.openSnackBar(
              `Team ${teamData.team} is present in multiple production lines. Please submit configuration change request to dev team for this change.`,
              'Ok',
              'warn'
            );
          } else { */
            const message = `Team ${teamData.team} is already present in ${existingTeamData[0].production_line}. Would you like to move this team to ${teamData.production_line}?`;
            const dialogData = new ConfirmDialogModel('Move Team', message);
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              maxWidth: '400px',
              data: dialogData
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
                teamData.override = 'Y';
                this.plantConfigService
                  .addTeamForProduction(teamData)
                  .subscribe((data) => {
                    this.openSnackBar(`Team moved successfully`, 'Ok');
                    this.getProductionTeams();
                    this.getProductionTeamsDownload()
                  });
              }
            });
        //  }
       // }
      });
  }

  getProductionTeams() {
    this.plantConfigService.getProductionTeams(this.dataService.plantCode);
  }
  getProductionTeamsDownload() {
    this.plantConfigService.getProductionTeamsForDownload(this.dataService.plantCode);
  }

  

  openSnackBar(message: string, action: string, color?: string) {
    this._snackBar.open(message, action, {
      panelClass: [color]
    });
  }

}
