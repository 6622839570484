import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  constructor(private httpClient: HttpClient) {}

  getWeatherByDate(plantcode, date) {
    return this.httpClient.get(
      `${API_URL}/weather/getWeatherByDate/plantcode=${plantcode}&date=${date}`
    );
  }

  formatStringToDate(dateStr) {
    var parts = dateStr.split('-');
    var date = new Date(parts[0], parts[1] - 1, parts[2]);
    return date;
  }
}
