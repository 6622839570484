import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class HrDailyReportService {
  constructor(private httpClient: HttpClient) { }

  getHrDailyDefaultDates() {
    return this.httpClient.get(
      `${API_URL}/dashboard/getHrDailyDefaultDates`
    );
  }

  getHrDailyReport(startDate, endDate, includeHolidays) {
    return this.httpClient.get(
      `${API_URL}/dashboard/getHrDailyReport/startdate=${startDate}&enddate=${endDate}&includeHoliday=${includeHolidays}`
    );
  }

  getHrMoparDailyReport(startDate, endDate,includeHolidays) {
    return this.httpClient.get(
      `${API_URL}/dashboard/getHrMoparDailyReport/startdate=${startDate}&enddate=${endDate}&includeHoliday=${includeHolidays}`
    );
  }

  getHrDailyAggTotal(startDate, endDate, includeHolidays) {
    return this.httpClient.get(
      `${API_URL}/dashboard/getHrDailyAggTotal/startdate=${startDate}&enddate=${endDate}&includeHoliday=${includeHolidays}`
    );
  }

  getHrMoparDailyAggTotal(startDate, endDate,includeHolidays) {
    return this.httpClient.get(
      `${API_URL}/dashboard/getHrMoparDailyAggTotal/startdate=${startDate}&enddate=${endDate}&includeHoliday=${includeHolidays}`
    );
  }

  getHrDailyFilterData() {
    return this.httpClient.get(
      `${API_URL}/dashboard/getHrDailyFilterData`
    )
  }

  getHrDailyTrends(facility, startDate, endDate) {
    return this.httpClient.get(
      `${API_URL}/dashboard/getHrDailyTrendsWithDateRange/facility=${facility}&startdate=${startDate}&enddate=${endDate}`
    )
  }
}
