import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-executive-toolbar',
  templateUrl: './executive-toolbar.component.html',
  styleUrls: ['./executive-toolbar.component.scss']
})
export class ExecutiveToolbarComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  navToggle() {
    this.sidenavToggle.emit(true);
  }
}
