import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { DataService } from 'src/app/services/data.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-weather-notifications-dialog',
  templateUrl: './weather-notifications-dialog.component.html',
  styleUrls: ['./weather-notifications-dialog.component.scss']
})
export class WeatherNotificationsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WeatherNotificationsDialogComponent>,
    public dataService: DataService,
    public notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    localStorage.setItem(
      'readWeatherNotificationTimeStamp' + this.dataService.plantCode,
      JSON.stringify(formatDate(new Date(), 'yyyyMMddHHmmss', 'en-US'))
    );
    this.dataService.unreadWeatherNotificationCount = 0;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
