import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  constructor(private httpClient: HttpClient) {}

  getHomeCanvasCountYOY(plantcode, crew?, dept?) {
    return this.httpClient.get(
      `${API_URL}/metrics/getHomeCanvasCountYOY/plantcode=${plantcode}&crew=${crew}&dept=${dept}`
    );
  }

  getTptCountYOY(plantcode, crew?, dept?) {
    return this.httpClient.get(
      `${API_URL}/metrics/getTptCountYOY/plantcode=${plantcode}&crew=${crew}&dept=${dept}`
    );
  }

  getabsencecodewklytrendsovertimebycrew(plantcode, startDate, endDate) {
    return this.httpClient.get(
      `${API_URL}/metrics/getabsencecodewklytrendsovertimebycrew/plantcode=${plantcode}&startdate=${startDate}&enddate=${endDate}`
    );
  }

  getabsencecodewklytrendsovertimebydept(plantcode, crew, startDate, endDate) {
    return this.httpClient.get(
      `${API_URL}/metrics/getabsencecodewklytrendsovertimebydept/plantcode=${plantcode}&crew=${crew}&startdate=${startDate}&enddate=${endDate}`
    );
  }

  getabsencecodewklytrendsovertimebyprodline(
    plantcode,
    crew,
    department,
    startDate,
    endDate
  ) {
    return this.httpClient.get(
      `${API_URL}/metrics/getabsencecodewklytrendsovertimebyprodline/plantcode=${plantcode}&crew=${crew}&dept=${department}&startdate=${startDate}&enddate=${endDate}`
    );
  }
}
