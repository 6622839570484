<div class="inner-container"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-between">
  <h1 class="mat-h1">{{ pageTitle }} for <span *ngIf="currentDate">{{ startDate | date }}</span></h1>

  <app-datepicker [date]="currentDate"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  (getDate)="getDate($event)"
                  [isNextPreviousVisible]="true"></app-datepicker>
</div>

<div *ngIf="abilityToStartupData && abilityToStartupData.length > 0"
     fxLayout="row wrap"
     fxLayout.xs="column"
     fxLayoutAlign="space-between">
  <mat-card fxFlex="23">
    <mat-card-header>
      <mat-card-title>Ability to Startup</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion [multi]="true">
        <mat-expansion-panel *ngFor="let trackerData of abilityToStartupData; let i = index"
                             [expanded]="true">
          <mat-expansion-panel-header>
            {{ trackerData.shift }}
          </mat-expansion-panel-header>


          <div *ngFor="let shiftData of trackerData.shiftData; let j = index">
            <h3 class="groupTitle mat-caption">{{shiftData.grp | uppercase }}</h3>

            <mat-list role="list">
              <mat-list-item role="listitem"
                             [matTooltip]="grpData.plant_name"
                             [matTooltipPosition]="'above'"
                             *ngFor="let grpData of shiftData.grpData; let j = index">
                {{ grpData.plant | uppercase }}
                <span *ngIf="grpData.able_to_startup_status !== null">
                  - {{ grpData.able_to_startup }}</span>
                <span class="fill-remaining-space"></span>
                <mat-icon *ngIf="grpData.able_to_startup_status !== null"
                          [ngClass]="{
                      green: grpData.able_to_startup_status === 1,
                      orange: grpData.able_to_startup_status === 2,
                      yellow: grpData.able_to_startup_status === 3,
                      red: grpData.able_to_startup_status === 4
                    }">fiber_manual_record</mat-icon>
              </mat-list-item>
            </mat-list>
          </div>







          <!--<mat-list role="list">
            <mat-list-item role="listitem"
                           [matTooltip]="plantData.plant_name"
                           [matTooltipPosition]="'above'"
                           *ngFor="let plantData of trackerData.plantData; let j = index">
              {{ plantData.plant | uppercase }}
              <span *ngIf="plantData.able_to_startup_status !== null">
                - {{ plantData.able_to_startup }}</span>
              <span class="fill-remaining-space"></span>
              <mat-icon *ngIf="plantData.able_to_startup_status !== null"
                        [ngClass]="{
                  green: plantData.able_to_startup_status === 1,
                  orange: plantData.able_to_startup_status === 2,
                  yellow: plantData.able_to_startup_status === 3,
                  red: plantData.able_to_startup_status === 4
                }">fiber_manual_record</mat-icon>
            </mat-list-item>
          </mat-list>-->
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>

  <div fxFlex="76">
    <div fxLayout="column"
         fxLayoutAlign="space-around"
         fxLayoutGap="10px"
         fxLayoutGap.xs="5px">
      <mat-card>
        <div fxLayout="column"
             fxLayoutAlign="space-between"
             fxLayoutGap="10px"
             fxLayoutGap.xs="5px">
          <mat-chip-list aria-label="Plant selection">
            <mat-chip class="chip"
                      *ngFor="let trackerData of plantsTrackerData"
                      [matTooltip]="trackerData.plantData[0].plant_name"
                      [matTooltipPosition]="'above'"
                      [selectable]="true"
                      [selected]="trackerData.selected"
                      (click)="selectPlant(e, trackerData)">{{ trackerData.plant | uppercase }}</mat-chip>
          </mat-chip-list>

          <table *ngIf="plantData && plantData.length > 0"
                 mat-table
                 fxFlex
                 [dataSource]="plantData">
            <ng-container matColumnDef="crew">
              <th mat-header-cell
                  *matHeaderCellDef>
                Shift
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.crew }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="actual_planned">
              <th mat-header-cell
                  *matHeaderCellDef>
                First Hour <br />
                Planned Absences
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.actual_planned }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="actual_unplanned">
              <th mat-header-cell
                  *matHeaderCellDef>
                First Hour <br />
                Unplanned Absences
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.actual_unplanned }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="black_flags">
              <th mat-header-cell
                  *matHeaderCellDef>
                First Hour <br />
                Black Flags
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.black_flags }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="able_to_startup">
              <th mat-header-cell
                  *matHeaderCellDef>
                Able to Start Up
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.able_to_startup }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="planned_builds">
              <th mat-header-cell
                  *matHeaderCellDef>
                First Hour <br /> Planned Build
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.planned_builds }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="actual_builds">
              <th mat-header-cell
                  *matHeaderCellDef>
                First Hour <br /> Actual Build
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.actual_builds }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <ng-container matColumnDef="comments">
              <th mat-header-cell
                  *matHeaderCellDef>
                Comments
              </th>
              <td mat-cell
                  *matCellDef="let element">
                {{ element.comments }}
              </td>
              <th mat-footer-cell
                  *matFooterCellDef></th>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Weekly Trend By First Hour</mat-card-title>
          <mat-radio-group aria-label="chart by"
                           [(ngModel)]="chartBy"
                           (change)="updateChartBy()">
            <mat-radio-button value="actual_unplanned_abs">Unplanned Absences</mat-radio-button>
            <mat-radio-button value="actual_planned_abs">Planned Absences</mat-radio-button>
            <mat-radio-button value="total_abs">Total Absences</mat-radio-button>
          </mat-radio-group>
        </mat-card-header>
        <mat-card-content>
          <app-chart [title]="chartTitle"
                     [datasets]="chartData"
                     [labels]="chartLabels"
                     [options]="chartOptions"
                     [colors]="chartColors"
                     [legend]="chartLegend"
                     [chartType]="chartType"
                     [plugins]="chartPlugins"
                     [chartWidth]="chartWidth"
                     [chartHeight]="chartHeight"></app-chart>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>