import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ApiService } from 'src/app/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

interface SelectOptions {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})

export class DialogComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  comment: string;
  selectedOptions = 'all';
  selectedOptionViewValue = 'All';
  headerName: string;
  displayedColumns: string[] = ['name', 'abs_code', 'count'];
  dataSource: any[];
  loading: boolean;

  selectOptions: SelectOptions[] = [
    { value: 'all', viewValue: 'All' },
    { value: 'cats_first_hour_count', viewValue: 'First hour actual' },
    { value: 'endOfShift_count', viewValue: 'End of shift actual' },
    { value: 'peopleSoft_count', viewValue: 'People soft actual' }

  ];
  constructor(
    private apiService: ApiService,
    private loaderService: LoaderService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
    this.onOptionChange()
  }

  catFirstHourCount(value) {
    let count = 0;
    this.apiService.currentCatFirstHourActualsAggregation
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res.length > 0 && count !== 1) {
          res.header = 'First Hour Actuals Count'
          res.tableHeader = 'First Hour Actuals'
          res.count_total = 0;
          for (let j = 0; j < res.length; j++) {
            res.count_total += res[j].count
          }
          this.dataSource.push(res);
          if (value === 'all') {
            this.catEndOfShiftHourCount(value);
          }
          count = count + 1;
        } else if (res.length === 0 && count !== 1) {
          if (value === 'all') {
            this.catEndOfShiftHourCount(value);
          }
          count = count + 1;
        }
      })
    this.apiService.catFirstHourActualsAggregation(
      this.selectedOptions,
      this.data.plantCode,
      this.data.crew,
      this.data.dept_name,
      this.data.production_line,
      this.data.startDate,
      this.data.endDate
    )
  }

  catEndOfShiftHourCount(value) {
    let count = 0;
    this.apiService.currentCatEndOfShiftActualsAggregation
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        console.log(res);
        if (res.length > 0 && count !== 1) {
          res.header = 'End Of Shift Actuals Count'
          res.tableHeader = 'End Of Shift Actuals'
          res.count_total = 0;
          for (let j = 0; j < res.length; j++) {
            res.count_total += res[j].count
          }
          this.dataSource.push(res);
          if (value === 'all') {
            this.peopleSoftCount(value);
          }
          count = count + 1;
        } else if (res.length === 0 && count !== 1) {
          if (value === 'all') {
            this.peopleSoftCount(value);
          }
          count = count + 1;
        }
      })
    this.apiService.catEndOfShiftActualsAggregation(
      this.selectedOptions,
      this.data.plantCode,
      this.data.crew,
      this.data.dept_name,
      this.data.production_line,
      this.data.startDate,
      this.data.endDate
    )
  }

  peopleSoftCount(value) {
    let count = 0;
    this.apiService.currentCatPeopleSoftActualsAggregation
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res.length > 0 && count !== 1) {
          res.header = 'People Soft Actuals Count'
          res.tableHeader = 'People Soft Actuals'
          res.count_total = 0;
          for (let j = 0; j < res.length; j++) {
            res.count_total += res[j].count
          }
          this.dataSource.push(res);
          count = count + 1;
        }
      })
    this.apiService.catPeopleSoftActualsAggregation(
      this.selectedOptions,
      this.data.plantCode,
      this.data.crew,
      this.data.dept_name,
      this.data.production_line,
      this.data.startDate,
      this.data.endDate
    )
  }
  onOptionChange() {
    this.dataSource = [];
    if (this.dataSource.length) {
      this.dataSource.length = 0
    }

    if (this.data.production_line !== undefined) {
      this.headerName = 'Production line';
    } else if (this.data.dept_name !== undefined) {
      this.headerName = 'Department';
    } else {
      this.headerName = 'Crew';
    }

    if (this.selectedOptions === 'endOfShift_count') {
      this.catEndOfShiftHourCount(this.selectedOptions);
    } else if (this.selectedOptions === 'peopleSoft_count') {
      this.peopleSoftCount(this.selectedOptions);
    } else {
      this.catFirstHourCount(this.selectedOptions);
    }
  }
  close() {
    this.dialogRef.close();
  }
}
