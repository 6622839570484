import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  ChangeDetectorRef
} from '@angular/core';

import { FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'underscore';

import { ChartOptions, ChartType } from 'chart.js';

import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { PlannersWorksheetService } from 'src/app/services/planners-worksheet.service';

import * as moment from 'moment';
@Component({
  selector: 'app-plant-startup-tracker',
  templateUrl: './plant-startup-tracker.component.html',
  styleUrls: ['./plant-startup-tracker.component.scss']
})
export class PlantStartupTrackerComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  constructor(
    private apiService: ApiService,
    public dataService: DataService,
    private plannersWorksheetService: PlannersWorksheetService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  pageTitle: string;
  startDate: string;

  minDate: Date;
  maxDate: Date;

  minDateForRange: moment.Moment;
  maxDateForRange: moment.Moment;

  dateLimit: number;
  selected: any;
  today: Date;
  currentDate: FormControl;

  currentDateSelection: string;
  currentPlantCode: string;
  abilityToStartupData: any;
  plantsTrackerData: any;
  plantData: any;

  displayedColumns: string[] = [
    'crew',
    'actual_planned',
    'actual_unplanned',
    'black_flags',
    'able_to_startup',
    'planned_builds',
    'actual_builds',
    'comments'
  ];

  chartBy = 'actual_unplanned_abs';
  chartTitle = 'Weekly Trend';
  chartLabels = [];
  chartData: any = [
    {
      label: '',
      data: []
    }
  ];
  chartType: ChartType = 'bar';
  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Count'
          },
          ticks: {
            min: 0
          }
        }
      ]
    },
    legend: {
      display: true,
      position: 'bottom'
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  chartColors = [
    {
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      borderColor: 'rgba(255, 99, 132,1)'
    },
    {
      backgroundColor: 'rgba(62, 149, 205, 0.5)',
      borderColor: 'rgba(62, 149, 205, 1)'
    },
    {
      backgroundColor: 'rgba(255, 206, 86, 0.5)',
      borderColor: 'rgba(255, 206, 86, 1)'
    }
  ];
  chartLegend = true;
  chartPlugins = [];
  chartWidth = 600;
  chartHeight = 300;

  ngOnInit() {
    this.pageTitle = 'Plant Startup Summary';
    this.apiService.currentFilterDefaultDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data[0].min_date) {
          this.minDate = moment(data[0].min_date, 'YYYY-MM-DD').toDate();
          this.minDateForRange = moment(data[0].min_date, 'YYYY-MM-DD');
        }
        if (data[0].max_date) {
          this.maxDate = moment(data[0].max_date, 'YYYY-MM-DD').toDate();
          this.maxDateForRange = moment(data[0].max_date, 'YYYY-MM-DD');
          this.today = new Date();
          this.currentDate = new FormControl(this.today);
          this.startDate = moment(this.currentDate.value).format('YYYY-MM-DD');
        }

        if (data[0].max_date === null) {
          this.minDate = null;
          this.maxDate = null;
          this.today = new Date();
          this.currentDate = new FormControl(this.today);
          this.startDate = moment(this.currentDate.value).format('YYYY-MM-DD');
        }
        this.loadData(this.startDate);
      });
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  getDate(date) {
    this.startDate = moment(date).format('YYYY-MM-DD');
    this.loadData(this.startDate);
  }

  loadData(date) {
    this.plannersWorksheetService
      .getPlantsTrackerInfo(date)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((d): any => {

        this.abilityToStartupData = _.chain(d)
          .groupBy('shift')
          .map((value, key) => {
            return {
              shift: key,
              selected: false,
              shiftData: _.chain(value)
                .groupBy('grp')
                .map((v, k) => {
                  return {
                    grp: k,
                    grpData: _.chain(v).value()
                  };
                })
                .value()
            };
          })
          .value();

        this.plantsTrackerData = _.chain(d)
          .groupBy('plant')
          .map((value, key) => {
            return {
              plant: key,
              selected: false,
              plantData: _.chain(value).value()
            };
          })
          .value();

        if (this.plantsTrackerData[0]) {
          this.plantsTrackerData[0].selected = true;
          this.plantData = this.plantsTrackerData[0].plantData;
          this.currentPlantCode = this.plantData[0].plant_code;
          this.currentDateSelection = date;
          this.loadChart(
            this.currentPlantCode,
            this.currentDateSelection,
            this.chartBy
          );
        }
      });
  }

  selectPlant(e, trackerData) {
    this.plantData = trackerData.plantData;
    this.plantsTrackerData.map((x) => (x.selected = false));
    trackerData.selected = true;
    this.currentPlantCode = this.plantData[0].plant_code;
    this.currentDateSelection = this.plantData[0].workdate;
    this.loadChart(
      this.currentPlantCode,
      this.currentDateSelection,
      this.chartBy
    );
  }

  loadChart(plantCode, date, chartBy) {
    this.plannersWorksheetService
      .getPlantTrackerWeeklyTrend(plantCode, date)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((d): any => {
        this.chartLabels = _.chain(d).pluck('crew').uniq().value();
        this.chartData = _.chain(d)
          .groupBy('workdate')
          .map((val: any, key: any) => {
            return {
              label: key,
              data: _.reduce(
                this.chartLabels,
                (arr, v: any) => {
                  if (val[0] && v === val[0].crew) {
                    return arr.concat(val[0][chartBy]);
                  } else if (val[1] && v === val[1].crew) {
                    return arr.concat(val[1][chartBy]);
                  } else if (val[2] && v === val[2].crew) {
                    return arr.concat(val[2][chartBy]);
                  } else {
                    return arr.concat(0);
                  }
                },
                []
              )
            };
          })
          .value();
      });
  }

  updateChartBy() {
    this.loadChart(
      this.currentPlantCode,
      this.currentDateSelection,
      this.chartBy
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
