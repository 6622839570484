<mat-toolbar color="primary" class="mat-elevation-z6">
  <mat-toolbar-row>
    <button
      mat-button
      id="hamburger"
      (click)="navToggle()"
      matTooltip="Navigation"
      [matTooltipPosition]="'below'"
    >
      <mat-icon aria-hidden="false" aria-label="Menu">menu</mat-icon>
    </button>
    <button mat-button [routerLink]="['dashboard']" fxShow.xs="false">
      <img class="logo" alt="FCA logo" src="assets/images/logo_white.svg" />
    </button>
    <div class="app-details">
      <div class="app-name" fxShow="true">Absentee Planning</div>
      <div class="mat-small">EXECUTIVE SUMMARY</div>
    </div>
    <button
      mat-button
      id="dashboard"
      [routerLink]="['dashboard']"
      [routerLinkActive]="['is-active']"
      matTooltip="Dashboard"
      [matTooltipPosition]="'below'"
      fxShow.xs="false"
    >
      <mat-icon aria-hidden="false" aria-label="Dashboard">dashboard</mat-icon>
    </button>

    <span class="fill-remaining-space"></span>
    <span class="mat-small welcome-text" fxShow.xs="false" fxShow="true"
      >Welcome</span
    >
  </mat-toolbar-row>
</mat-toolbar>
