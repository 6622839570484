<div class="inner-container"
     fxLayout="row"
     fxLayout.xs="column"
     fxLayoutAlign="space-between">
  <h1 class="mat-h1">{{ pageTitle }}</h1>
  <div fxLayout="row"
       fxLayout.xs="column"
       fxLayoutAlign="space-around"
       fxLayoutGap="20px"
       fxLayoutGap.xs="5px">
    <div class="rhs-container"
         fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="space-between">
      <app-date-range-picker class="filterField"
                             [minDate]="minDate"
                             [maxDate]="maxDate"
                             [selected]="selected"
                             (getDate)="getDate($event)">
      </app-date-range-picker>
    </div>
    <mat-form-field class="filterField">
      <mat-label>Country</mat-label>
      <mat-select [(ngModel)]="selectedCountry"
                  (selectionChange)="selectCountry($event)">
        <mat-option *ngFor="let data of country"
                    [value]="data">{{data}} </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filterField">
      <mat-label>EMP Group</mat-label>
      <mat-select [(ngModel)]="selectedEmpGroup"
                  (selectionChange)="selectEmpGroup($event)">
        <mat-option *ngFor="let data of empGroup"
                    [value]="data">{{data}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filterField">
      <mat-label>Facility</mat-label>
      <mat-select [(ngModel)]="selectedFacility"
                  (selectionChange)="selectFacility($event)">
        <mat-option *ngFor="let data of facility"
                    [value]="data">{{data}} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="card-container"
     fxLayout="row wrap"
     fxLayout.xs="column"
     fxLayoutAlign="space-around"
     fxLayoutGap="10px"
     fxLayoutGap.xs="5px"
     *ngIf="filteredTrendData">
  <app-chart-card class="card"
                  fxFlex="noshrink"
                  [title]="chartTitle"
                  [datasets]="chartData"
                  [labels]="chartLabels"
                  [options]="chartOptions"
                  [colors]="chartColors"
                  [legend]="chartLegend"
                  [chartType]="chartType"
                  [plugins]="chartPlugins"
                  [cardCount]="cardCount"
                  [chartHeight]="500"
                  [isToggleVisible]=false
                  [jsonDataForExport]="jsonDataForExport"></app-chart-card>
</div>