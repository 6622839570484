import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  Input,
  OnInit
} from '@angular/core';

import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'underscore';

import { DataService } from 'src/app/services/data.service';
import { PlantConfigService } from 'src/app/services/plant-config.service';

@Component({
  selector: 'app-config-teams',
  templateUrl: './config-teams.component.html',
  styleUrls: ['./config-teams.component.scss']
})
export class ConfigTeamsComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  @Input() productionTeams;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  tableSource;
  displayedColumns: string[];

  constructor(
    private plantConfigService: PlantConfigService,
    public dataService: DataService
  ) {}

  ngOnInit() {
    this.plantConfigService.currentProductionTeams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.dataService.productionTeams = _.chain(data)
          .groupBy('dept_number')
          .map((value: any, key) => {
            return {
              dept_number: key,
              dept_name: _.pluck(value, 'dept_name')[0],
              productionLines: _.chain(value)
                .groupBy('production_line')
                .map((val: any, k) => {
                  console.log(val);
                  return {
                    plant: val[0].plant,
                    plant_code: val[0].plant_code,
                    dept_number: val[0].dept_number,
                    dept_name: val[0].dept_name,
                    production_line: k,
                    teams: _.pluck(val, 'teams')
                  };
                })
                .value()
            };
          })
          .value();
      });
  }
}
