import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataService } from 'src/app/services/data.service';
import { Notification } from '../../layout/notification/notification.interface';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-weather-notifications',
  templateUrl: './weather-notifications.component.html',
  styleUrls: ['./weather-notifications.component.scss']
})
export class WeatherNotificationsComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  pageTitle: string;
  selectedNotificationType: string;

  notificationTextFormControl = new FormControl('', [Validators.required]);

  constructor(
    private router: Router,
    public dataService: DataService,
    private notificationsService: NotificationsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.pageTitle = 'Weather Alerts';
    this.dataService._activeWeatherNotificationCount.subscribe((v) => {
      this.loadWeatherNotifications();
    });
    this.loadWeatherNotifications();
  }

  loadWeatherNotifications() {
    this.dataService.weatherNotifications = [];
    if (
      this.router.url.includes('/weather-notifications') &&
      this.dataService.activeWeatherNotificationCount > 0
    ) {
      this.notificationsService
        .getAllWeatherNotificationsByPlant(
          this.dataService.plantCode,
          this.dataService.activeWeatherNotificationCount +
            this.dataService.maxDisabledNotificationToShow
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: Notification[]) => {
          this.dataService.weatherNotifications = data;
        });
    }
  }
}
