<mat-form-field>
  <input
    matInput
    [matDatepicker]="picker"
    [placeholder]="placeholder"
    [formControl]="date"
    [min]="minDate"
    [max]="maxDate"
    (dateChange)="updateDate($event)"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<button
  *ngIf="isNextPreviousVisible"
  mat-icon-button
  matTooltip="Previous Day"
  [matTooltipPosition]="'above'"
  (click)="prevDay()"
>
  <mat-icon aria-hidden="false" aria-label="Previous">navigate_before</mat-icon>
</button>
<button
  *ngIf="isNextPreviousVisible"
  mat-icon-button
  matTooltip="Next Day"
  [matTooltipPosition]="'above'"
  (click)="nextDay()"
>
  <mat-icon aria-hidden="false" aria-label="Next">navigate_next</mat-icon>
</button>
