<mat-card (click)="onCardClick($event, data)"
          (mouseenter)="mouseEnter(true)"
          (mouseleave)="mouseLeave(false)"
          [class.mat-elevation-z2]="!isActive"
          [class.mat-elevation-z8]="isActive">
  <mat-card-header>
    <mat-card-title>{{ data?.cardName }}</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="!loading">
    <div fxLayout="column wrap"
         fxLayoutAlign="start left"
         fxLayoutGap="10px">
      <div fxLayout
           fxLayout.xs="column"
           fxLayoutAlign="space-around"
           fxLayoutGap="20px"
           fxLayoutGap.xs="5px">
        <div class="main-section">
          <div class="value"
               matTooltip="Unplanned Absentee Actual"
               [matTooltipPosition]="'above'"
               *ngIf="data?.actual === 0 || data?.actual === null">
            -
          </div>
          <div class="value"
               matTooltip="Unplanned Absentee Actual"
               [matTooltipPosition]="'above'"
               *ngIf="data?.actual !== 0">
            {{ data?.actual }}
          </div>
          <div class="label">PeopleSoft Actual</div>
        </div>
        <div class="main-section-predicted">
          <div class="value"
               matTooltip="Unplanned Absentee Prediction"
               [matTooltipPosition]="'above'">
            {{ data?.predicted }}
          </div>
          <div class="label">Predicted</div>
        </div>
      </div>
      <div fxLayout
           fxLayout.xs="column"
           fxLayoutAlign="space-around"
           fxLayoutGap="20px"
           fxLayoutGap.xs="5px">
        <div class="sub-section">
          <div class="value">{{ data?.predictedLow }}</div>
          <div class="label">Predicted Low</div>
        </div>
        <div class="sub-section">
          <div class="value">{{ data?.predictedHigh }}</div>
          <div class="label">Predicted High</div>
        </div>
      </div>
      <div fxLayout
           fxLayout.xs="column"
           fxLayoutAlign="space-around"
           fxLayoutGap="20px"
           fxLayoutGap.xs="5px">

        <div class="sub-section"
             *ngIf="data?.show_compression_crew_aggr==='true'">
          <div class="value">{{ data?.predicted_compression_crew_level_cats }}</div>
          <div class="label">Weather Advisory Forecast </div>
        </div>
      </div>
      <div fxLayout
           fxLayout.xs="column"
           fxLayoutAlign="space-around"
           fxLayoutGap="20px"
           fxLayoutGap.xs="5px">
        <div class="sub-section">
          <div class="value"
               matTooltip="First Hour Actuals"
               [matTooltipPosition]="'above'"
               *ngIf="data?.firstHourActuals === 0 || data?.firstHourActuals === null">
            - </div>
          <div class="value"
               matTooltip="First Hour Actuals"
               [matTooltipPosition]="'above'"
               *ngIf="data?.firstHourActuals !== 0">
            {{ data?.firstHourActuals}}</div>
          <div class="label">First Hour Actuals</div>
        </div>
        <div class="sub-section">
          <div class="value"
               matTooltip="End Of Shift Actuals"
               [matTooltipPosition]="'above'"
               *ngIf="data?.endOfShiftActuals === 0 || data?.endOfShiftActuals === null">
            - </div>
          <div class="value"
               matTooltip="End Of Shift Actuals"
               [matTooltipPosition]="'above'"
               *ngIf="data?.endOfShiftActuals !== 0">
            {{ data?.endOfShiftActuals}}</div>
          <div class="label">End Of Shift Actuals</div>
        </div>
      </div>
    </div>
    <div #chartContainer
         fxLayout="row wrap"
         fxLayoutAlign="space-evenly stretch"
         *ngIf="startDate !== endDate">
      <app-chart [datasets]="chartDataForCard"
                 [labels]="chartLabelsForCard"
                 [options]="lineChartOptions"
                 [colors]="lineChartColors"
                 [legend]="lineChartLegend"
                 [chartType]="lineChartType"
                 [plugins]="lineChartPlugins"
                 [width]="chartWidth"
                 [height]="chartHeight"></app-chart>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-icon-button
            id="filter-button"
            matTooltip="Feedback"
            [matTooltipPosition]="'above'"
            (click)="onFeedbackClick($event, data)">
      <mat-icon aria-hidden="false"
                aria-label="Filter">chat_bubble_outline</mat-icon>
    </button>
    <button *ngIf="startDate !== endDate"
            mat-icon-button
            matTooltip="Fullscreen Chart"
            [matTooltipPosition]="'above'"
            (click)="expand()">
      <mat-icon aria-hidden="false"
                aria-label="Fullscreen">fullscreen</mat-icon>
    </button>
    <button mat-icon-button
            id="filter-button"
            matTooltip="Actuals at granular level"
            [matTooltipPosition]="'above'"
            (click)="onDialogClick($event, data)">
      <mat-icon aria-hidden="false"
                aria-label="Filter">more_vert</mat-icon>

    </button>

  </mat-card-actions>
</mat-card>