import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { HrDailyReportService } from 'src/app/services/hr-daily-report.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-hr-daily-report-trends',
  templateUrl: './hr-daily-report-trends.component.html',
  styleUrls: ['./hr-daily-report-trends.component.scss']
})
export class HrDailyReportTrendsComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  pageTitle: string;
  country: any;
  selectedCountry: any;
  empGroup: any;
  selectedEmpGroup: any;
  facility: any;
  selectedFacility: any;
  hrDailyReportFilterData: any;
  filteredTrendData: any;
  tooltipValue: any;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  startDate: string;
  endDate: string;
  selected;

  chartTitle = '';
  cardCount = 1;
  chartLabels = [];
  chartData = [];
  chartType: ChartType = 'bar';
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          this.tooltipValue = tooltipItem.yLabel;
          this.tooltipValue = Math.round(this.tooltipValue);
          return data.datasets[tooltipItem.datasetIndex].label + ": " + this.tooltipValue + '%';
        }
      }
    },
    scales: {
      yAxes: [
        {
          position: 'left',
          display: true,
          ticks: {
            min: 0,
            max: 100,
            callback: function (value) {
              return value + "%"
            }
          },
          scaleLabel: {
            display: true,
            labelString: 'Percentage (%)',
          }
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Workdate',
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        formatter: function (value, context) {
          return Math.round(value) + '%';
        },
        font: {
          size: 10,
        },
      },
    },
  };
  chartColors = [
    {
      backgroundColor: 'rgba(247, 150, 70, 1)',
      borderColor: 'rgba(255, 172, 38, 1)',
    },
    {
      backgroundColor: 'rgba(128, 100, 162, 1)',
      borderColor: 'rgba(156, 90, 146, 1)',
    },
    {
      backgroundColor: 'rgba(255, 206, 86, 0.1)',
      borderColor: 'rgba(255, 206, 86, 1)',
    },
  ];
  chartLegend = true;
  chartPlugins = [];
  jsonDataForExport = [];

  constructor(
    private hrDailyReportService: HrDailyReportService
  ) { }

  ngOnInit(): void {

    this.pageTitle = 'HR Daily Report Trends';

    this.getHrDailyFilterData();

    this.maxDate = moment();
    this.minDate = moment('2023-05-01', 'YYYY-MM-DD');

    this.selected = {
      startDate: moment(this.maxDate, 'YYYY-MM-DD').subtract(
        30,
        'days'
      ),
      endDate: moment(this.maxDate, 'YYYY-MM-DD')
    };
  }

  getHrDailyFilterData() {
    this.hrDailyReportService.getHrDailyFilterData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.hrDailyReportFilterData = data;

        this.country = this.hrDailyReportFilterData.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.COUNTRY === value.COUNTRY
          ))
        ).map(x => x.COUNTRY);
        this.selectedCountry = this.country[2];

        this.empGroup = this.hrDailyReportFilterData.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.EMP_GROUP === value.EMP_GROUP
          ))
        ).map(x => x.EMP_GROUP);
        this.selectedEmpGroup = this.empGroup[0];

        this.facility = this.hrDailyReportFilterData.filter(x => (x.COUNTRY === this.selectedCountry && x.EMP_GROUP === this.selectedEmpGroup))
        this.facility = this.facility.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.FACILITY === value.FACILITY
          ))
        ).map(x => x.FACILITY);
        this.facility.unshift('All');
        this.selectedFacility = this.facility[0];

        this.getFilteredTrendData(this.selectedCountry, this.selectedEmpGroup, this.selectedFacility);
      });
  }

  getDate(range) {
    if (range.startDate !== null) {
      this.startDate = moment(range.startDate).format('YYYY-MM-DD');
      this.endDate = moment(range.endDate).format('YYYY-MM-DD');
    }

    this.getFilteredTrendData(this.selectedCountry, this.selectedEmpGroup, this.selectedFacility);
  }

  selectCountry(country) {
    this.empGroup = this.hrDailyReportFilterData.filter(x => (x.COUNTRY === this.selectedCountry));
    this.empGroup = this.empGroup.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.EMP_GROUP === value.EMP_GROUP
      ))
    ).map(x => x.EMP_GROUP);
    this.selectedEmpGroup = this.empGroup[0];
    this.facility = this.hrDailyReportFilterData.filter(x => (x.COUNTRY === country.value && x.EMP_GROUP === this.selectedEmpGroup)).map(x => x.FACILITY);
    this.facility.unshift('All');
    this.selectedFacility = this.facility[0];
    this.getFilteredTrendData(this.selectedCountry, this.selectedEmpGroup, this.selectedFacility);
  }

  selectEmpGroup(value) {
    this.facility = this.hrDailyReportFilterData.filter(x => (x.COUNTRY === this.selectedCountry && x.EMP_GROUP === value.value)).map(x => x.FACILITY);
    this.facility.unshift('All');
    this.selectedFacility = this.facility[0];
    this.getFilteredTrendData(this.selectedCountry, this.selectedEmpGroup, this.selectedFacility);
  }

  selectFacility(value) {
    this.getFilteredTrendData(this.selectedCountry, this.selectedEmpGroup, this.selectedFacility);
  }

  getFilteredTrendData(country, empGroup, facility) {
    facility = facility ? facility : 'All';
    this.hrDailyReportService.getHrDailyTrends(facility, this.startDate, this.endDate).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.filteredTrendData = data;
        this.filteredTrendData = this.filteredTrendData.filter(x => (x.COUNTRY === country) && (x.EMP_GROUP === empGroup));

        this.chartTitle = 'HR Daily Report Trends';
        if (this.filteredTrendData) {
          this.chartLabels = _.pluck(this.filteredTrendData, 'WORKDATE');
          this.chartData = [
            {
              label: 'Planned Absences Percent',
              borderWidth: 0.6,
              data: _.pluck(this.filteredTrendData, 'PLANNED_ABSENCES_PRCNT'),
              type: 'bar',
            },
            {
              label: 'Unplanned Absences Percent',
              borderWidth: 0.6,
              data: _.pluck(this.filteredTrendData, 'UNPLANNED_ABSENCES_PRCNT'),
              type: 'bar',
            }
          ];
          this.jsonDataForExport = this.filteredTrendData.map((x: any) => ({
            ['WorkDate']: x.WORKDATE,
            ['Planned Absences Percent']: (x.PLANNED_ABSENCES_PRCNT).toFixed(2) + '%',
            ['Unplanned Absences Percent']: (x.UNPLANNED_ABSENCES_PRCNT).toFixed(2) + '%'
          }));
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
