<mat-form-field class="dateRangeField">
  <input
    type="text"
    placeholder="Date range"
    matInput
    ngxDaterangepickerMd
    startKey="startDate"
    endKey="endDate"
    [(ngModel)]="selected"
    [ranges]="ranges"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateLimit]="dateLimit"
    [autoApply]="true"
    [alwaysShowCalendars]="true"
    [keepCalendarOpeningWithRange]="false"
    [showRangeLabelOnInput]="true"
    [showCustomRangeLabel]="true"
    [linkedCalendars]="true"
    [singleDatePicker]="false"
    [locale]="{
      format: 'YYYY-MM-DD',
      separator: ' to '
    }"
    [showDropdowns]="true"
    (datesUpdated)="datesUpdated($event)"
  />
</mat-form-field>
