import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'underscore';
import * as moment from 'moment';

import { ChartOptions, ChartType } from 'chart.js';

import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { PlannersWorksheetService } from 'src/app/services/planners-worksheet.service';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit, OnChanges, OnDestroy {
  ngUnsubscribe: Subject<any> = new Subject<any>();

  pageTitle = 'Predicted vs. Scheduled vs. Actual';
  startDate: string;
  endDate: string;
  cardCount: number;
  crew: string;
  chartBy = 'crew';
  chartData: any[];
  chartType: ChartType = 'line';
  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Count'
          },
          ticks: {
            min: 0
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  chartColors = [
    {
      backgroundColor: 'rgba(255, 99, 132, 0.1)',
      borderColor: 'rgba(255, 99, 132,1)'
    },
    {
      backgroundColor: 'rgba(62, 149, 205, 0.1)',
      borderColor: 'rgba(62, 149, 205, 1)'
    },
    {
      backgroundColor: 'rgba(255, 206, 86, 0.1)',
      borderColor: 'rgba(255, 206, 86, 1)'
    }
  ];
  chartLegend = true;
  chartPlugins = [];

  minDate: moment.Moment;
  maxDate: moment.Moment;
  selected: any;

  constructor(
    public dataService: DataService,
    private apiService: ApiService,
    private plannersWorksheetService: PlannersWorksheetService,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnChanges() {}

  ngOnInit() {
    this.apiService.currentFilterDefaultDates
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data[0].min_date) {
          this.minDate = moment(data[0].min_date, 'YYYY-MM-DD');
        }
        if (data[0].max_date) {
          this.maxDate = moment(data[0].max_date, 'YYYY-MM-DD');

          let subtractDays;
          if (this.dataService.plantCode === '2452') {
            subtractDays = 5;
          } else {
            subtractDays = 6;
          }

          this.selected = {
            startDate: moment(data[0].max_date, 'YYYY-MM-DD').subtract(
              subtractDays,
              'days'
            ),
            endDate: moment(data[0].max_date, 'YYYY-MM-DD')
          };
          this.startDate = moment(this.selected.startDate).format('YYYY-MM-DD');
          this.endDate = moment(this.selected.endDate).format('YYYY-MM-DD');
        }

        this.crew = this.dataService.crewArr[0];
        this.loadCharts(this.chartBy);
      });
  }

  loadCharts(chartBy) {
    if (chartBy === 'crew') {
      this.getPlannerWorksheetChartByCrew(this.startDate, this.endDate);
    } else {
      this.getPlannerWorksheetChartByDepartment(
        this.crew,
        this.startDate,
        this.endDate
      );
    }
  }

  getDate(range) {
    if (range.startDate && range.endDate) {
      this.startDate = moment(range.startDate).format('YYYY-MM-DD');
      this.endDate = moment(range.endDate).format('YYYY-MM-DD');
      this.dataService.startDate = this.startDate;
      this.dataService.endDate = this.endDate;
      this.loadCharts(this.chartBy);
    }
  }

  chartByChange(val) {
    this.chartBy = val;
    this.loadCharts(this.chartBy);
  }

  crewChange(val) {
    this.crew = val;
    this.loadCharts(this.chartBy);
  }

  getPlannerWorksheetChartByCrew(startDate, endDate) {
    this.plannersWorksheetService
      .getPlannerWorksheetChartByCrew(
        this.dataService.plantCode,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.chartData = _.chain(data)
          .groupBy('crew')
          .map((value, key) => {
            return {
              chartTitle: key,
              chartLabels: _.pluck(value, 'workdate'),
              data: [
                {
                  label: 'Predicted',
                  data: _.pluck(value, 'total_expected')
                },
                {
                  label: 'Scheduled',
                  data: _.pluck(value, 'total_scheduled')
                },
                {
                  label: 'Actual',
                  data: _.pluck(value, 'total_actuals')
                }
              ]
            };
          })
          .value();

        this.cardCount = this.chartData.length;
      });
  }

  getPlannerWorksheetChartByDepartment(crew, startDate, endDate) {
    this.plannersWorksheetService
      .getPlannerWorksheetChartByDepartment(
        this.dataService.plantCode,
        crew,
        startDate,
        endDate
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.chartData = _.chain(data)
          .groupBy('dept_name')
          .map((value, key) => {
            return {
              chartTitle: key,
              chartLabels: _.pluck(value, 'workdate'),
              data: [
                {
                  label: 'Predicted',
                  data: _.pluck(value, 'total_expected')
                },
                {
                  label: 'Scheduled',
                  data: _.pluck(value, 'total_scheduled')
                },
                {
                  label: 'Actual',
                  data: _.pluck(value, 'total_actuals')
                }
              ]
            };
          })
          .value();

        this.cardCount = this.chartData.length;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
