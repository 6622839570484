<mat-list>
  <mat-list-item (click)="navToggle()"
                 [routerLink]="['/plant-startup-summary']"
                 [routerLinkActive]="['list-item-active']">
    Plant Startup Summary
  </mat-list-item>
  <!--<mat-list-item
    (click)="navToggle()"
    [routerLink]="['/absence-over-time']"
    [routerLinkActive]="['list-item-active']"
  >
    Absence Over Time
  </mat-list-item>-->
  <mat-list-item (click)="navToggle()"
                 [routerLink]="['/planners-worksheet']"
                 [routerLinkActive]="['list-item-active']">
    Planners Worksheet
  </mat-list-item>
  <mat-list-item (click)="navToggle()"
                 [routerLink]="['/prediction-summary']"
                 [routerLinkActive]="['list-item-active']">
    Prediction Summary
  </mat-list-item>
  <mat-list-item (click)="navToggle()"
                 [routerLink]="['/hr-daily-report']"
                 [routerLinkActive]="['list-item-active']">
    HR Daily Reporting
  </mat-list-item>
  <mat-list-item (click)="navToggle()"
                 [routerLink]="['/hr-daily-report-trends']"
                 [routerLinkActive]="['list-item-active']">
    HR Daily Reporting Trends
  </mat-list-item>
  <mat-list-item mat-button
                 *ngFor="let c of config"
                 (click)="navToggle()"
                 [routerLink]="c.routerLink"
                 [class.list-item-active]="isLinkActive(c.routerLink)">
    {{ c.levelName }}
  </mat-list-item>
</mat-list>