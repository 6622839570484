<mat-card>
  <div #card>
    <mat-card-header>
      <mat-card-title class="title">{{ title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group mat-align-tabs="end">
        <mat-tab label="Chart">
          <div
            class="tabContent"
            #chartContainer
            fxLayout="row wrap"
            fxLayoutAlign="space-evenly stretch"
          >
            <div fxLayoutGap="grid">
              <app-chart
                [datasets]="datasets"
                [labels]="labels"
                [options]="options"
                [colors]="colors"
                [legend]="legend"
                [chartType]="chartType"
                [plugins]="plugins"
                [width]="chartWidth"
                [height]="chartHeight"
              ></app-chart>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Data">
          <div fxLayoutGap="grid">
            <app-table
              fxFlex
              class="tabContent"
              [data]="jsonDataForExport"
            ></app-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </div>
  <mat-card-actions align="end">
    <mat-slide-toggle
      *ngIf="isToggleVisible"
      class="slideToggle"
      (change)="changeChartType($event.checked)"
      >{{ chartType }}</mat-slide-toggle
    >

    <button
      *ngIf="jsonDataForExport && jsonDataForExport.length > 0"
      mat-icon-button
      matTooltip="Export to Excel"
      [matTooltipPosition]="'above'"
      (click)="export()"
    >
      <mat-icon aria-hidden="false" aria-label="Export">save_alt</mat-icon>
    </button>

    <button
      mat-icon-button
      matTooltip="Fullscreen Chart"
      [matTooltipPosition]="'above'"
      (click)="expand()"
    >
      <mat-icon aria-hidden="false" aria-label="Fullscreen"
        >fullscreen</mat-icon
      >
    </button>
  </mat-card-actions>
</mat-card>
