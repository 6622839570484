import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class LevelService {
  constructor(private httpClient: HttpClient) {}

  getPredictionDataByCrew(plantcode, startdate, enddate) {
    return this.httpClient.get(
      `${API_URL}/level/getPredictionDataByCrew/plantcode=${plantcode}&startdate=${startdate}&enddate=${enddate}`
    );
  }

  getPredictionDataByDept(plantcode, crew, startdate, enddate) {
    return this.httpClient.get(
      `${API_URL}/level/getPredictionDataByDept/plantcode=${plantcode}&crew=${crew}&startdate=${startdate}&enddate=${enddate}`
    );
  }

  getPredictionDataByProductionLine(plantcode, crew, dept, startdate, enddate) {
    return this.httpClient.get(
      `${API_URL}/level/getPredictionDataByProductionLine/plantcode=${plantcode}&crew=${crew}&dept=${dept}&startdate=${startdate}&enddate=${enddate}`
    );
  }
}
