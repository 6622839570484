import {
  Component,
  OnInit,
  OnChanges,
  AfterViewChecked,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as _ from 'underscore';

import { NotificationsDialogComponent } from '../notifications-dialog/notifications-dialog.component';
import { WeatherNotificationsDialogComponent } from '../weather-notifications-dialog/weather-notifications-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { UserAccessService } from 'src/app/services/user-access.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  private ngUnsubscribe: Subject<any> = new Subject<any>();

  @Output() sidenavToggle = new EventEmitter<boolean>();

  isNotifications: boolean;
  isWeatherNotifications: boolean;
  unreadNotificationCount: number;
  unreadWeatherNotificationCount: number;

  config = [];
  dbParam = {};

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private userAccessService: UserAccessService,
    public dataService: DataService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges() { }

  ngOnInit() {
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('/notifications')) {
            this.isNotifications = true;
          } else {
            this.isNotifications = false;
          }

          if (event.url.includes('/weather-notifications')) {
            this.isWeatherNotifications = true;
          } else {
            this.isWeatherNotifications = false;
          }
          this.updateConfig();
        }
      });

    this.dbParam = {
      givenName: this.dataService.givenName
    };

    this.apiService.currentFilterConfig
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.dataService.crewArr = _.chain(data)
          .map((item) => item.crew)
          .uniq()
          .value();

        this.dataService.productionGroupArr = _.chain(data)
          .map((item) => item.dept_name)
          .uniq()
          .value();
        this.dataService.crew = this.dataService.crewArr[0];
        this.dataService.productionGroup = this.dataService.productionGroupArr[0];
        this.updateConfig();
      });
  }

  ngAfterViewChecked() {
    this.unreadNotificationCount = this.dataService.unreadNotificationCount;
    this.unreadWeatherNotificationCount = this.dataService.unreadWeatherNotificationCount;
    this.changeDetectorRef.detectChanges();
  }

  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    return baseUrl == url;
  }

  updateConfig() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        plantCode: this.dataService.plantCode
      },
      queryParamsHandling: 'merge'
    });

    if (this.router.url.includes('/level/production-group')) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          plantCode: this.dataService.plantCode,
          crew: this.dataService.crew
        },
        queryParamsHandling: 'merge'
      });
    }

    if (this.router.url.includes('/level/production-line')) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          plantCode: this.dataService.plantCode,
          crew: this.dataService.crew,
          productionGroup: this.dataService.productionGroup
        },
        queryParamsHandling: 'merge'
      });
    }
    this.config = [
      {
        levelName: 'Crew',
        routerLink: ['/level/crew'],
        routerLinkActive: ['is-active'],
        queryParams: {
          givenName: this.dataService.givenName,
          plantCode: this.dataService.plantCode,
          startDate: this.dataService.startDate,
          endDate: this.dataService.endDate
        }
      },
      {
        levelName: 'Department',
        routerLink: ['/level/production-group'],
        routerLinkActive: ['is-active'],
        queryParams: {
          givenName: this.dataService.givenName,
          plantCode: this.dataService.plantCode,
          crew: this.dataService.crew,
          startDate: this.dataService.startDate,
          endDate: this.dataService.endDate
        }
      },
      {
        levelName: 'Production Line',
        routerLink: ['/level/production-line'],
        routerLinkActive: ['is-active'],
        queryParams: {
          givenName: this.dataService.givenName,
          plantCode: this.dataService.plantCode,
          crew: this.dataService.crew,
          productionGroup: this.dataService.productionGroup,
          startDate: this.dataService.startDate,
          endDate: this.dataService.endDate
        }
      }
    ];
  }

  selectPlant(plantConfig) {
    this.updateConfig();
    this.dataService.crew = this.dataService.crewArr[0];
    this.dataService.productionGroup = this.dataService.productionGroupArr[0];
    this.dataService.plantCode = plantConfig.plant_code;
    this.dataService.plantName = plantConfig.plant_name;
    this.dataService.plantAbbr = plantConfig.plantAbbr;
    this.dataService.plannerWorksheetEntryLevel =
      plantConfig.planner_worksheet_entry_level;

    this.dataService.autoPopulatedFieldEntryApplicable =
      plantConfig.auto_populated_field_entry_applicable;

    sessionStorage.setItem('plantCode', plantConfig.plant_code);
    sessionStorage.setItem('plantName', plantConfig.plant_name);
    sessionStorage.setItem('plantAbbr', plantConfig.plant_abbr);
    sessionStorage.setItem(
      'plannerWorksheetEntryLevel',
      plantConfig.planner_worksheet_entry_level
    );
    sessionStorage.setItem(
      'autoPopulatedFieldEntryApplicable',
      plantConfig.auto_populated_field_entry_applicable
    );
    this.apiService.getFilterConfigForPlant(this.dataService.plantCode);
    this.userAccessService.getManagementUserRole(this.dataService.userId);
  }

  navToggle() {
    this.sidenavToggle.emit(true);
  }

  openNotificationDialog(): void {
    this.dialog.open(NotificationsDialogComponent, {
      position: {
        top: '52px',
        right: '30px'
      },
      width: '430px'
    });
  }

  openWeatherNotificationDialog(): void {
    this.dialog.open(WeatherNotificationsDialogComponent, {
      position: {
        top: '52px',
        right: '30px'
      },
      width: '430px'
    });
  }

  ngOnDestroy() { }
}
