<h1 mat-dialog-title>Weather Notifications</h1>
<div
  *ngIf="dataService.activeWeatherNotificationCount > 0; else noNotifications"
>
  <mat-dialog-content class="mat-notification-dialog">
    <div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="5px">
      <app-notification
        *ngFor="let notification of dataService.weatherNotificationsTop1"
        [notification]="notification"
        (updateNotifications)="
          notificationsService.updateWeatherNotifications($event)
        "
      ></app-notification>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    align="end"
    *ngIf="dataService.activeWeatherNotificationCount > 3"
  >
    <button
      mat-button
      [routerLink]="['/weather-notifications']"
      cdkFocusInitial
      (click)="onNoClick()"
    >
      Show All
    </button>
  </mat-dialog-actions>

  <mat-dialog-actions
    align="end"
    *ngIf="
      dataService.activeWeatherNotificationCount <= 3 &&
      dataService.userRole === 'Super User'
    "
  >
    <button
      mat-button
      [routerLink]="['/weather-notifications']"
      cdkFocusInitial
      (click)="onNoClick()"
    >
      Show All Weather Alerts
    </button>
  </mat-dialog-actions>
</div>
<ng-template #noNotifications>
  Your notifications live here
</ng-template>
