import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() minDate: moment.Moment;
  @Input() maxDate: moment.Moment;
  @Input() dateLimit: number;
  @Input() selected: any;

  @Output() getDate = new EventEmitter();

  ranges: any = {
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()]
  };

  constructor() {}

  ngOnInit() {}

  datesUpdated(event) {
    this.getDate.emit(event);
  }
}
