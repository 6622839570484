<mat-card class="container">
  <mat-form-field>
    <input matInput
           (keyup)="applyFilter($event.target.value)"
           placeholder="Filter" />
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table mat-table
           [dataSource]="managementUsersSource"
           matSort>
      <!-- User id -->
      <ng-container matColumnDef="user_id">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>User Id</th>
        <td mat-cell
            *matCellDef="let user">{{ user.user_id }}</td>
      </ng-container>
      <!-- Email -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let user">{{ user.email }}</td>
      </ng-container>

      <!-- Name -->
      <ng-container matColumnDef="user_name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>Name</th>
        <td mat-cell
            *matCellDef="let user">{{ user.user_name }}</td>
      </ng-container>

      <!-- User Role -->
      <ng-container matColumnDef="user_role">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>User Role</th>
        <td mat-cell
            *matCellDef="let user">{{ user.user_role }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell
            *matHeaderCellDef>Actions</th>
        <td mat-cell
            *matCellDef="let user">
          <button [disabled]="user.user_id === dataService.userId"
                  mat-icon-button
                  matTooltip="Delete User"
                  matTooltipPosition="left"
                  (click)="deleteUser(user)">
            <mat-icon aria-hidden="false"
                      aria-label="Delete User">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
  </div>
</mat-card>